import React from 'react'
import { useSaleCollectModel } from '../collectModel'
import { ISearchValue, SearchTypeEnum } from "@src/components/YhPageHeader/Types"
import { YhPageHeader } from '@src/components/YhPageHeader'


export function PageHeader () {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSaleCollectModel()

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '车辆编号',
            fieldName: 'vehicleCode',
            xxl: 4,
            span: 4
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '客户名称',
            fieldName: 'userName',
            xxl: 4,
            span: 4,
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: '收藏时间',
            fieldName: 'createTime',
            xxl: 4,
            span: 4,
        }
    ]

    return (
        <YhPageHeader
            title="收藏管理列表"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    )
}