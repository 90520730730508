import React, { useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { usePurchaseCollectModel } from '../collectModel'
import { IPurchaseCollectDetail } from '../collectTypes'
import moment from 'moment'

export function DataTable () {
    const {
        dataSource,
        selectedRowKeys,
        pgCfg,
        loadData,
        setSelectedRowKeys
    } = usePurchaseCollectModel()
    const collect = dataSource.map(item => item.collectTypeName)
    useEffect(() => {
        console.log(collect);
    }, [])
    const columns: ColumnsType<IPurchaseCollectDetail> = [
        {
            title: '序号',
            align: 'center',
            fixed: 'left',
            render: (t, r, i) => i + 1
        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'userName'
        },
        {
            title: '车辆编号',
            align: 'center',
            dataIndex: 'vehicleCode'
        },
        {
            align: 'center',
            title: "车辆品牌",
            dataIndex: 'modelName'
        },
        {
            align: 'center',
            title: "车辆型号",
            dataIndex: 'vehicleModel'
        },
        {
            title: '车辆类型',
            align: 'center',
            dataIndex: 'typeName'
        },
        {
            title: '出厂日期',
            align: 'center',
            dataIndex: 'produceTime',
            render: (v) => moment(v).format('YYYY-MM-DD')
        },
        {
            title: '驱动形式',
            align: 'center',
            dataIndex: 'drivingFormName'
        },
        {
            title: '马力',
            align: 'center',
            dataIndex: 'horsepowerName'
        },
        {
            title: '燃料种类',
            align: 'center',
            dataIndex: 'fuelTypeName'
        },
        {
            title: '行驶里程',
            align: 'center',
            dataIndex: 'mileage'
        },
        {
            title: '车辆状态',
            align: 'center',
            dataIndex: 'status',
            render: (v, record) => {
                if (record.collectTypeName === "车源信息") {
                    if (v === 1) {
                        return "待售中"
                    } else {
                        return "已售出"
                    }
                } else {
                    if (v === 0) {
                        return "已完结"
                    } else {
                        return "求购中"
                    }
                }
            }
        },
        {
            title: '收藏类型',
            align: 'center',
            dataIndex: 'collectTypeName',
        },
        {
            title: '收藏时间',
            align: 'center',
            dataIndex: 'collectTime',
            render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss')
        }
    ]

    useEffect(() => {
        loadData()
    }, [])

    return (
        <Table
            style={{ cursor: "pointer" }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}