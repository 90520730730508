import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import useGlobalModel from "./model/globalModel";
import { Login } from "./views/login/Login";
import TokenLogin from './views/login/TokenLogin'
import Index from "./layout";
import { setToken } from './utils/constants';

function App() {
  const { user } = useGlobalModel()

  function loginCheck() {
    const url = window.location.href
    let urlObj: { [proppName: string]: string; } = {}

    /*
    * 正则匹配
    * [?&]:匹配?或&其一
    * [^?&]:非?或非&
    * */
    let reg = /[?&][^?&]+=[^?&]+/g
    let arr = url.match(reg)
    if (arr) {
      arr.forEach((item) => {
        let tempArr = item.substring(1).split('=')
        let key: string = tempArr[0]
        let val: string = tempArr[1]
        urlObj[key] = val
      })
    }
    if (urlObj.token) {
      sessionStorage.setItem('loginType', 'token')
      setToken(urlObj.token)
      return <TokenLogin token={urlObj.token} />
    }
    sessionStorage.setItem('loginType', 'account')
    return <Login />
  }

  return (
    <Switch>
      <Route path="/login" component={() => loginCheck()} />
      <Route path="/" render={
        () => user.token ? <Index /> : <Redirect to="/login" />}
      />
    </Switch>
  );
}

export default App;
