import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IVehicleDetail } from '../VehicleTypes'
import { useVehicleModle } from '../vehicleModel'
import moment from 'moment'

export function DataTable () {
  const {
    pgCfg,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    loadData,
  } = useVehicleModle()
  const columns: ColumnsType<IVehicleDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "车辆编号",
      dataIndex: 'vehicleCode'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "品牌型号",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆牌照",
      dataIndex: 'numberPlate'
    },
    {
      align: 'center',
      title: "登记日期",
      dataIndex: 'registerTime',
      render: v => v ? moment(v).format("YYYY-MM-DD HH:mm:ss") : ''
    },
    {
      align: 'center',
      title: "出厂日期",
      dataIndex: 'produceTime',
      render: v => v ? moment(v).format("YYYY-MM-DD") : ''
    },
    {
      align: 'center',
      title: "创建人",
      dataIndex: 'createUser'
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime'
    },
    {
      align: 'center',
      title: "状态",
      dataIndex: 'status',
      render: v => v === 0 ? '求购中' : v === 1 ? '转订单' : v === 2 ? '已成交' : v
    },
  ]
  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IVehicleDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0] ?? undefined)
        },
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        position: ["bottomCenter"],
        showTotal: () => `共${pgCfg.total}条`,
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData(undefined)
            } else {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record)
            }
          },
        };
      }}
    />
  )
}