import { DataNode, EventDataNode } from "antd/lib/tree";
import React, { MutableRefObject } from "react";
import { IFormItem, IYhFormRef } from "../YhFormBox/Types";

export enum OptModelTypeEnum {
    ADD_ROOT = 1,//跟
    ADD_SAME_LEVEL = 2,//同级
    ADD_CHILD_LEVEL = 3,//孩子
    DETAIL = 4//编辑
}

type onSelectType = ((selectedKeys: React.Key[], info: {
    event: "select";
    selected: boolean;
    node: EventDataNode;
    selectedNodes: DataNode[];
    nativeEvent: MouseEvent;
}) => void) | undefined




export interface IDictFormProps {
    items: IFormItem[];
    optModelType: OptModelTypeEnum;
    onSubmit: () => void;
    onCancel: () => void;
    onReset: () => void;
    formTitle?: string;
    formRef?: MutableRefObject<IYhFormRef | undefined>,
    disabled?: boolean;
    initialValues?: any;
    children?: React.ReactElement;
}

export interface IDictTree {
    action: JSX.Element;
    treeData: DataNode[] | undefined;
    onSelect: onSelectType;
    treeTitle?: string;
    selectedKeys?: React.Key[] | undefined;
}

export type IDictProps = IDictFormProps & IDictTree