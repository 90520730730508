import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@src/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@src/components/YhBox/Types";
import { usePurchaseOrderModel } from "../orderModel";
import useNavModel from "@src/model/navModel";
import { globalPrompt } from "@src/components/YhMessage";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, resetEditCatch } = usePurchaseOrderModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === 9) {
            globalPrompt("message", { text: "该订单已取消，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "ucms-02-03-01";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow?.id as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "销售订单跳转详情",
                type: "warning",
                text: "您还有销售订单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.code : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    resetEditCatch()
                    switchNavState(saleOrderEditKey, { id: selectedRow?.id as string, mode: "edit" });
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行查看", type: "warning" });
            return;
        }
        addNav(
            'ucms-02-03-02',
            { id: selectedRow?.id as string, mode: "look", },
        );
    }

    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >订单管理</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >查看</Button>
    </>;

    return (
        <YhBox title={action}>
            <DataTable />
        </YhBox>
    )
}