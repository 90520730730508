

//系统标识
export const CLIENT_APP_CODE = "shc2";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_FIRST_PAGE_NUM = 1;
export const DEFAULT_REQUEST_TIMEOUT = 10000;

// 默认供应商ID
export const DEFAULT_SUPPLIER = "2f6c5fb1c76b41a5bb2eb1f1111442ab"
// 默认客户ID
export const DEFAULT_CLIENT = "bd18f7f6440a4687ae59226ee433d188"

enum baseRequestUrlEnum {
    "DEV" = "http://api.peijian88.cn",
    "TEST" = "http://test-api-2.laoniuche.cn",
    "PROD" = "https://api-2.yunhaogroup.com",
    "STAGE" = "https://api.beiben88.com"
}
//API请求环境
export const baseRequestUrl = {
    "DEV": `${baseRequestUrlEnum.DEV}`,
    "TEST": `${baseRequestUrlEnum.TEST}`,
    "PROD": `${baseRequestUrlEnum.PROD}`
}

const baseAuthRequestUrl = {
    "DEV": `${baseRequestUrlEnum.DEV}/portal/auth`,
    "TEST": `${baseRequestUrlEnum.TEST}/portal/auth`,
    "PROD": `${baseRequestUrlEnum.PROD}/portal/auth`
}

// const baseFileRequestUrl = "http://file-server.laoniuche.cn/file";
/**
 * 上传文件路径
 */
// export const uploadFileUrl = `${baseFileRequestUrl}/uploadFile`;
// /**
//  * 获取文件服务器文件
//  * @param id 
//  * @returns 
//  */
// export const loadFileUrl = (id: string) => `${baseFileRequestUrl}/fetch?id=${id}`;


//根据环境变量自动获取图片查看地址
export const getBaseImgLookUrl = (md5: string) => {
    //自动选择环境
    return `${getBaseRequestUrl()}/fsrv/fetch/${md5}?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseImgUploadUrl = () => {
    //自动选择环境
    return `${getBaseRequestUrl()}/fsrv/upload_file?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseUploadData = () => {
    return {
        appCode: CLIENT_APP_CODE,
        fileMode: 0,
    }
}


//根据环境变量自动获取请求地址
export const getBaseRequestUrl = () => {
    //自动选择环境
    //....
    const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD";
    return baseRequestUrl[REACT_APP_ENV ?? "DEV"]
};
export const getBaseAuthRequestUrl = () => {
    //自动选择环境
    //....
    const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD";
    return baseAuthRequestUrl[REACT_APP_ENV ?? "DEV"]
};
//服务器自定义错误码serve 
export const INTERNAL_SERVER_ERROR_CODE = 500;
export const INTERNAL_SERVER_LOGON_FAILURE_CODE = 401;
//token存储KEY值
export const TOKEN_KEY = "token";
export const IP_KEY = "ip";
//获取token
export const getToken = () => {
    return sessionStorage.getItem(TOKEN_KEY) ?? "default-token-str"
};
//存储token
export const setToken = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token)
};
//清除token
export const clearToken = () => {
    sessionStorage.removeItem(TOKEN_KEY)
};
//获取IP
export const getIP = () => {
    return sessionStorage.getItem(IP_KEY) ?? ""
};
//存储IP
export const setIP = (ip: string) => {
    sessionStorage.setItem(IP_KEY, ip)
};
//清除IP
export const clearIP = () => {
    sessionStorage.removeItem(IP_KEY)
};