import React from 'react'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { useSaleDemandModle } from '../demandModel'
import { useDsModel } from '@src/model/dsModel'
import { StatusDs } from '@src/types/FmsDs'

export function PageHeader() {
  const {
    vehicleTypeDs,
    gearboxTypeDs,
    engineDs,
    drivingTypeDs,
    fuelTypeDs,
    powerTypeDs,
    vehicleBrandDs,
    labelDs,
    tradeCountryDs,
  } = useDsModel()
  const { onSearch, onReset, updateSearchParam, searchParam } = useSaleDemandModle()
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '求购编号',
      fieldName: 'demandCode',
      size: "small",
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '车辆类型',
      fieldName: 'typeCode',
      size: 'small',
      options: vehicleTypeDs
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '客户名称',
      fieldName: 'customerCode',
      size: 'small',
      options: []
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '状态',
      fieldName: 'status',
      size: 'small',
      options: [
        {
          label: "求购中",
          value: 0
        },
        {
          label: "转订单",
          value: 1
        },
        {
          label: "已成交",
          value: 2
        }
      ]
    },
    {
      type: SearchTypeEnum.Datepicker,
      xxl: 4,
      span: 4,
      placeholder: '创建时间',
      fieldName: 'createTime',
      format: "YYYY-MM-DD",
      size: "small",
    },
  ]
  return (
    <div>
      <YhPageHeader
        title="销售管理/客户需求"
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={onSearch}
        searchFields={searchFields}
        searchValues={searchParam}
      />
    </div>
  )
}