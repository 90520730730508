import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ISaleDemandDetail } from '../DemandTypes'
import { useSaleDemandModle } from '../demandModel'
import moment from 'moment'

export function DataTable() {
  const {
    pgCfg,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    loadData,
    setSelectedRow,
  } = useSaleDemandModle()
  const columns: ColumnsType<ISaleDemandDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "求购编号",
      dataIndex: 'demandCode'
    },
    {
      align: 'center',
      title: "客户名称",
      dataIndex: 'customerName'
    },
    {
      align: 'center',
      title: "贸易国别",
      dataIndex: 'countryName'
    },
    {
      align: 'center',
      title: "数量",
      dataIndex: 'number'
    },
    {
      align: 'center',
      title: "已转数量",
      dataIndex: 'transorderNum',
      render: v => v ?? 0
    },
    {
      align: 'center',
      title: "车辆品牌",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆型号",
      dataIndex: 'vehicleModel'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "驱动形式",
      dataIndex: 'drivingFormName'
    },
    {
      align: 'center',
      title: "马力",
      dataIndex: 'horsepowerName'
    },
    {
      align: 'center',
      title: "燃料种类",
      dataIndex: 'fuelTypeName'
    },
    {
      align: 'center',
      title: "状态",
      dataIndex: 'status',
      render: v => v === 0 ? "求购中"
        : v === 1 ? "已转接"
          : v === 2 ? "已成交"
            : v
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime',
      render: v => v ? moment(v).format("YYYY-MM-DD HH:mm:ss") : ""
    },
  ]
  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDemandDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0])
          setSelectedRow(selectedRows)
        },
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        position: ["bottomCenter"],
        showTotal: () => `共${pgCfg.total}条`,
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData(undefined)
            } else {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record)
            }
          },
        };
      }}
    />
  )
}