import { httpRequest } from "@utils/HttpRequest";
import { IPage } from "@src/types/FmsTypes";
import { trasDsToTreeData } from "./dictModel";
import { IMdDictDetail, IMdDictFields, IMdDictSearch } from "./DictTypes";

class DictService {
    async insert(insertVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/insert";
        return await httpRequest.post<IMdDictDetail>(apiUrl, insertVo);
    }

    async update(updateVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/update";
        return await httpRequest.post<IMdDictDetail>(apiUrl, updateVo);
    }

    async disable(oneVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/status";
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }

    async find(searchVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/search";
        return await httpRequest.post<IMdDictDetail[]>(apiUrl, searchVo)
    }

    async dictItemDs(pcode: string,) {
        let { retData } = await this.find({ pcode })
        return retData.map(el => ({
            label: el.name ?? "",
            value: el.code ?? ""
        }))
    }

    async page(searchVo: IMdDictSearch) {
        const apiUrl = "/shc2/dict/page";
        return await httpRequest.post<IPage<IMdDictDetail>>(apiUrl, searchVo)
    }

    async one(oneVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/one";
        return await httpRequest.post<IMdDictDetail>(apiUrl, oneVo)
    }

    async allTree(searchVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/find_all_tree";
        return await httpRequest.get<IMdDictDetail[]>(apiUrl)
    }

    async oneTree(oneVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/oneTree";
        return await httpRequest.post<IMdDictDetail>(apiUrl, oneVo)
    }

    async oneTreeDs(oneVo: IMdDictFields) {
        let { retData } = await this.oneTree(oneVo);
        let { children } = retData
        return children ? children.map(el => ({
            label: el.name ?? "",
            value: el.code ?? ""
        })) : []
    }

    async sync(searchVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/sync";
        // searchVo.branchList = ["main", "shc2"];
        return await httpRequest.post<boolean>(apiUrl, searchVo)
    }

    async syncPage(searchVo: IMdDictSearch) {
        const apiUrl = "/shc2/dict/syncPage";
        searchVo.branchList = ["main", "shc2"];
        return await httpRequest.post<boolean>(apiUrl, searchVo)
    }

    async syncOne(oneVo: IMdDictFields) {
        const apiUrl = "/shc2/dict/syncOne";
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }
    async accountTreeOne(code: string) {
        let { retData } = await this.allTree({ code })
        // return retData.map(el => ({
        //     label: el.name ?? "",
        //     value: el.code ?? "",
        //     codes: el.value ?? "",
        // }))
        let { children } = retData[0]
        console.log(retData)
        let treeData = trasDsToTreeData(children as IMdDictFields[], "code", "name");
        return treeData;
    }
}
export const dictService = new DictService();
