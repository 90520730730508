import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IPVehicleResourceDetail } from '../VehicleResourceTypes'
import { usePVehicleResourceModle } from '../vehicleResourceModel'
import moment from 'moment'

export function DataTable() {
  const {
    pgCfg,
    selectedRow,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    loadData,
    setSelectedRow,
  } = usePVehicleResourceModle()
  const columns: ColumnsType<IPVehicleResourceDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "车辆编号",
      dataIndex: 'vehicleCode'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "车辆品牌",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆型号",
      dataIndex: 'vehicleModel'
    },
    {
      align: 'center',
      title: "出厂日期",
      dataIndex: 'produceTime',
      render: v => v ? moment(v).format("YYYY-MM-DD") : ""
    },
    {
      align: 'center',
      title: "驱动形式",
      dataIndex: 'drivingFormName'
    },
    {
      align: 'center',
      title: "马力",
      dataIndex: 'horsepowerName'
    },
    {
      align: 'center',
      title: "燃料种类",
      dataIndex: 'fuelTypeName'
    },
    {
      align: 'center',
      title: "行驶里程",
      dataIndex: 'mileage'
    },
    {
      align: 'center',
      title: "创建人",
      dataIndex: 'createUser'
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime',
      render: v => v ? moment(v).format("YYYY-MM-DD HH:mm:ss") : ""
    },
    {
      align: 'center',
      title: "状态",
      dataIndex: 'status',
      render: (v, r, i) => v === 0 ? '待审批'
        : v === 1 ? '待售中'
          : v === 2 ? '已转接'
            : v === 3 ? '已售出'
              : v === 9 ? '已驳回'
                : v
    },
  ]
  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IPVehicleResourceDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0])
          setSelectedRow(selectedRows)
        },
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        position: ["bottomCenter"],
        showTotal: () => `共${pgCfg.total}条`,
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData(undefined)
              setSelectedRow(selectedRow?.filter(item => item.key === record.key))
            } else {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record)
              setSelectedRow([record])
            }
          },
        };
      }}
    />
  )
}