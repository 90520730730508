import React from "react";
import { Layout, Avatar, Dropdown, Menu, Badge } from "antd";
import FontIcon from "../components/YhIcon";
import useGlobalModel from "../model/globalModel";
import useNavModel from "../model/navModel";
import Logo from "@assets/images/header-logo.png"
import { loginService } from "@src/views/login/LoginService";
import { clearToken } from "@src/utils/constants";
import { closeWebPage } from "@src/utils/utils";

const { Header } = Layout;

function FmsHeader() {
    const { user, setUser, initUserState, setGMsgVisible, gMsgVisible, gMsgTotal } = useGlobalModel();
    const { initNav, clearNav } = useNavModel();
    //退出登录
    const onLogout = async () => {
        const loginType = sessionStorage.getItem('loginType')
        console.log('当前登录状态:', loginType);
        try {
            await loginService.appLogout(user.userCode as string)
            if (loginType === 'account') {
                await loginService.userLogout()
            }
        } catch (error) {
            console.error("退出异常:", error)
        } finally {
            if (loginType === 'account') {
                setUser(initUserState)
                clearToken()
                clearNav()
            } else {
                closeWebPage()
            }
        }
    };
    // 菜单项
    const HeaderMenu = () => {
        const onClearCache = () => {
            initNav()
        }
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onClearCache}>关闭所有标签</span>
                </Menu.Item>
                {/* <Menu.Divider /> */}
            </Menu>
        )
    }
    return (
        <Header className="layout-section-header">
            {/* 左侧按钮 */}
            <div className="header-left flex items-center">
                <div className="layout-sider-logo">
                    <img className="layout-sider-logo-img" src={Logo} alt="财务管理系统Logo" />
                    <span className="one-line">二手车管理系统</span>
                </div>
            </div>
            {/* 右侧内容 */}
            <div className="header-right" style={{ paddingRight: "15px" }}>
                <div className="mr-15" onClick={() => { setGMsgVisible(!gMsgVisible) }}>
                    <Badge count={gMsgTotal} overflowCount={99}>
                        <Avatar className="header-right-item config-icon" icon={<FontIcon type="icon-tixing" />} />
                    </Badge>
                </div>
                {/* 设置 */}
                <Dropdown overlay={HeaderMenu} placement="bottomCenter" trigger={['click']}>
                    <Avatar className="header-right-item config-icon" icon={<FontIcon type="icon-shezhi" />} />
                </Dropdown>

                {/* 登录用户信息 */}
                <span onClick={e => e.preventDefault()} className="flex items-center header-right-item user-info">
                    <Avatar
                        size="default"
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        className="user-icon"
                    />
                    <span className="account">{user.userName ?? "未获取到"}</span>
                </span>

                {/* 退出登录 */}
                <FontIcon type="icon-tuichu" className="header-right-item logout-icon" onClick={onLogout} />
            </div>
        </Header>
    )
}

export default FmsHeader;