/**
 * 分支选择项
 */
export const BranchDs = [
  { label: '二手车', value: 'shc2' },
  { label: '主分支', value: 'main' },
];

/**
 * 状态选择项
 */
export const StatusDs = [
  { label: '禁用', value: 0 },
  { label: '启用', value: 1 },
];
/**
 * 状态选择项
 */
export const PVStatusDs = [
  { label: '待审批', value: 0 },
  { label: '待售中', value: 1 },
  { label: '已转接', value: 2 },
  { label: '已售出', value: 3 },
  { label: '已驳回', value: 9 },
];

/**
 * 采购订单状态
 */
export const POStatusDs = [
  { label: '已开单', value: 0 },
  { label: '已报价', value: 1 },
  { label: '签合同', value: 2 },
  { label: '已付款', value: 3 },
  { label: '已过户', value: 4 },
  { label: '已售出', value: 5 },
  { label: '已取消', value: 9 },
];

/**
 * 销售订单状态
 */

export const SOStatusDs = [
  { label: '已开单', value: 0 },
  { label: '已报价', value: 1 },
  { label: '签合同', value: 2 },
  { label: '已付款', value: 3 },
  { label: '已报关', value: 4 },
  { label: '已发运', value: 5 },
  { label: '已清关', value: 6 },
  { label: '已售出', value: 7 },
  { label: '已取消', value: 9 },
];

/**
 * 采购管理收藏与浏览类型
 */
export const PCTypeDs = [
  { label: '车源信息', value: '001' },
  { label: '求购信息', value: '002' },
];

/**
 * 性别选择项
 */
export const GenderDs = [
  { label: '女', value: 2 },
  { label: '男', value: 1 },
];

/**
 * 是否可过户
 */
export const TransferOwnershipFlagDs = [
  { label: '否', value: 0 },
  { label: '是', value: 1 },
];

/**
 * 归属栏目
 */
export const ColumnDs = [
  { label: '收车版小程序', value: 0 },
  { label: '二手车小程序', value: 1 },
];

/**
 * 是否翻译
 */
export const TranslateDs = [
  { label: '中文', value: 0 },
  { label: '英文', value: 1 },
];

/**
 * 负责人
 */
 export const PrincipalDs = [
	{ label: '范晓瑞', value: 0 },
	{ label: '王倩', value: 1 },
	{ label: '王健', value: 2 },
	{ label: '冯燕飞', value: 3 },
];

/**
 * 是否可过户
 */
export const ModelTypeDs = [
  {
    label: '否',
    label_en: 'no',
    label_all: '否 | no',
    value: 0,
  },
  {
    label: '是',
    label_en: 'yes',
    label_all: '是 | yes',
    value: 1,
  },
];

export const BooleanFlagDs = [
  {
    label: '是',
    label_en: 'yes',
    label_all: '是 | yes',
    value: 1,
  },
  {
    label: '否',
    label_en: 'no',
    label_all: '否 | no',
    value: 0,
  },
];
