import React, { useEffect } from 'react'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'
import { TransOrderDrawer } from '../drawer/TransOrderDrawer'
import { TransClientDrawer } from '../drawer/TransClientDrawer'
import { usePVehicleResourceModle } from '../vehicleResourceModel'

export const PurchaseVehicleResource = () => {
  const { loadData, resetState } = usePVehicleResourceModle()

  useEffect(() => {
    loadData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
    <TransOrderDrawer />
    <TransClientDrawer />
  </>
}