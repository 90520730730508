import React, { useEffect, useRef, useState } from "react";
import { YHDrawer } from "@components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@components/YhFormBox/Types";
import { YhForm } from "@components/YhFormBox";
import { useSVehicleResourceModle } from "../vehicleResourceModel";
import { pVehicleResourceService } from "@src/views/purchase/vehicleResource/VehicleResourceService";
import { globalPrompt } from "@src/components/YhMessage";
import { saleClientService } from "@src/views/user/UserService";
import useNavModel from "@src/model/navModel";
import { sVehicleResourceService } from "../VehicleResourceService";
import { userInfo } from "node:os";
import useGlobalModel from "@src/model/globalModel";
import { IUserFields } from "@src/views/user/UserTypes";
import { useSaleOrderModel } from '../../order/orderModel';


export function TransOrderDrawer() {
    const { user } = useGlobalModel()
    const { resetEditCatch } = useSaleOrderModel();
    const { addNav, findNav, switchNavState, selectNav } = useNavModel()
    const {
        customerDs,
        orderVisible,
        setOrderVisible,
        selectedRow
    } = useSVehicleResourceModle();
    const formRef = useRef<IYhFormRef>();
    const [client, setClient] = useState<IUserFields>()
    const [number, setNumber] = useState<number>(1)
    useEffect(() => {
        orderVisible && setNumber(selectedRow?.length as number)
    }, [selectedRow, orderVisible])

    const onCancel = () => {
        setOrderVisible(false);
        setClient(undefined)
    }
    const onOk = async () => {
        try {
            const formData = await formRef.current?.validateFields()
            const params = {
                ...formData,
                userType: "customer",
                userCode: client?.id,
                createUser: user.userName,
                countryCode: client?.countryCode,
                countryName: client?.countryName,
                ShcSales: selectedRow?.map(item => {
                    return {
                        ...item,
                        filemgr: undefined,
                        updateUser: undefined,
                        updateTime: undefined,
                        createUser: undefined,
                        createTime: undefined,
                    }
                }),
                ShcContract: selectedRow?.reduce((p, n) => {
                    let index = p.findIndex((item) => item.modelCode === n.modelCode && item.vehicleModel === n.vehicleModel)
                    index !== -1 ? p[index].number++ : p.push({
                        modelCode: n.modelCode as string,
                        modelName: n.modelName as string,
                        vehicleModel: n.vehicleModel as string,
                        number: 1
                    })
                    return p
                }, [] as Array<any>)
            }
            console.log('转订单：', params);
            const { retData, success } = await sVehicleResourceService.transOrder(params)
            if (success) {
                globalPrompt('message', { text: '订单新增成功！', type: 'success' })
                setOrderVisible(false)
                let saleOrderEditKey = "ucms-03-02-01";
                let isFindNav = findNav(saleOrderEditKey);
                if (!isFindNav) {
                    addNav(saleOrderEditKey, { id: retData, mode: "edit" });
                } else {
                    globalPrompt("modal", {
                        title: "销售订单跳转详情",
                        type: "warning",
                        text: "您还有销售订单详情未保存，是否跳转",
                        okText: "跳转未保存页",
                        onOk: () => selectNav(isFindNav ? isFindNav.code : ""),
                        cancelText: "打开新页签",
                        onCancel: () => {
                            resetEditCatch()
                            switchNavState(saleOrderEditKey, { id: retData, mode: "edit" });
                            onCancel()
                        }
                    })
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        disabled: true,
        initialValues: {
            company: client?.company,
            userName: client?.userName,
            tel: client?.autoTel,
            purorderNumber: number
        },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "company",
                disable: false,
                ds: customerDs,
                onChange: (v, option) => setClient(option),
                span: 24,
                rules: [{ required: true, message: "未选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "姓名",
                fieldName: "userName",
                disabled: true,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电话",
                fieldName: "tel",
                disabled: true,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "台数",
                fieldName: "purorderNumber",
                disabled: true,
                span: 24,
                rules: [{ required: true, message: "未选择台数" }]
            }
        ]
    };
    // const onTranslation = async () => {
    //     try {
    //         const { retData } = await pVehicleResourceService.translate(serviceGuarantee)
    //         console.log(retData.dst);
    //         setServiceGuaranteeTranslate(retData.dst)
    //     } catch (error) {

    //     }
    // }
    return (
        <YHDrawer
            title="车辆信息转订单"
            visible={orderVisible}
            onCancel={onCancel}
            onOk={onOk}
            showFooter={true}
        >
            <YhForm  {...formConfig} />
        </YHDrawer>
    )
}