import { IPage, OptionDsType } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { IUserDetail, IUserFields, IUserSearch } from "./UserTypes";

class UserService {
  /**
   * 新增供应商
   * @param params 
   * @returns 
   */
  async insert(params: IUserFields) {
    const url = "/shc2/user/insert";
    return await httpRequest.post<IUserDetail>(url, params);
  }

  /**
   * 更新供应商
   * @param params 
   * @returns 
   */
  async update(params: IUserFields) {
    const url = "/shc2/user/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 分页查询供应商
   * @param params 
   * @returns 
   */
  async page(params: IUserSearch) {
    const url = "/shc2/user/search_page";
    return await httpRequest.post<IPage<IUserDetail>>(url, params);
  }

  /**
   * 分页查询供应商
   * @param params 
   * @returns 
   */
  async find(params: IUserFields) {
    const url = "/shc2/user/search";
    return await httpRequest.post<IUserDetail[]>(url, params);
  }

  /**
   * 分页查询用户
   * @param insertType
   * @returns 
   */
  async findUserDs(insertType: "customer" | "supplier") {
    const userList = await this.find({
      insertType
    });
    const list = userList.retData.filter(item => item.authStatus === 1)
    return list.map(item => {
      return {
        label: item.company,
        value: item.id,
        ...item
      } as OptionDsType
    })
  }

  /**
   * 供应商认证
   * @param params 
   * @returns 
   */
  async authenticate(id: string) {
    const url = `/shc2/user/authenticate`;
    return await httpRequest.post<boolean>(url, { id, authStatus: 1 });
  }

  /**
   * 查询单条供应商
   * @param id
   * @returns 
   */
  async findOne(id: string) {
    const url = `/shc2/user/search_one/${id}`;
    return await httpRequest.post<IUserDetail>(url);
  }

  /**
   * 导出供应商
   * @returns 
   */
  async exportSupplier() {
    const url = `/shc2/user/export/supplier`;
    return await httpRequest.getFile(url)
  }
  /**
   * 导出客户
   * @returns 
   */
  async exportCustomer() {
    const url = `/shc2/user/export/customer`;
    return await httpRequest.getFile(url)
  }

}

export const purchaseSupplierService = new UserService()
export const saleClientService = new UserService()