import { useState, useEffect, useRef } from 'react';
import { createModel } from 'hox';
import { message } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage, ISearchPageField } from '../../../types/FmsTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { IAboutUsSearch, IAboutUsDetail, IAboutUsFields } from './aboutUsTypes';
import { aboutUsService } from './aboutUsService';
import useGlobalModel from '../../../model/globalModel';
import { transPgToPagination, urlParse1 } from '../../../utils/utils';
import { IYhFormRef } from '@src/components/YhFormBox/Types';
import BraftEditor from 'braft-editor';
import { UploadFile } from 'antd/lib/upload/interface';
import { globalPrompt } from '@src/components/YhMessage';

export const useAboutUsModel = createModel(function () {
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const initSearchParam: IAboutUsFields = {};
	const initDataSource: IAboutUsDetail[] = [];

	const formRef = useRef<IYhFormRef>();
	// 查询条件
	const [searchParam, setSearchParam] = useState<IAboutUsFields>(initSearchParam);
	// 列表数据源
	const [dataSource, setDataSource] = useState<IAboutUsDetail[]>(initDataSource);
	// 表格选中行key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	// 表格选中的值
	const [selectedRow, setSelectRow] = useState<IAboutUsDetail>({});
	// 列表数据源分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	const [loading, setLoading] = useState<boolean>(false);

	// 富文本 新增
	const [addEditorState, setAddEditorState] = useState(BraftEditor.createEditorState(null)); //富文本
	// 富文本 编辑
	const [editEditorState, setEditEditorState] = useState(BraftEditor.createEditorState(null)); //富文本
	const [previewImage, setPreviewImage] = useState(Boolean); //商品缩略图预览
	// 上传图片的地址
	const [imageUrl, setImageUrl] = useState('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [previewUrl, setPreviewUrl] = useState('');

	// 编辑缓存
	const [editFormVal, setEditFormVal] = useState<IAboutUsFields>({});
	const [editGoodsList, setEditGoodsList] = useState<IAboutUsFields[]>([]);
	// 新增缓存
	const [addFormVal, setAddFormVal] = useState<IAboutUsFields>({});
	const [addGoodsList, setAddGoodsList] = useState<IAboutUsFields[]>([]);

	useEffect(() => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectRow(selectedData as IAboutUsFields);
	}, [setSelectedRowKeys]);

	// 更新页码
	const updataPgCfg = (updataParam: TablePaginationConfig) => {
		setPgCfg({ ...pgCfg, ...updataParam });
	};

	// 更新搜索条件
	const updataSearchParam = (updataParam: IAboutUsSearch) => {
		setSearchParam({ ...searchParam, ...updataParam });
	};

	// 重置
	const resetSearchParam = () => {
		setSearchParam(initSearchParam);
		loadData(initPgCfg, {});
		setSelectedRowKeys([]);
		setSelectRow({});
	};

	// 数据加载
	const loadData = async (page?: ISearchPage, searchValue: IAboutUsDetail = searchParam) => {
		const searchParams: IAboutUsSearch = {
			...searchValue,
			insertType: 'aboutUs',
			pg: {
				orders: [
					{
						rule: 'ASC',
						column: 'createTime',
					},
				],
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await aboutUsService.page(searchParams);
		const { items, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(items);
	};

	// 删除
	const onRemove = async (id: string) => {
		try {
			const { retData } = await aboutUsService.remove(id);
			if (retData) {
				globalPrompt('message', { type: 'success', text: '删除成功!' });
				loadData();
			} else {
				globalPrompt('message', { type: 'error', text: '删除失败!' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	//表单新增修改
	const updateAddFormVal = (val: object | null) => {
		val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal({});
	};
	//表单编辑修改
	const updateEditFormVal = (val: object | null) => {
		val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
	};

	// 新增api
	const insertDetail = async (params: IAboutUsFields) => {
		try {
			let newParams = {
				...params,
			};
			let { retData } = await aboutUsService.insert(newParams);
			return retData;
		} catch (error) {
			console.log(error);
		}
	};

	// 编辑api
	const editDetail = async (params: IAboutUsFields) => {
		try {
			let newParams = {
				...params,
			};
			let { retData } = await aboutUsService.update(newParams);
			return retData;
		} catch (error) {
			console.log(error);
		}
	};

	// 获取一条数据
	const getDetailEditOne = async (id: string) => {
		try {
			const { retData } = await aboutUsService.one(id);
			let { ...form } = retData;
			if (retData) {
				setEditFormVal(form);
				setEditEditorState(BraftEditor.createEditorState(form.articl));
			} else {
				globalPrompt('message', { type: 'error', text: '操作失败请重试!' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	// 翻译
	const translate = async (mode: string) => {
		// debugger;
		let isEdit = mode === 'edit';
		let formVal = isEdit ? editFormVal : addFormVal;
		let editorState = isEdit ? editEditorState : addEditorState ?? '';
		let arrIndex: string[] = [];
		let arr: string[] = editorState.match(/(<[a-zA-Z]+.*?>|<\/[a-zA-Z]+.*?>|[^><]+)/g) ?? [];
		let str: string = '';
		arr.forEach((item, index) => {
			if (item.indexOf('<') < 0 && item.indexOf('>') < 0) {
				arrIndex.push(`${index}@`);
				str += `&${index}@=${arr[index]}`;
			}
		});
		// console.log(arrIndex, arr);
		let translationObj = {
			source: formVal.source,
			keyword: formVal.keyword,
			title: formVal.title?.trim(),
			desc: formVal.desc,
			createUser: formVal.createUser,
			article: str,
		};

		let retData = await aboutUsService.translate(translationObj);
		// console.log('返回数据', retData);

		let obj: any = urlParse1(retData.retData?.article ?? '');
		// console.log(obj);
		(isEdit ? setEditFormVal : setAddFormVal)({
			source: retData.retData.source,
			keyword: retData.retData.keyword,
			title: retData.retData.title,
			desc: retData.retData.desc,
			createUser: retData.retData.createUser,
			createTime: editFormVal?.createTime,
			author: editFormVal?.author,
			id: editFormVal?.id,
		});
		arrIndex.forEach((item) => {
			arr[parseInt(item)] = obj[item];
		});
		(isEdit ? setEditEditorState : setAddEditorState)(BraftEditor.createEditorState(arr.join('')));
	};
	//重置缓存
	const resetEditCatch = (type?: string) => {
		setEditFormVal({});
	};
	const resetAddCatch = (type?: string) => {
		setAddFormVal({});
	};
	return {
		initPgCfg,
		searchParam,
		dataSource,
		selectedRowKeys,
		selectedRow,
		pgCfg,
		loading,
		setSearchParam,
		setDataSource,
		setSelectedRowKeys,
		setSelectRow,
		setPgCfg,
		setLoading,
		updataPgCfg,
		updataSearchParam,
		resetSearchParam,
		loadData,
		onRemove,
		formRef,
		addEditorState,
		setAddEditorState,
		editEditorState,
		setEditEditorState,
		previewImage,
		setPreviewImage,
		imageUrl,
		setImageUrl,
		fileList,
		setFileList,
		previewUrl,
		setPreviewUrl,
		editFormVal,
		setEditFormVal,
		editGoodsList,
		setEditGoodsList,
		addFormVal,
		setAddFormVal,
		addGoodsList,
		setAddGoodsList,
		updateAddFormVal,
		updateEditFormVal,
		resetEditCatch,
		resetAddCatch,
		insertDetail,
		editDetail,
		getDetailEditOne,
		translate,
	};
});
