import React, {FC} from "react";
import {IFormItem} from "../Types";
import {Form, InputNumber} from "antd";

export const __FormNumber: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
            
        >
            <InputNumber
                 disabled={props.disable}
                 max={props.max}
                 min={props.min}
            />
        </Form.Item>
    )
}