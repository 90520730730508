import useStorageState from '../hooks/useStorageState';
import arrayMove from 'array-move';
import { forFind } from '../utils/utils';
// import menuData from "../data/menuData.json";
import { createModel } from 'hox';
import useGlobalModel from './globalModel';

//定义页面需要通过路由传的参数
export type RouterParamType = {
	id: string;
	mode: string;
	[key: string]: any;
};

//定义tabs参数类型
export type NavTabType = {
	code: string;
	linkUrl: string;
	name: string;
	closeable: number;
	state?: RouterParamType;
	parent?: string | null;
	isShow?: number;
	// children?: null
};

// Nav模型定义
export type NavModelType = {
	navTabs: NavTabType[];
	selectedNavId: string;
	selectNav: (id: string) => void;
	closeNav: (id: string) => void;
	addNav: (id: string, state?: RouterParamType) => void;
	replaceNav: (closeId: string, replaceId: string, state?: RouterParamType, skip?: boolean) => void;
	getSelectedNavState: () => RouterParamType | undefined;
	initNav: () => void;
	clearNav: () => void;
	sortNav?: (obj: any) => void;
	findNav: (id: string) => NavTabType | undefined;
	switchNavState: (id: string, state: RouterParamType) => void;
};

function useModel(): NavModelType {
	const { menuData } = useGlobalModel();
	let initNavTabs = menuData[0] ? [menuData[0]] : [];
	const [navTabs, setNavTabs, resetNavTabs] = useStorageState<NavTabType[]>('navTabs', initNavTabs);
	const [selectedNavId, setSelectedNav, resetSelectedNav] = useStorageState<string>(
		'navSelected',
		initNavTabs[0]?.code || ''
	);
	//初始nav  用于清楚缓存回到最初始状态
	const initNav = (): void => {
		resetNavTabs();
		resetSelectedNav();
	};

	const clearNav = (): void => {
		setNavTabs([]);
		setSelectedNav('');
	};
	/**
	 * 选中nav
	 * @param id
	 */
	const selectNav = (id: string): void => {
		if (selectedNavId !== id) {
			setSelectedNav(id);
		}
	};
	/**
	 * 添加nav
	 * @param id
	 * @param state
	 */
	const addNav = (id: string, state?: RouterParamType): void => {
		let findNav: NavTabType | undefined = navTabs.find((el) => el.code === id);
		if (findNav) {
			setSelectedNav(findNav.code);
		} else {
			let params = forFind(menuData, id, 'code');
			let newNavTabs: NavTabType[] = [...navTabs];
			if (params) {
				newNavTabs.push({ ...params, state: state ?? null, children: undefined });
				setNavTabs(newNavTabs);
				setSelectedNav(params.code);
			}
		}
	};

	/**
	 * 关闭nav
	 * @param id
	 */
	const closeNav = (id: string): void => {
		let newNavTabs: NavTabType[] = navTabs.filter((el) => el.code !== id);
		setNavTabs(newNavTabs);
		if (selectedNavId === id) {
			setSelectedNav(newNavTabs[newNavTabs.length - 1].code);
		}
	};

	//替换nav

	const replaceNav = (
		closeId: string,
		replaceId: string,
		state?: RouterParamType,
		skip: boolean = false
	) => {
		let filterNavTabs = navTabs.filter((el) => (skip ? skip : el.code !== closeId));
		let findTabs = filterNavTabs.find((item) => item.code === replaceId);
		if (findTabs) {
			setNavTabs(filterNavTabs);
			setSelectedNav(replaceId);
		} else {
			let { children, ...params } = forFind(menuData, replaceId, 'code');
			filterNavTabs.push({ ...params, state: state ?? null });
			setNavTabs(filterNavTabs);
			setSelectedNav(params.code);
		}
	};

	//获取当前选中的nav的node的state
	const getSelectedNavState = () => {
		let findObj = navTabs.find((item) => item.code === selectedNavId);
		return findObj ? findObj['state'] : undefined;
	};

	//根据id查找Nav是否存在
	const findNav = (id: string) => {
		return navTabs.find((item) => item.code === id);
	};

	//已选中的nav转换state
	const switchNavState = (id: string, state: RouterParamType) => {
		let newNavTabs: NavTabType[] = [...navTabs];
		let findIndex = newNavTabs.findIndex((item) => item.code === id);
		newNavTabs.splice(findIndex, 1, { ...newNavTabs[findIndex], state });
		setNavTabs(newNavTabs);
		setSelectedNav(id);
	};
	// //获取当前选中的nav的node的state
	// const getSelectedNavState = (code?: string) => {
	//     let findObj = navTabs.find(item => item.code === selectedNavId);
	//     if (code === "state") return findObj ? findObj[code] : undefined;
	//     return findObj;
	// }

	//nav排序
	const sortNav = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
		let newTabs = arrayMove(navTabs, oldIndex, newIndex);
		setNavTabs(newTabs);
	};
	return {
		navTabs,
		selectedNavId,
		selectNav,
		closeNav,
		addNav,
		replaceNav,
		getSelectedNavState,
		sortNav,
		initNav,
		findNav,
		switchNavState,
		clearNav,
	};
}

export const useNavModel = createModel(useModel);
export default useNavModel;
