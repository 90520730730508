import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useSaleClientModle } from '../clientModel'
import { IUserDetail } from '@src/views/user/UserTypes'
import moment from 'moment'

export function DataTable() {
  const {
    pgCfg,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    loadData,
  } = useSaleClientModle()
  const columns: ColumnsType<IUserDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "客户编码",
      dataIndex: 'account'
    },
    {
      align: 'center',
      title: "客户名称",
      dataIndex: 'company'
    },
    {
      align: 'center',
      title: "微信昵称",
      dataIndex: 'nickname'
    },
    {
      align: 'center',
      title: "姓名",
      dataIndex: 'userName'
    },
    {
      align: 'center',
      title: "联系电话",
      dataIndex: 'autoTel'
    },
    {
      align: 'center',
      title: "邮箱",
      dataIndex: 'email'
    },
    {
      align: 'center',
      title: "认证状态",
      dataIndex: 'authStatus',
      render: (v) => v === 1 ? "已认证" : "未认证"
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime',
      render: (v) => v ? moment(v).format("YYYY-MM-DD HH:mm:ss") : ""
    },
    {
      align: 'center',
      title: "操作",
      dataIndex: ''
    },
  ]
  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IUserDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0])
        },
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        position: ["bottomCenter"],
        showTotal: () => `共${pgCfg.total}条`,
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData({} as IUserDetail)
            } else {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record)
            }
          },
        };
      }}
    />
  )
}