import React from 'react'
import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ISVehicleResourceDetail } from '../VehicleResourceTypes'
import { useSVehicleResourceModle } from '../vehicleResourceModel'
import moment from 'moment'

export function DataTable() {
  const {
    pgCfg,
    selectedRow,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    loadData,
    setSelectedRowData,
    setSelectedRow,
    handleChangeTopStatus,
  } = useSVehicleResourceModle()
  const columns: ColumnsType<ISVehicleResourceDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "车辆编号",
      dataIndex: 'vehicleCode'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "车辆品牌",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆型号",
      dataIndex: 'vehicleModel'
    },
    {
      align: 'center',
      title: "驱动形式",
      dataIndex: 'drivingFormName'
    },
    {
      align: 'center',
      title: "马力",
      dataIndex: 'horsepowerName'
    },
    {
      align: 'center',
      title: "是/否可过户",
      dataIndex: 'transferOwnershipFlag',
      render: v => v === 1 ? "是" : "否"
    },
    {
      align: 'center',
      title: "创建人",
      dataIndex: 'createUser'
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime',
      render: v => v ? moment(v).format("YYYY-MM-DD HH:mm:ss") : ""
    }, {
      align: 'center',
      title: "状态",
      dataIndex: 'status',
      render: v => v === 1 ? "待售中" : v === 2 ? "已转接" : v === 3 ? "已售出" : v
    },
    {
      align: 'center',
      title: "置顶",
      dataIndex: 'top',
      render: (v, r, i) =>
        <Switch checked={v === 1} checkedChildren="开启" unCheckedChildren="关闭" onChange={(value) => handleChangeTopStatus(r.id as string, value)} />
    },
  ]
  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[], selectedRows: ISVehicleResourceDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0])
          setSelectedRow(selectedRows)
        },
        // getCheckboxProps: (record) => ({
        //   disabled: record.status === 3, // Column configuration not to be checked
        // }),
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        position: ["bottomCenter"],
        showTotal: () => `共${pgCfg.total}条`,
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData(undefined)
              setSelectedRow(selectedRow?.filter(item => item.key === record.key))
            } else {
              // } else if (record.status !== 3) {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record)
              setSelectedRow([record])
            }
          },
        };
      }}
    />
  )
}