import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import useNavModel from '@src/model/navModel';
import { Button } from 'antd';
import React from 'react'
import { DataTable } from './DataTable'
import { useSaleDemandModle } from '../demandModel';

export function DataBox () {
  const { addNav } = useNavModel()
  const {
    selectedRowData,
    setOrderVisible,
    setPurchaseVisible,
    selectedRowKeys,
    setEditModel,
    exportSaleDemand
  } = useSaleDemandModle()
  const action = (
    <>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={
          selectedRowKeys.length !== 1
        }
        onClick={() => {
          setEditModel('edit');
          addNav('ucms-03-03-01')
        }}
      >编辑</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={!(
          selectedRowKeys.length === 1 && selectedRowData?.status === 0
        )}
        onClick={() => {
          setOrderVisible(true)
        }}
      >转订单</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length !== 1}
        onClick={() => {
          setPurchaseVisible(true)
        }}
      >转供应商求购信息</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        // disabled={selectedRowKeys.length === 0}
        onClick={exportSaleDemand}
      >导出</Button>
    </>
  );
  return (
    <YhBox title={<OrderedListOutlined />} action={action}>
      <DataTable />
    </YhBox>
  )
}