import React from "react"
import { Button } from "antd"
import { DataTable } from "./DataTable"
import { YhBox } from "@src/components/YhBox"
import { useDynamicModel } from "../dynamicModel";
import useNavModel from "@src/model/navModel";
import { globalPrompt } from "@src/components/YhMessage";

export const DataBox = () => {
  const { selectedRowKeys, selectedRow, onRemove, resetEditCatch } = useDynamicModel()
  const { addNav, findNav, selectNav, switchNavState } = useNavModel(() => []);
  // 编辑
  const onEdit = () => {
    if (selectedRowKeys.length !== 1) {
      globalPrompt("message", { text: "请选择单条数据进行编辑", type: "warning" })
      return
    }
    let policyEditKey = "ucms-04-02-02"
    let isFindNav = findNav(policyEditKey);
    if (!isFindNav) {
      addNav(policyEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
    } else {
      globalPrompt("modal", {
        title: "本站动态跳转详情",
        type: "warning",
        text: "您还有详情页未保存，是否跳转",
        okText: "跳转未保存页",
        onOk: () => selectNav(isFindNav ? isFindNav.code : ""),
        cancelText: "打开新页签",
        onCancel: () => {
          switchNavState(policyEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
          resetEditCatch();
        }
      })
    }
  }

  const action = <>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      onClick={() => {
        addNav('ucms-04-02-01', { id: "", mode: "add" })
      }}
    >新增</Button>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      onClick={onEdit}
    >编辑</Button>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      onClick={() => {
        if (selectedRowKeys.length !== 1) {
          globalPrompt("message", { text: "请选择单条数据进行操作", type: "warning" });
          return;
        }
        onRemove(selectedRowKeys[0] as string)
      }}
    >删除</Button>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      disabled={!(
        selectedRowKeys.length === 1
      )}
      onClick={() => {
        addNav('ucms-04-02-01', { id: "", mode: "copy" })
      }}
    >复制</Button>
  </>

  return (
    <YhBox title="本站动态列表" action={action}>
      <DataTable />
    </YhBox>
  )
}