import { httpRequest } from '../../../utils/HttpRequest';
import { IPage } from '@src/types/FmsTypes';
import { IAboutUsSearch, IAboutUsDetail, IAboutUsFields, Translate } from './aboutUsTypes';
class AboutUsService {
	// 获取分页数据
	async page(searchVo: IAboutUsSearch) {
		const apiUrl = '/shc2/content/search_page';
		return await httpRequest.post<IPage<IAboutUsDetail>>(apiUrl, searchVo);
	}

	// 获取一条数据
	async one(id: string) {
		const apiUrl = `/shc2/content/search_one/${id}`;
		return await httpRequest.post<IAboutUsDetail>(apiUrl);
	}

	// 数据新增
	async insert(insertVo: IAboutUsFields) {
		const apiUrl = '/shc2/content/insert';
		return await httpRequest.post<boolean>(apiUrl, insertVo);
	}

	// 数据编辑
	async update(updateVo: IAboutUsFields) {
		const apiUrl = '/shc2/content/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}

	// 数据删除
	async remove(id: string) {
		const apiUrl = '/shc2/content/delete';
		return await httpRequest.post<boolean>(apiUrl, { id });
	}

	// 数据置顶
	async top(id: string) {
		const apiUrl = `/shc2/content/Top/${id}`;
		return httpRequest.post<boolean>(apiUrl);
	}

	//翻译
	async translate(params: Translate) {
		const apiUrl = '/shc2/content/translation';
		return httpRequest.post<Translate>(apiUrl, params);
	}
}

export const aboutUsService = new AboutUsService();
