import React, { useEffect, useRef, useState } from "react";
import { YHDrawer } from "@components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@components/YhFormBox/Types";
import { YhForm } from "@components/YhFormBox";
import { useSaleDemandModle } from "../demandModel";
import { Button } from "antd";
import { useDsModel } from "@src/model/dsModel";
import { pVehicleResourceService } from "@src/views/purchase/vehicleResource/VehicleResourceService";
import { saleDemandService } from "../DemandService";
import { globalPrompt } from "@src/components/YhMessage";
import useNavModel from "@src/model/navModel";
import useGlobalModel from "@src/model/globalModel";


export function TransPurchaseDrawer() {
    const { user } = useGlobalModel()
    const { replaceNav } = useNavModel()
    const {
        vehicleTypeDs,
        engineDs,
        drivingTypeDs,
        fuelTypeDs,
        gearboxTypeDs,
        vehicleBrandDs,
        labelDs,
    } = useDsModel()
    const { purchaseVisible, setPurchaseVisible, selectedRowData } = useSaleDemandModle();
    const [serviceGuaranteeTranslate, setServiceGuaranteeTranslate] = useState<string>("")

    // useEffect(() => {
    //     setServiceGuaranteeTranslate("")
    // }, [purchaseVisible])

    const onCancel = () => {
        setServiceGuaranteeTranslate("")
        setPurchaseVisible(false);
    }

    const onOk = async () => {
        try {
            const formData = await formRef.current?.validateFields()
            const params = {
                ...formData,
                // registerTime: formData.registerTime ? moment(formData.registerTime).format("YYYY-MM-DD HH:mm:ss") : "",
                // produceTime: formData.produceTime ? moment(formData.produceTime).format("YYYY-MM-DD HH:mm:ss") : "",
                // scrapTime: formData.scrapTime ? moment(formData.scrapTime).format("YYYY-MM-DD HH:mm:ss") : "",
                // firstLicenseTime: formData.firstLicenseTime ? moment(formData.firstLicenseTime).format("YYYY-MM-DD HH:mm:ss") : "",
                createUser: user.userName,
                createTime: undefined,
                status: 1,
                orderNum: selectedRowData?.orderNum,
                desc: serviceGuaranteeTranslate,
            }
            const { retData, success } = await saleDemandService.transPurchase(params)
            if (success) {
                globalPrompt('message', { text: '新增成功！', type: 'success' })
                onCancel()
                replaceNav("ucms-03-03", "ucms-02-01")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const formRef = useRef<IYhFormRef>();
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        // disabled: true,
        justify: "start",
        initialValues: {
            ...selectedRowData
        },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆品牌",
                fieldName: "modelCode",
                ds: vehicleBrandDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "typeCode",
                ds: vehicleTypeDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "燃料种类",
                fieldName: "fuelTypeCode",
                ds: fuelTypeDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "马力",
                fieldName: "horsepowerName",
                span: 12,
                // ds: powerTypeDs,
                rules: [
                    { required: true, message: "请输入马力" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "驱动形式",
                fieldName: "drivingFormCode",
                ds: drivingTypeDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "发动机品牌",
                fieldName: "engineBrandCode",
                ds: engineDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "求购台数",
                fieldName: "number",
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "变速箱品牌",
                fieldName: "gearboxBrandCode",
                ds: gearboxTypeDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "标签选择",
                fieldName: "labelCode",
                ds: labelDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "求购描述",
                fieldName: "desc",
                labelCol: {
                    span: 3,
                },
                span: 24,
            }
        ]
    };

    const onTranslation = async () => {
        try {
            const { desc } = await formRef.current?.validateFields()
            const { retData } = await pVehicleResourceService.translate(desc)
            setServiceGuaranteeTranslate(retData.dst)
        } catch (error) {

        }
    }

    return (
        <YHDrawer
            title="转供应商求购信息"
            width={800}
            visible={purchaseVisible}
            onOk={onOk}
            onCancel={onCancel}
            showFooter={true}
            footerButs={[
                {
                    text: "在线翻译(中文)",
                    onClick: onTranslation
                }
            ]}
        >
            <YhForm  {...formConfig} />
            <div style={{ margin: "0px 20px 20px 100px" }}>{serviceGuaranteeTranslate}</div>
        </YHDrawer>
    )
}