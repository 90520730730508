import { createModel } from "hox";
import React, { Key, useState } from "react";
import { dictService } from "./DictService";
import { message } from "antd";
import { OptModelTypeEnum } from "@components/YHDict/Types";
import { IMdDictDetail, IMdDictFields } from "./DictTypes";

export enum DictOptModeEnum {
    ADD,
    ADD_ITEM,
    EDIT,
    DETAIL
}

interface ITreeDs<T> {
    status?: number;
    code?: string;
    children?: T[]
}

export interface ITreeData<T> {
    key: Key;
    title?: string;
    children?: ITreeData<T>[] | undefined;
}

export interface IFieldValue<T> {
    [key: string]: T[keyof T]
}


export function trasDsToTreeData<T extends ITreeDs<T>>(
    ds: T[],
    keyField: keyof T,
    titleField: keyof T,
): ITreeData<T>[] {
    return ds.map(el => {
        const key = el[keyField] as any;
        const title = el[titleField] as any;
        const children = el.children && el.children.length > 0 ? trasDsToTreeData(el.children, keyField, titleField) : undefined;
        // const disabled = el.status === 1;
        return {
            el,
            key,
            title,
            // disabled,
            value: el["code"],
            // selectable: !disabled,
            children,
        }
    });
}

export function filterTree<T extends ITreeDs<T>>(arg: T[], code: Key): T | undefined {
    for (let i = 0; i < arg.length; i++) {
        const el = arg[i]
        if (el.code && el.code === code) {
            return el;
        }
        if (el.children && el.children.length > 0) {
            const ret = filterTree(el.children, code)
            if (ret) return ret;
        }
    }
}

export const useDictModel = createModel(function () {
    const [dictOptMode, setDictOptMode] = useState(DictOptModeEnum.ADD)
    const initDataSource: IMdDictDetail[] = [];
    //列表数据源
    const [dataSource, setDataSource] = useState<IMdDictDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedData, setSelectedData] = useState<IMdDictDetail>();
    const initFormValues = {};
    const [formValues, setFormValues] = useState<IMdDictFields>(initFormValues);

    const [selectParentData, setSelectParentData] = useState<IMdDictDetail>()
    const [optModelType, setOptModelType] = useState<OptModelTypeEnum>(OptModelTypeEnum.DETAIL)



    const getTreeData = () => {
        let treeData = trasDsToTreeData(dataSource, "code", "name");
        return treeData;
    }
    const disableData = async (status: boolean) => {
        // console.log(selectedRowKeys, selectedData)
        let { retData } = await dictService.disable({
            id: selectedData?.id,
            status: status ? 1 : 0
        });
        retData ? message.success("字典状态修改成功!") : message.error("字典状态修改失败!");
        setSelectedRowKeys([]);
        setSelectedData(undefined)
        retData && await loadData();
    }
    const loadData = async () => {
        let { retData } = await dictService.allTree({});
        // let treeData = trasDsToTreeData(retData, "code", "name");
        setDataSource(retData);
    }

    // const loadOneData = async (code: string) => {
    //     let { retData } = await dictService.one({ code });
    //     setSelectedData(retData);
    // }
    const syncData = async () => {
        let ret = await dictService.sync({});
        ret ? message.success("同步成功!重新加载数据...") : message.error("同步失败");
        ret && await loadData()
    }

    const updateFormValue = (value: IFieldValue<IMdDictFields>) => {
        setFormValues({ ...formValues, ...value });
    }

    return {
        dictOptMode,
        /***field***/
        dataSource,
        selectedRowKeys,
        formValues,
        selectedData,

        selectParentData,
        optModelType,

        /***method***/
        setDataSource,
        setSelectedRowKeys,
        loadData,
        syncData,
        disableData,
        getTreeData,
        setFormValues,
        updateFormValue,
        setDictOptMode,
        setSelectedData,

        setSelectParentData,
        setOptModelType,
    }
});
