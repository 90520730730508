import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { message, notification } from 'antd';

function getBase64Image(img: HTMLImageElement) {
  let canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL('image/jpeg', 0.8);
}

//传入图片路径，返回base64
function getBase64(img: string): Promise<string> {
  let image = new Image();
  image.setAttribute('crossOrigin', 'anonymous'); // 必须设置在src之前，跨域使用
  image.src = img;
  return new Promise((resolve, reject) => {
    if (img) {
      image.onload = () => {
        resolve(getBase64Image(image));
      }
    } else {
      reject('none')
    }
  })
}

/**
 * 图片批量打包zip并下载
 * @param imgSrcList 批量下载图片的路径
 * @param fileName 压缩文件的文件名
 */
export default function toZip(imgSrcList: string[], fileName: string) {
  let imgBase64: any[] = [];
  let zip = new JSZip();  //实例化一个压缩文件对象
  let imgFolder = zip.folder(fileName) as JSZip; //新建一个图片文件夹用来存放图片，参数为文件名
  for (let i = 0; i < imgSrcList.length; i++) {
    let src = imgSrcList[i];
    getBase64(src)
      .then(base64 => imgBase64.push(base64.substring(22)))
      .catch(error => console.log('error', error))
  }

  function polling() {
    setTimeout(() => {
      if (imgSrcList.length === imgBase64.length) {
        for (let i = 0; i < imgSrcList.length; i++) {
          imgFolder.file(fileName + "-" + (i + 1) + ".png", imgBase64[i], { base64: true });
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          FileSaver.saveAs(content, `${fileName}.zip`);
        });
        message.destroy()
        message.success('打包完成')
        notification.close('downloadImgToZIP')
      } else {
        notification.warning({
          message: '图片开始批量下载...',
          description: '已完成：' + imgBase64.length + '/' + imgSrcList.length,
          key: 'downloadImgToZIP',
        })
        console.log('已完成：' + imgBase64.length + '/' + imgSrcList.length)
        polling();
      }
    }, 300);
  }
  polling();
}
