import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum } from '@src/components/YhBox/Types'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm, IYhFormRef } from '@src/components/YhFormBox/Types'
import { globalPrompt } from '@src/components/YhMessage'
import { useDsModel } from '@src/model/dsModel'
import useNavModel from '@src/model/navModel'
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData, } from '@src/utils/constants'
import { importPropsConfig } from '@src/utils/importFile'
import { getBase64 } from '@src/utils/utils'
import { purchaseSupplierService } from '@src/views/user/UserService'
import { Button, Col, Form, message, Modal, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { usePurchaseSupplierModle } from '../supplierModel'

export const PurchaseSupplierDetail = () => {
  const formRef = useRef<IYhFormRef>();
  const personFormRef = useRef<IYhFormRef>();
  const bizFormRef = useRef<IYhFormRef>();
  const {
    authTypeDs,
  } = useDsModel()
  const {
    editModel,
    selectedRowData,
    loadData,
  } = usePurchaseSupplierModle()

  const { addNav, replaceNav } = useNavModel()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [businessFileList, setBusinessFileList] = useState<UploadFile[]>([])
  const [formCache, setformCache] = useState<any>()
  const [previewUrl, setPreviewUrl] = useState('')

  useEffect(() => {
    setFileList([{
      response: {
        retData: selectedRowData?.photo
      }
    } as any])
    setBusinessFileList([{
      url: getBaseImgLookUrl(selectedRowData?.businessLicense as string),
      response: {
        retData: selectedRowData?.businessLicense
      }
    } as any])
  }, [selectedRowData])

  const formConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    // disabled: editModel === 'look',
    initialValues: formCache ? { ...formCache } : {
      ...selectedRowData,
      createTime: moment(selectedRowData?.createTime),
      status: selectedRowData?.status === 1
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "微信昵称",
        disable: true,
        fieldName: "nickname",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "授权电话",
        fieldName: "autoTel",
        span: 6,
        rules: [
          { required: false, message: "请输入电话" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "电子邮箱",
        fieldName: "email",
        span: 6,
        rules: [
          { required: false, message: "请输入邮箱" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "密码",
        fieldName: "password",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "微信",
        fieldName: "wechat",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "账户ID",
        fieldName: "account",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "供应商类型",
        fieldName: "authTypeCode",
        disable: true,
        span: 6,
        ds: authTypeDs,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "备注",
        fieldName: "note",
        span: 6,
      }, {
        type: FormItemTypeEnum.Switch,
        fieldLabel: "供应商状态",
        fieldName: "status",
        span: 6,
      },
    ]
  };
  const personFormConfig: IYhForm = {
    labelCol: { span: 8 },
    // disabled: editModel === 'look',
    initialValues: {
      ...selectedRowData,
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "供应商名称",
        fieldName: "userName",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "联系电话",
        fieldName: "manualTel",
        span: 6,
        rules: [
          { required: true, message: "请输入电话" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "身份证号",
        fieldName: "numberId",
        span: 6,
        rules: [
          { required: true, message: "请输入邮箱" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "地址",
        fieldName: "address",
        span: 6,
      }
    ]
  };
  const bizFormConfig: IYhForm = {
    labelCol: { span: 9 },
    // disabled: editModel === 'look',
    initialValues: formCache ? { ...formCache } : {
      ...selectedRowData,
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "供应商名称",
        fieldName: "company",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "统一社会信用代码",
        fieldName: "uniSocCreCode",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "地址",
        fieldName: "address",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "详细地址",
        fieldName: "detailedAddress",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "姓名",
        fieldName: "userName",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "邮箱",
        fieldName: "email",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "联系电话",
        fieldName: "manualTel",
        span: 6,
        rules: [
          { required: true, message: "请输入电话" }
        ],
      }
    ]
  };

  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = async (info: any, type: 'photo' | 'business') => {
    type === 'photo' ? setFileList(info.fileList)
      : setBusinessFileList(info.fileList)
    const formData = await formRef.current?.getFieldsValue()
    const otherData = await bizFormRef.current?.getFieldsValue()
    setformCache({
      ...formData,
      ...otherData,
    })
  };

  const onSubmit = async () => {
    try {
      const formData = await formRef.current?.validateFields();
      var otherData = null
      if (selectedRowData?.authTypeCode === "customer-type.merchant") {
        otherData = await bizFormRef.current?.validateFields();
      } else {
        otherData = await personFormRef.current?.validateFields();
      }
      if (formData) {
        const params = {
          ...formData,
          ...otherData,
          status: formData.status ? 1 : 0,
          createTime: undefined,
          photo: fileList[0].response.retData,
          businessLicense: businessFileList[0].response.retData,
        }
        if (editModel === 'add') {
          const insertRet = await purchaseSupplierService.insert(params);
          if (insertRet.success) {
            await globalPrompt("message", { type: "success", text: '新增成功!' });
            await loadData()
            replaceNav("ucms-02-06-01", "ucms-02-06")
          } else {
            await globalPrompt("message", { type: "error", text: "新增失败!" + insertRet.retMsg });
          }
        } else {
          const updateParams = {
            ...params,
            id: selectedRowData?.id
          }
          console.log("修改供应商:", updateParams);
          const updateRet = await purchaseSupplierService.update(updateParams);
          if (updateRet.success) {
            await globalPrompt("message", { type: "success", text: '修改成功!' });
            await loadData();
            replaceNav("ucms-02-06-01", "ucms-02-06")
          } else {
            await globalPrompt("message", { type: "error", text: "修改失败!" + updateRet.retMsg });
          }
        }
      }
    } catch (e) {
      console.log("失败!");
    }
  }
  /**
   * 认证 
   */
  const handleAuthenticate = async () => {
    try {
      const { retData } = await purchaseSupplierService.authenticate(selectedRowData?.id as string)
      if (retData) {
        globalPrompt('message', { text: '认证成功', type: 'success' })
        loadData();
        replaceNav("ucms-02-06-01", "ucms-02-06")
      } else {
        globalPrompt('message', { text: '认证失败', type: 'error' })
      }
    } catch (error) {

    }
  }
  /**
   * 预览
   * @param type 预览类型
   * @param file 预览文件
   */
  const handlePreview = async (file: UploadFile) => {
    let url = ""
    if (!file.url && !file.preview) {
      url = await getBase64(file.originFileObj) as string;
    } else {
      url = file.url ?? file.preview as string
    }
    setPreviewUrl(url)
  };
  const Title = <>
    <span className="mr-20">供应商详情</span>
    <Description
      label="状态"
      value={selectedRowData?.authStatus === 1 ? "已认证" : "未认证"}
      color={DescriptionColorEnum.green}
      status={true}
    />
  </>
  const Action = (
    <>
      <Button type="link" shape="round" onClick={() => { onSubmit() }}>保存</Button>
      <Button type="link" shape="round" hidden={selectedRowData?.authStatus === 1} onClick={() => { handleAuthenticate() }}>认证</Button>
      <Button type="link" shape="round" onClick={() => { addNav('ucms-02-06') }}>返回</Button>
    </>
  )

  return (
    <div>
      <YhBox title={Title} action={Action} />
      <YhBox title="基础信息" style={{ marginTop: '15px', padding: "20px" }}>
        <Col span="6">
          <Form.Item
            label="头像"
            name="photo"
            labelCol={{ span: 8 }}
            required={true}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              disabled={true}
            // beforeUpload={beforeUpload}
            // {...importPropsConfig('/uploadAttach', (v: any) => handleChange(v, 'photo'))}
            >
              {
                fileList.length > 0 ?
                  <img src={fileList[0].response.retData} alt="用户头像" style={{ width: '100%' }} />
                  : <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>上传头像</div>
                  </div>
              }
            </Upload>
          </Form.Item>
        </Col>
        <YhForm {...formConfig} >
        </YhForm>
      </YhBox>
      <YhBox hidden={selectedRowData?.authTypeCode === "customer-type.merchant"} title="个人认证信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm formRef={personFormRef} {...personFormConfig} ></YhForm>
      </YhBox>
      <YhBox hidden={selectedRowData?.authTypeCode === "customer-type.personal"} title="商家认证信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm formRef={bizFormRef} {...bizFormConfig} >
          <Col span="6">
            <Form.Item
              label="营业执照"
              name="businessLicense"
              labelCol={{ span: 9 }}
              required={true}
            >
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                maxCount={1}
                beforeUpload={(file) => beforeUpload(file)}
                action={getBaseImgUploadUrl()}
                data={getBaseUploadData()}
                onChange={(info) => {
                  setBusinessFileList(info.fileList)
                  if (info.file.status === 'done' && Number(info.file.response.retCode) === 0) {
                    message.info({ content: `${info.file.name}导入${info.file.response.retData}`, duration: 8 });
                    handleChange(info, 'business')
                  } else if (info.file.status === 'error' && info.file.response && info.file.response.retData) {
                    message.error(`${info.file.response.retData}：${info.file.name}导入异常，请尝试重新导入`);
                  } else if (info.file.status === 'error') {
                    message.error("导入错误")
                  }
                }}
                onPreview={(file) => handlePreview(file)}
                fileList={businessFileList}
                onRemove={(e) => setBusinessFileList([])}
              >
                {businessFileList.length >= 1 ? null : <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传营业执照</div>
                </div>}
              </Upload>
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
      {/* 图片预览 */}
      <Modal visible={!!previewUrl} title={'图片预览'} footer={null} onCancel={() => setPreviewUrl('')} >
        <img alt="example" style={{ width: '100%' }} src={previewUrl} />
      </Modal >
    </div>
  )
}