import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchValue, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { useDsModel } from '@src/model/dsModel'
import React from 'react'
import { usePurchaseCollectModel } from '../collectModel'

export function PageHeader () {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        // onReset
    } = usePurchaseCollectModel()
    const { collectTypeDs } = useDsModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '车辆编号',
            fieldName: 'vehicleCode',
            xxl: 4,
            span: 4
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '供应商名称',
            fieldName: 'userName',
            xxl: 4,
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '收藏类型',
            fieldName: 'collectTypeCode',
            xxl: 4,
            span: 4,
            options: collectTypeDs
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: '收藏时间',
            fieldName: 'createTime',
            xxl: 4,
            span: 4,
        }
    ]

    return (
        <YhPageHeader
            title="收藏管理列表"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    )
}