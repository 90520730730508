import { useState, useEffect, useRef } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage } from '../../../types/FmsTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { IDynamicSearch, IDynamicDetail, IDynamicFields } from './dynamicType';
import { dynamicService } from './dynamicService';
import { transPgToPagination, urlParse1 } from '../../../utils/utils';
import { IYhFormRef } from '@src/components/YhFormBox/Types';
import BraftEditor from 'braft-editor';
import { UploadFile } from 'antd/lib/upload/interface';
import { globalPrompt } from '@src/components/YhMessage';

export const useDynamicModel = createModel(function () {
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const initSearchParam: IDynamicFields = {};
	const initDataSource: IDynamicDetail[] = [];

	const formRef = useRef<IYhFormRef>();

	// 查询条件
	const [searchParam, setSearchParam] = useState<IDynamicFields>(initSearchParam);
	// 列表数据源
	const [dataSource, setDataSource] = useState<IDynamicDetail[]>(initDataSource);
	// 表格选中行key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	// 表格选中的值
	const [selectedRow, setSelectRow] = useState<IDynamicDetail>({});
	// 列表数据源分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	const [loading, setLoading] = useState<boolean>(false);

	// 富文本 新增
	const [addEditorState, setAddEditorState] = useState(BraftEditor.createEditorState(null)); //富文本
	// 富文本 编辑
	const [editEditorState, setEditEditorState] = useState(BraftEditor.createEditorState(null)); //富文本
	const [previewImage, setPreviewImage] = useState(Boolean); //商品缩略图预览
	// 上传图片的地址
	const [imageUrl, setImageUrl] = useState('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [previewUrl, setPreviewUrl] = useState('');

	// 编辑缓存
	const [editFormVal, setEditFormVal] = useState<IDynamicFields>({});
	const [editGoodsList, setEditGoodsList] = useState<IDynamicFields[]>([]);
	// 新增缓存
	const [addFormVal, setAddFormVal] = useState<IDynamicFields>({});
	const [addGoodsList, setAddGoodsList] = useState<IDynamicFields[]>([]);

	useEffect(() => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectRow(selectedData as IDynamicFields);
	}, [setSelectedRowKeys]);

	// 更新页码
	const updataPgCfg = (updataParam: TablePaginationConfig) => {
		setPgCfg({ ...pgCfg, ...updataParam });
	};

	// 更新搜索条件
	const updataSearchParam = (updataParam: IDynamicSearch) => {
		setSearchParam({ ...searchParam, ...updataParam });
	};

	// 重置搜索条件
	const resetSearchParam = () => {
		setSearchParam(initSearchParam);
		loadData(initPgCfg, {});
		setSelectedRowKeys([]);
		setSelectRow({});
	};

	// 数据加载
	const loadData = async (page?: ISearchPage, searchValue: IDynamicDetail = searchParam) => {
		const searchParams: IDynamicSearch = {
			...searchValue,
			insertType: 'dynamic',
			pg: {
				orders: [
					{
						rule: 'ASC',
						column: 'createTime',
					},
				],
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await dynamicService.page(searchParams);
		const { items, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(items);
	};

	// 删除
	const onRemove = async (id: string) => {
		try {
			const { retData } = await dynamicService.remove(id);
			if (retData) {
				globalPrompt('message', { text: '删除成功', type: 'success' });
				loadData();
			} else {
				globalPrompt('message', { text: '删除失败', type: 'error' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	//表单新增修改
	const updateAddFormVal = (val: object | null) => {
		val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal({});
	};
	//表单编辑修改
	const updateEditFormVal = (val: object | null) => {
		val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
	};

	// 新增api
	const insertDetail = async (params: IDynamicFields) => {
		let newParams = {
			...params,
		};
		let { retData } = await dynamicService.insert(newParams);
		return retData;
	};

	//编辑api
	const editDetail = async (params: IDynamicFields) => {
		let newParams = {
			...params,
		};
		let { retData } = await dynamicService.update(newParams);
		return retData;
	};
	// 获取一条数据
	const getDetailEditOne = async (id: string) => {
		let { retData } = await dynamicService.one(id);
		const { ...form } = retData;
		if (retData) {
			setEditFormVal(form);
			setEditEditorState(BraftEditor.createEditorState(form.articl));
		} else {
			globalPrompt('message', { type: 'error', text: '操作失败请重试!' });
		}
	};

	// 翻译
	const translate = async (mode: string) => {
		let isEdit = mode === 'edit';
		let formVal = isEdit ? editFormVal : addFormVal;
		let editorState = isEdit ? editEditorState : addEditorState ?? '';
		let arrIndex: string[] = [];
		let arr: string[] = editorState.match(/(<[a-zA-Z]+.*?>|<\/[a-zA-Z]+.*?>|[^><]+)/g) ?? [];
		let str: string = '';
		arr.forEach((item, index) => {
			if (item.indexOf('<') < 0 && item.indexOf('>') < 0) {
				arrIndex.push(`${index}@`);
				str += `&${index}@=${arr[index]}`;
			}
		});
		// console.log(arrIndex, arr);
		let objStr = {
			source: formVal.source,
			keyword: formVal.keyword,
			title: formVal.title?.trim(),
			desc: formVal.desc,
			createUser: formVal.createUser,
			article: str,
		};
		// console.log(objStr);
		let retData = await dynamicService.translate(objStr);
		// console.log('返回数据', retData);

		let obj: any = urlParse1(retData.retData?.article ?? '');
		(isEdit ? setEditFormVal : setAddFormVal)({
			source: retData.retData.source,
			keyword: retData.retData.keyword,
			title: retData.retData.title,
			desc: retData.retData.desc,
			createUser: retData.retData.createUser,
			createTime: editFormVal?.createTime,
			author: editFormVal?.author,
			id: editFormVal?.id,
		});
		arrIndex.forEach((item) => {
			arr[parseInt(item)] = obj[item];
		});
		(isEdit ? setEditEditorState : setAddEditorState)(BraftEditor.createEditorState(arr.join('')));
	};

	//重置缓存
	const resetEditCatch = (type?: string) => {
		updateEditFormVal(null); //表单
		setEditGoodsList([]);
	};
	const resetAddCatch = (type?: string) => {
		updateAddFormVal(null);
		setAddGoodsList([]);
	};
	return {
		initPgCfg,
		searchParam,
		dataSource,
		selectedRowKeys,
		selectedRow,
		pgCfg,
		loading,
		setSearchParam,
		setDataSource,
		setSelectedRowKeys,
		setSelectRow,
		setPgCfg,
		setLoading,
		updataPgCfg,
		updataSearchParam,
		resetSearchParam,
		loadData,
		onRemove,
		formRef,
		addEditorState,
		setAddEditorState,
		editEditorState,
		setEditEditorState,
		previewImage,
		setPreviewImage,
		imageUrl,
		setImageUrl,
		fileList,
		setFileList,
		previewUrl,
		setPreviewUrl,
		editFormVal,
		setEditFormVal,
		editGoodsList,
		setEditGoodsList,
		addFormVal,
		setAddFormVal,
		addGoodsList,
		setAddGoodsList,
		updateAddFormVal,
		updateEditFormVal,
		resetEditCatch,
		resetAddCatch,
		insertDetail,
		editDetail,
		getDetailEditOne,
		translate,
	};
});
