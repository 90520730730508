import { createModel } from 'hox';
import { message } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { IPgProps, ISearchPage, ISearchPageField } from '@src/types/FmsTypes';
import React, { useState, useEffect } from 'react';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@src/utils/constants';
import {
	IPurchaseCollectDetail,
	IPurchaseCollectFields,
	IPurchaseCollectSearch,
} from './collectTypes';
import { purchaseCollectService } from './collectService';
import { transPgToPagination } from '@src/utils/utils';
import useGlobalModel from '@src/model/globalModel';

export const usePurchaseCollectModel = createModel(function () {
	const initSearchParam: IPurchaseCollectFields = {};
	const initDataSource: IPurchaseCollectDetail[] = [];
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};

	//查询条件
	const [searchParam, setSearchParam] = useState<IPurchaseCollectFields>(initSearchParam);
	// 列表数据源
	const [dataSource, setDataSource] = useState<IPurchaseCollectDetail[]>(initDataSource);
	// 表格选中行
	const [selectedRow, setSelectedRow] = useState<IPurchaseCollectDetail>({});
	// 表格选中行key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	// 列表数据源分页参数
	const [pgCfg, setPgcfg] = useState<TablePaginationConfig>(initPgCfg);

	const [resetState, setResetState] = useState(false)

	useEffect(() => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectedRow(selectedData as IPurchaseCollectFields);
	}, [selectedRowKeys]);

	const updatePgCfg = (updateParam: TablePaginationConfig) => {
		setPgcfg({ ...pgCfg, ...updateParam });
	};

	const updateSearchParam = (updateParam: IPurchaseCollectSearch) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	// 重置
	const resetSearchParam = () => {
		setSearchParam(initSearchParam);
		loadData(initPgCfg, {})
	};

	// 加载数据源
	const loadData = async (page?: ISearchPage, searchValue: IPurchaseCollectDetail = searchParam) => {
		const searchParams: IPurchaseCollectSearch = {
			...searchValue,
			userType: "supplier",
			pg: {
				orders: [
					{
						rule: 'ASC',
						column: 'createTime',
					},
				],
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await purchaseCollectService.page(searchParams);
		const { items, ...pg } = retData;
		setPgcfg(transPgToPagination(pg));
		setDataSource(items);
	};
	return {
		searchParam,
		setSearchParam,
		dataSource,
		setDataSource,
		selectedRow,
		setSelectedRow,
		selectedRowKeys,
		setSelectedRowKeys,
		pgCfg,
		setPgcfg,
		updatePgCfg,
		updateSearchParam,
		resetSearchParam,
		loadData,
		initPgCfg,
	};
});
