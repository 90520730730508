import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSaleOrderModel } from "../orderModel";
import { ISaleOrderDetail, ISaleOrderFields, ISaleOrderSearch } from '../orderTypes'
import moment from 'moment';

export function DataTable() {
    const {
        dataSource,
        selectedRowKeys,
        pgCfg,
        loadData,
        setSelectedRowKeys
    } = useSaleOrderModel();
    const columns: ColumnsType<ISaleOrderDetail> = [
        {
            title: '序号',
            align: 'center',
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '订单编号',
            align: 'center',
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '客户名称',
            align: 'center',
            dataIndex: 'userName',
        }, {
            title: '车辆类型',
            align: 'center',
            dataIndex: 'typeName',
        }, {
            title: '贸易国别',
            align: 'center',
            dataIndex: 'countryName',
        }, {
            align: 'center',
            title: "车辆品牌",
            dataIndex: 'modelName'
        },
        {
            align: 'center',
            title: "车辆型号",
            dataIndex: 'vehicleModel'
        }, {
            title: '数量',
            align: 'center',
            dataIndex: 'puroderNumber',
        }, {
            title: '驱动形式',
            align: 'center',
            dataIndex: 'drivingFormName',
        }, {
            title: '马力',
            align: 'center',
            dataIndex: 'horsepowerName',
        }, {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            render: (v, r, i) => v === 0 ? '已开单'
                : v === 1 ? '已报价'
                    : v === 2 ? '签合同'
                        : v === 3 ? '已付款'
                            : v === 4 ? '已报关'
                                : v === 5 ? '已发运'
                                    : v === 6 ? '已清关'
                                        : v === 7 ? '已售出'
                                            : '已取消'
        }, {
            title: '创建人',
            align: 'center',
            dataIndex: 'createUser',
        }, {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            render: (v) => moment(v).format("YYYY-MM-DD HH:mm:ss")
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleOrderDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: event => {
                    if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([]);
                    } else {
                        setSelectedRowKeys([record?.id ?? ""]);
                    }
                },
            })}
        />
    )
}