import { ITreeData, IYhFormRef } from "@src/components/YhFormBox/Types";
import { globalPrompt } from "@src/components/YhMessage";
import useNavModel from "@src/model/navModel";
import { EditModeType, ISearchPage, ISearchPageField, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { convertRes2Blob } from "@src/utils/exportFile";
import { transPgToPagination } from "@src/utils/utils";
import { dictService } from "@src/views/base/dict/DictService";
import { saleClientService } from "@src/views/user/UserService";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import { useRef, useState } from "react";
import { saleDemandService } from "./DemandService";
import { ISaleDemandDetail, ISaleDemandFields } from "./DemandTypes";

const useModel = () => {
  const initSearchParam: ISaleDemandFields = {};
  const initDataSource: ISaleDemandDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数

  //查询条件
  const [searchParam, setSearchParam] = useState<ISaleDemandFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<ISaleDemandDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<ISaleDemandDetail>();
  const [selectedRow, setSelectedRow] = useState<ISaleDemandDetail[]>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [orderVisible, setOrderVisible] = useState(false);
  const [purchaseVisible, setPurchaseVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")

  const [resetState, setResetState] = useState(false)

  const [saleDemandTypeDs, setSaleDemandTypeDs] = useState<OptionDsType[]>()


  const getDs = async () => {
    try {

      let saleDemandDs = await dictService.oneTreeDs({ code: "saleDemand-type" })
      setSaleDemandTypeDs(saleDemandDs)
    } catch (error) {

    }
  }

  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: ISaleDemandFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }
  const loadData = async (page?: ISearchPage) => {
    const searchParams: ISearchPageField = {
      ...searchParam,
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await saleDemandService.page(searchParams);
    const { items, ...pg } = retData;
    setSelectedRowData(undefined)
    setSelectedRowKeys([])
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item?.id as string } }));
  }

  const exportSaleDemand = async () => {
    const result = await saleDemandService.export()
    convertRes2Blob(result)
  }

  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    orderVisible,
    resetState,
    purchaseVisible,
    editModel,
    saleDemandTypeDs,
    selectedRow,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setOrderVisible,
    onSearch,
    onReset,
    setPurchaseVisible,
    setEditModel,
    getDs,
    setSelectedRow,
    exportSaleDemand,
  }
}

export const useSaleDemandModle = createModel(useModel)