import { httpRequest } from '@src/utils/HttpRequest';
import { IPage } from '@src/types/FmsTypes';
import {
	IPurchaseOrderDetail,
	IPurchaseOrderSearch,
	IPVehicleDetail,
	IPurchaseOrderFields,
	IInvalidate,
	IDetailOne,
	ShcProcess,
	IDeletes,
} from './orderTypes';

class PurchaseOrderService {
	async page(params: IPurchaseOrderSearch) {
		const apiUrl = '/shc2/order/search_page';
		return await httpRequest.post<IPage<IPurchaseOrderDetail>>(apiUrl, params);
	}

	async one(id: string) {
		const apiUrl = `/shc2/order/search_one/${id}`;
		return await httpRequest.post<IDetailOne>(apiUrl);
	}

	async update(params: IDetailOne) {
		const apiUrl = '/shc2/order/update';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 进度条新增
	async insert(params: ShcProcess) {
		const apiUrl = '/vrm2/sale/order/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 取消订单
	async remove(params: IInvalidate) {
		const apiUrl = '/shc2/order/remove';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 采购订单新增
	async orderInsert(params: IPurchaseOrderFields) {
		const apiUrl = '/shc2/order/schedule/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 明细删除
	async delete(params: IDeletes) {
		const apiUrl = 'shc2/vehicle/delete';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	async findOne(id: string) {
		const url = `/shc2/source/search_one/${id}`;
		return await httpRequest.post<IPVehicleDetail>(url);
	}
}

export const purchaseOrderService = new PurchaseOrderService();
