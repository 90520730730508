import React from "react";
import { usePurchaseOrderModel } from "../orderModel";
import { ISearchValue, SearchTypeEnum } from "@src/components/YhPageHeader/Types";
import { YhPageHeader } from "@src/components/YhPageHeader";
import { POStatusDs } from '@src/types/FmsDs'
import { useDsModel } from '@src/model/dsModel';

export function PageHeader () {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchaseOrderModel()
    const { vehicleTypeDs} = useDsModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "订单编号",
            xxl: 4,
            span: 4,
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "车辆类型",
            xxl: 4,
            span: 4,
            fieldName: "typeCode",
            options:vehicleTypeDs
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商名称",
            xxl: 4,
            span: 4,
            fieldName: "userName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            xxl: 4,
            span: 4,
            fieldName: "status",
            options:POStatusDs
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: "创建时间",
            xxl: 4,
            span: 4,
            fieldName: "creactTime"
        }
    ]

    return (
        <YhPageHeader
            title="采购订单列表"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}