import React, { useState, useEffect, FC } from "react";
import { Table, Button, Input, Select, DatePicker } from "antd";
import { YHModal } from "@src/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@src/components/YhBox";
import { YhBut } from "@src/components/YhButton";
import { ISVehicleResourceFields } from '../../vehicleResource/VehicleResourceTypes'
import { useSaleOrderModel } from '../orderModel'
import useNavModel, { RouterParamType } from '@src/model/navModel';
import moment from 'moment';
import { useDsModel } from '@src/model/dsModel';
enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}

export const AddVehicleResourceModal = () => {
    const {
        drawerVisible,
        workDataSource,
        workPgCfg,
        addDataSource,
        searchWorkData,
        setWorkDataSource,
        resetWorkloadData,
        setSearchWorkData,
        setAddDataSource,
        transformsToPoDetailList,
        workloadData,
        setDrawerVisible,
    } = useSaleOrderModel();
    const { getSelectedNavState, } = useNavModel()
    const { vehicleTypeDs, vehicleBrandDs } = useDsModel()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    useEffect(() => {
        workloadData()
    }, [])

    const column = (optype: number) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '车辆编号',
            width: 160,
            dataIndex: 'vehicleCode',
            fixed: "left",
            align: "center",
        }, {
            title: '车辆类型',
            width: 160,
            dataIndex: 'typeName',
            align: "center",
        }, {
            title: '车辆品牌',
            width: 160,
            dataIndex: 'modelName',
            align: "center",
        }, {
            title: '车辆型号',
            width: 160,
            dataIndex: 'vehicleModel',
            align: "center",
        }, {
            title: '状态',
            width: 160,
            dataIndex: 'status',
            align: "center",
            render: (v: any, r: any, i: any) => v === 1 ? '待售中'
                : v === 2 ? '已转接'
                    : '已售出'
        }, {
            title: '驱动形式',
            width: 160,
            dataIndex: 'drivingFormName',
            align: "center",
        }, {
            title: '马力',
            width: 160,
            dataIndex: 'horsepowerName',
            align: "center",
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'number',
            align: "center"
        }, {
            title: '燃料种类',
            width: 160,
            dataIndex: 'fuelTypeName',
            align: "center"
        },
        {
            title: '创建人',
            width: 160,
            dataIndex: 'createUser',
            align: "center"
        }, {
            title: '创建时间',
            width: 160,
            dataIndex: 'createTime',
            render: (v: any) => moment(v).format("YYYY-MM-DD"),
            align: "center"
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            align: "center",
            render: (text: string, record: ISVehicleResourceFields) => optype == OPT_TYPE.ADD ? (
                <YhBut
                    typeName="add"
                    text="添加"
                    click={() => add(record)}
                    disabled={!!addDataSource.find(item => item.vehicleCode === record.vehicleCode)}
                />
            ) : (
                <YhBut
                    typeName="delete"
                    text="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];

    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISVehicleResourceFields, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType == OPT_TYPE.ADD ? notHandleSave : handleSave,
                }),
            }
        })

    const notHandleSave = (row: ISVehicleResourceFields) => {
        const index = workDataSource.findIndex((item) => row.vehicleCode === item.vehicleCode);
        const item = workDataSource[index];
        setWorkDataSource([...workDataSource])
    }

    const handleSave = (row: ISVehicleResourceFields) => {
        const index = addDataSource.findIndex((item) => row.vehicleCode === item.vehicleCode);
        const item = addDataSource[index];
        setAddDataSource([...addDataSource])
    }

    const add = async (record: ISVehicleResourceFields) => {
        setAddDataSource(origin => [...origin, record])
    }
    const remove = async (record: ISVehicleResourceFields) => {
        const filterAddDataSource = addDataSource.filter(item => item.vehicleCode != record.vehicleCode)
        setAddDataSource(filterAddDataSource)
    }
    const onOK = async () => {
        transformsToPoDetailList(detailsInfo.mode)
        onCancel()
    }
    const onCancel = () => {
        setDrawerVisible(false);
        setAddDataSource([]);
        resetWorkloadData()

    };
    const rightChildren = <div className="flex">
        <Select
            allowClear
            className="ant-select-round  mr-10"
            style={{ width: "150px" }}
            options={vehicleTypeDs}
            value={searchWorkData.typeCode}
            onChange={(value) => {
                setSearchWorkData({ ...searchWorkData, typeCode: value })
            }}
            placeholder="车辆类型"
        />
        <Select
            allowClear
            className="ant-select-round mr-10"
            style={{ width: "150px" }}
            options={vehicleBrandDs}
            value={searchWorkData.modelCode}
            onChange={(value) => {
                setSearchWorkData({ ...searchWorkData, modelCode: value })
            }}
            placeholder="品牌型号"
        />
        <DatePicker
            allowClear={true}
            className=" ant-picker-round  mr-10"
            value={searchWorkData.createTime ? moment(searchWorkData.createTime) : undefined}
            format="YYYY-MM-DD"
            onChange={(value) => { setSearchWorkData({ ...searchWorkData, createTime: value ? moment(value).format("YYYY-MM-DD") : undefined }) }}
            style={{ width: "150px" }}
        />
        <Button onClick={() => workloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkloadData()} shape="round">重置</Button>
    </div>


    return <YHModal
        visible={drawerVisible}
        onOk={onOK}
        onCancel={onCancel}
        title="车源信息"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选车源信息" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                size="small"
                rowKey={record => record.vehicleCode as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<ISVehicleResourceFields>}
                dataSource={workDataSource}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData({ pageNum, pageSize });
                    }
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选车源信息">
            <Table
                size="small"

                rowKey={record => record.vehicleCode as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<ISVehicleResourceFields>}
                dataSource={[...addDataSource]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}