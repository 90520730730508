import { YhBut } from '@src/components/YhButton';
import { YHDrawer } from '@src/components/YhDrawer'
import { YhPageHeader } from '@src/components/YhPageHeader';
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types';
import { useDsModel } from '@src/model/dsModel';
import useGlobalModel from '@src/model/globalModel';
import useNavModel from '@src/model/navModel';
import { IGMsgFields, ISearchPage, ISearchPageField } from '@src/types/FmsTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@src/utils/constants';
import { transPgToPagination } from '@src/utils/utils';
import { usePVehicleResourceModle } from '@src/views/purchase/vehicleResource/vehicleResourceModel';
import { IPVehicleResourceDetail } from '@src/views/purchase/vehicleResource/VehicleResourceTypes';
import { useSaleDemandModle } from '@src/views/sale/demand/demandModel';
import { saleDemandService } from '@src/views/sale/demand/DemandService';
import { ISaleDemandDetail } from '@src/views/sale/demand/DemandTypes';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Key } from 'node:readline';
import React, { useEffect, useState } from 'react';
import { gMsgService } from './MessageService';

export const ClobalMessageDrawer = () => {
  const {
    vehicleBrandDs,
  } = useDsModel()
  const { addNav } = useNavModel()
  const initSearchParam: IGMsgFields = {};
  const initDataSource: IGMsgFields[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  const { setGMsgVisible, gMsgVisible, setGMsgTotal } = useGlobalModel();
  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
  //列表数据源
  const [dataSource, setDataSource] = useState<IGMsgFields[]>(initDataSource);
  //查询条件
  const [searchParam, setSearchParam] = useState<IGMsgFields>(initSearchParam);
  const [resetState, setResetState] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const {
    setEditModel,
    setSelectedRowData,
  } = usePVehicleResourceModle()
  const {
    setEditModel: setDemandEditModel,
    setSelectedRowData: setDemandRowData
  } = useSaleDemandModle()
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Select,
      xxl: 5,
      span: 5,
      placeholder: '单据类型',
      fieldName: 'type',
      options: [
        {
          label: '车源信息',
          value: '车源信息'
        },
        {
          label: '购车意向',
          value: '购车意向'
        }
      ],
      size: "small",
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 5,
      span: 5,
      placeholder: '车辆品牌',
      fieldName: 'modelCode',
      size: 'small',
      options: vehicleBrandDs
    },
    {
      type: SearchTypeEnum.Input,
      xxl: 5,
      span: 5,
      placeholder: '创建人',
      fieldName: 'createUser',
      size: 'small',
    },
    {
      type: SearchTypeEnum.Datepicker,
      xxl: 5,
      span: 5,
      placeholder: '创建时间',
      fieldName: 'createTime',
      size: "small",
    },
  ]

  const columns: ColumnsType<IGMsgFields> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "阅读状态",
      dataIndex: 'status',
      render: v => v === 1 ? '已读' : '未读'
    },
    {
      align: 'center',
      title: "车辆编号",
      dataIndex: 'vehicleCode'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "车辆品牌",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆型号",
      dataIndex: 'vehicleModel'
    },
    {
      align: 'center',
      title: "单据类型",
      dataIndex: 'type'
    },
    {
      align: 'center',
      title: "创建人",
      dataIndex: 'createUser'
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime'
    },
    {
      align: 'center',
      title: "操作",
      render: (v) =>
        <YhBut text="详情" type="green" typeName="icon-chakan" click={() => handleMoveOne(v)} />
    }
  ]

  const loadData = async (page?: ISearchPage) => {
    if (timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }
    const searchParams: ISearchPageField = {
      ...searchParam,
      pg: {
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await gMsgService.page(searchParams);
    const { items, ...pg } = retData;
    setGMsgTotal(retData?.number ?? 0)
    setPgCfg(transPgToPagination(pg));
    setDataSource(items);
    const time = setTimeout(() => {
      loadData()
    }, 2000)
    setTimer(time)
  }
  const updateSearchParam = (updateParam: IGMsgFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }
  const handleMoveOne = async (rowData: IGMsgFields) => {
    const { retData } = await gMsgService.findOne(rowData.documentsId as string)
    console.log(rowData)
    setGMsgVisible(false)
    if (rowData?.type === '购车意向') {
      // const { retData: data } = await saleDemandService.findOne(rowData.documentsId as string)
      setDemandRowData(retData as ISaleDemandDetail)
      setDemandEditModel('edit');
      addNav('ucms-03-03-01')
    } else {
      setSelectedRowData(retData as IPVehicleResourceDetail)
      setEditModel("edit")
      addNav('ucms-02-02-01')
    }
  }
  return (
    <YHDrawer
      title="消息列表"
      width={1000}
      visible={gMsgVisible}
      onCancel={() => { setGMsgVisible(false) }}
      showFooter={false}
    >
      <YhPageHeader
        searchFields={searchFields}
        searchValues={searchParam}
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={loadData}
      />
      <Table
        // style={{ cursor: "pointer" }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          ...pgCfg,
          position: ["bottomCenter"],
          onChange: (pageNum, pageSize) => {
            loadData({ pageNum, pageSize }).then();
          }
        }}
      />
    </YHDrawer>
  )
}