import { Tree } from "antd"
import React from "react"
import { YhBox } from "../YhBox"
import { IDictTree } from "./Types"


const DictTree = (props: IDictTree) => {
    const {
        action,
        onSelect,
        selectedKeys,
        treeData,
        treeTitle,
    } = props


    return (
        <YhBox
            action={action}
            title={treeTitle ?? "字典目录"}
            width="500px"
            maxHeight="650px"
        >
            <Tree
                className="p-15"
                treeData={treeData}
                selectedKeys={selectedKeys}
                selectable={true}
                onSelect={onSelect}
            />
        </YhBox>
    )
}
export default DictTree