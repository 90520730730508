import React, { FC } from "react";
import { IFormItem } from "../Types";
import { DatePicker, Form } from "antd";

export const __FormDatePicker: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <DatePicker
                format={props.format ?? "YYYY-MM-DD HH:mm:ss"}
                showTime={props.showTime}
                disabled={props.disable}
                disabledTime={props.disabledDateTime}
                placeholder={props.placeholder ?? ''}
                allowClear={props.allowClear}
                showNow={props.showNow ?? false}
            />
        </Form.Item>
    )
}