import React from 'react'
import { DataTable } from "./DataTable";
import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from "@src/components/YhBox";

export const DataBox = () => {
    return (
        <YhBox title={<OrderedListOutlined />} >
            <DataTable />
        </YhBox>
    )
}