import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import useNavModel from '@src/model/navModel';
import { Button, message, Upload } from 'antd';
import React from 'react'
import { DataTable } from './DataTable'
import { usePVehicleResourceModle } from '../vehicleResourceModel';
import { pVehicleResourceService } from '../VehicleResourceService';
import { globalPrompt } from '@src/components/YhMessage';
import useGlobalModel from '@src/model/globalModel';

export function DataBox() {
  const { addNav } = useNavModel()
  const { user } = useGlobalModel()
  const {
    selectedRow,
    selectedRowData,
    setOrderVisible,
    setClientVisible,
    exportVehicleResource,
    selectedRowKeys,
    setSelectedRowData,
    setSelectedRowKeys,
    setEditModel,
    handleRemove,
    onReset,
    loadOneData,
  } = usePVehicleResourceModle()
  const action = (
    <>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={() => {
          setSelectedRowData(undefined)
          setSelectedRowKeys([])
          setEditModel('add')
          addNav('ucms-02-02-01');
        }}
      >新增</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={!(
          selectedRowKeys.length === 1 && (selectedRowData?.status === 0 || selectedRowData?.status === 1)
        )}
        onClick={async () => {
          await loadOneData()
          setEditModel("edit")
          addNav('ucms-02-02-01')
        }}
      >编辑</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length !== 1}
        onClick={async () => {
          await loadOneData()
          setEditModel("look")
          addNav('ucms-02-02-01')
        }}
      >查看</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={!(
          selectedRowKeys.length === 1 && (selectedRowData?.status === 0 || selectedRowData?.status === 1)
        )}
        onClick={() => { handleRemove() }}
      >删除</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={(selectedRowKeys.length === 1 && selectedRowData?.status !== 1) || selectedRowKeys.length === 0}
        onClick={() => {
          if (selectedRow?.findIndex(item => item.status !== 1) !== -1) return globalPrompt('message', { type: "warning", text: "当前选中车源有不可转订单车源！" })
          setOrderVisible(true)
        }}
      >转订单</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length !== 1}
        onClick={async () => {
          await loadOneData()
          setClientVisible(true)
        }}
      >转客户车源</Button>
      <Upload
        showUploadList={false}
        className="ml-15 mr-15"
        {
        ...pVehicleResourceService.import(onReset, user.userName as string)}
        name="vehicleFile"
      >
        <Button
          className="mr-5"
          type="link"
          shape="round"
        >导入</Button>
      </Upload>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={exportVehicleResource}
      >导出</Button>
    </>
  );
  return (
    <YhBox title={<OrderedListOutlined />} action={action}>
      <DataTable />
    </YhBox>
  )
}