import { YhBox } from '@src/components/YhBox'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import { YHModal } from '@src/components/YHModal'
import { Col, Form, Input, message, Modal, Row, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useEffect, useRef, useState } from 'react'
import { useSVehicleResourceModle } from '../../vehicleResource/vehicleResourceModel'
import { useSaleOrderModel } from '../orderModel'
import moment from 'moment'
import { useDsModel } from '@src/model/dsModel'
import { IUploadRef, UploadFiles } from '@src/views/purchase/vehicleResource/view/UploadFiles'
import useNavModel, { RouterParamType } from '@src/model/navModel'
import { BooleanFlagDs } from '@src/types/FmsDs'

export const LookVehicleResourceModel = () => {
    const uploadRef = useRef<IUploadRef>()
    const {
        lookDrawerVisible,
        setLookDrawerVisible,
        lookFormVal
    } = useSaleOrderModel()
    const {
        vehicleTypeDs,
        gearboxTypeDs,
        drivingTypeDs,
        fuelTypeDs,
        powerTypeDs,
        vehicleBrandDs,
        labelDs,
        engineDs,
        colorDs,
        emissionDs,
    } = useDsModel()
    const { formRef } = useSVehicleResourceModle()
    const [loading, setLoading] = useState<Boolean>(false)
    const [imageUrl, setImageUrl] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewImage, setPreviewImage] = useState(false)
    const [previewUrl, setPreviewUrl] = useState('')
    const [previewVideo, setPreviewVideo] = useState(false)
    const [reject, setReject] = useState("")
    const [showReject, setShowReject] = useState<boolean>(false)
    const [formCache, setformCache] = useState<any>()
    const { getSelectedNavState, } = useNavModel()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    useEffect(() => {
        uploadRef.current?.setAllFile({
            appReport: lookFormVal?.ShcFilemgr?.appReport,
            photo: lookFormVal?.ShcFilemgr?.photo?.split(","),
            registerInfo: lookFormVal?.ShcFilemgr?.registerInfo,
            video: lookFormVal?.ShcFilemgr?.video,
        })
    }, [lookFormVal, lookDrawerVisible])

    const baseFormConfig: IYhForm = {
        formRef,
        labelCol: { span: 8 },
        disabled: true,
        initialValues: {
            ...lookFormVal,
            createTime: lookFormVal.createTime && moment(lookFormVal.createTime).format("YYYY-MM-DD HH:mm:ss"),
            produceTime: lookFormVal.produceTime && moment(lookFormVal.produceTime)
        },
        justify: 'start',
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "typeCode",
                span: 6,
                ds: vehicleTypeDs,
                rules: [
                    { required: true, message: "请选择车辆类型" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆品牌",
                fieldName: "modelCode",
                span: 6,
                ds: vehicleBrandDs,
                rules: [
                    { required: true, message: "请选择品牌型号" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "驱动形式",
                fieldName: "drivingFormCode",
                span: 6,
                ds: drivingTypeDs,
                rules: [
                    { required: true, message: "请选择驱动形式" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是/否可过户",
                fieldName: "transferOwnershipFlag",
                span: 6,
                ds: BooleanFlagDs,
                rules: [
                    { required: true, message: "请选择是否" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "行驶里程",
                fieldName: "mileage",
                span: 6,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "驱动形式",
                fieldName: "drivingFormCode",
                span: 6,
                ds: drivingTypeDs,
                rules: [
                    { required: true, message: "请选择驱动形式" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "马力",
                fieldName: "horsepowerName",
                span: 6,
                // ds: powerTypeDs,
                rules: [
                    { required: true, message: "请输入马力" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "燃料种类",
                fieldName: "fuelTypeCode",
                span: 6,
                ds: fuelTypeDs,
                rules: [
                    { required: true, message: "请选择燃料种类" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车身颜色",
                fieldName: "colorCode",
                span: 6,
                ds: colorDs,
                rules: [
                    { required: true, message: "请选择车身颜色" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "排放标准",
                fieldName: "emissionStandardCode",
                span: 6,
                ds: emissionDs,
                rules: [
                    { required: true, message: "请选择排放标准" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "变速箱品牌",
                fieldName: "gearboxBrandCode",
                span: 6,
                ds: gearboxTypeDs,
                rules: [
                    { required: true, message: "请选择变速箱品牌" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "排量",
                fieldName: "emission",
                span: 6,
                ds: emissionDs,
                rules: [
                    { required: true, message: "请选择排量" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所在地",
                fieldName: "addressName",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "价格",
                fieldName: "price",
                span: 6
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "发动机品牌",
                fieldName: "engineBrandCode",
                span: 6,
                ds: engineDs,
                rules: [
                    { required: true, message: "请选择发动机品牌" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "功率",
                fieldName: "power",
                span: 6,
                ds: powerTypeDs,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "标签选择",
                fieldName: "labelCode",
                span: 6,
                ds: labelDs,
            }, {
                type: FormItemTypeEnum.Datepicker,
                format: "YYYY-MM-DD",
                fieldLabel: "出厂日期",
                fieldName: "produceTime",
                allowClear: true,
                rules: [
                    { required: true, message: "请选择出厂日期" }
                ],
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                span: 6,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                allowClear: true,
                disable: true,
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "卖家描述",
                fieldName: "desc",
                span: 12,
                labelCol: { span: 4 },
            },
        ]
    };


    const onOK = async () => {
        onCancel()
    }
    const onCancel = () => {
        setLookDrawerVisible(false);
    };
    return (
        <YHModal
            visible={lookDrawerVisible}
            onOk={onOK}
            onCancel={onCancel}
            width={1400}
            bodyHeight={700}
            showFooter={true}
        >
            <YhBox title="基本信息" style={{ marginTop: '15px', padding: "20px" }}>
                <YhForm {...baseFormConfig} >
                    <Col span="6">
                        <Form.Item
                            label="外轮廓尺寸"
                            name="size"
                            labelCol={{ span: 8 }}
                        >
                            <div className="flex">
                                <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </Col>
                </YhForm>
            </YhBox>
            <YhBox title="相关信息上传" style={{ marginTop: '15px', padding: "20px" }}>
                <UploadFiles uploadRef={uploadRef} />
            </YhBox>
        </YHModal>
    )
}