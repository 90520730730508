import { PlusOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import useNavModel from '@src/model/navModel'
import { importPropsConfig } from '@src/utils/importFile'
import { Button, Col, Form, Input, message, Modal, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useEffect, useRef, useState } from 'react'
import { usePVehicleResourceModle } from '../vehicleResourceModel'
import { IUploadRef, UploadFiles } from './UploadFiles'
import moment from 'moment'
import { useDsModel } from '@src/model/dsModel'
import { BooleanFlagDs } from '@src/types/FmsDs'
import { DEFAULT_SUPPLIER, getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData, } from '@src/utils/constants'
import { pVehicleResourceService } from '../VehicleResourceService'
import { globalPrompt } from '@src/components/YhMessage'
import useGlobalModel from '@src/model/globalModel'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum } from '@src/components/YhBox/Types'
import { getBase64 } from '@src/utils/utils'
import { httpRequest } from '@src/utils/HttpRequest'
import { convertRes2Blob } from '@src/utils/exportFile'

export const PurchaseVehicleResourceDetail = () => {
  const uploadRef = useRef<IUploadRef>()
  const {
    formRef,
    baseFormRef,
    selectedRowData,
    editModel,
  } = usePVehicleResourceModle()
  const { replaceNav } = useNavModel()
  const {
    vehicleTypeDs,
    gearboxTypeDs,
    drivingTypeDs,
    fuelTypeDs,
    vehicleBrandDs,
    labelDs,
    engineDs,
    colorDs,
    emissionDs,
    useNatureDs,
  } = useDsModel()
  const { user } = useGlobalModel()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewUrl, setPreviewUrl] = useState('')
  const [reject, setReject] = useState("")
  const [showReject, setShowReject] = useState<boolean>(false)

  const [formCache, setformCache] = useState<any>()


  const formConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    disabled: editModel === 'look',
    initialValues: formCache ? { ...formCache } : editModel === "add" ? {
      createUser: user.userName,
      ownership: "公司",
      createTime: moment().format("YYYY-MM-DD HH:mm:ss")
    } : {
      ...selectedRowData,
      registerTime: selectedRowData?.registerTime ? moment(selectedRowData?.registerTime) : '',
      createTime: moment(selectedRowData?.createTime).format("YYYY-MM-DD HH:mm:ss")
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆牌照",
        fieldName: "numberPlate",
        span: 6,
        rules: [
          { required: true, message: "请输入车辆牌照" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆类型",
        fieldName: "typeCode",
        span: 6,
        ds: vehicleTypeDs,
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "使用性质",
        fieldName: "useNatureCode",
        span: 6,
        ds: useNatureDs
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆品牌",
        fieldName: "modelCode",
        ds: vehicleBrandDs,
        span: 6,
        rules: [
          { required: true, message: "请选择车辆品牌" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆型号",
        fieldName: "vehicleModel",
        span: 6,
        rules: [
          { required: true, message: "请输入车辆型号" }
        ]
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆识别代码",
        fieldName: "vin",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "发动机号码",
        fieldName: "engineCode",
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        fieldLabel: "注册日期",
        fieldName: "registerTime",
        span: 6,
        showTime: false,
        format: "YYYY-MM-DD",
        rules: [
          { required: true, message: "请选择注册日期" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆归属",
        disable: editModel === "add",
        fieldName: "ownership",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "卖家描述",
        fieldName: "desc",
        span: 12,
        labelCol: {
          span: 4
        }
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建人",
        fieldName: "createUser",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        disable: true,
        span: 6,
      }
    ]
  };
  const baseFormConfig: IYhForm = {
    formRef: baseFormRef,
    labelCol: { span: 8 },
    disabled: editModel === 'look',
    initialValues: formCache ? { ...formCache } : editModel === "add" ? {

    } : {
      ...selectedRowData,
      colorCode: selectedRowData?.colorCode ? selectedRowData?.colorCode.split(',') : [],
      produceTime: selectedRowData?.produceTime ? moment(selectedRowData?.produceTime) : '',
      scrapTime: selectedRowData?.scrapTime ? moment(selectedRowData?.scrapTime) : '',
      firstLicenseTime: selectedRowData?.firstLicenseTime ? moment(selectedRowData?.firstLicenseTime) : '',
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "驱动形式",
        fieldName: "drivingFormCode",
        span: 6,
        ds: drivingTypeDs,
        rules: [
          { required: true, message: "请选择驱动形式" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "马力",
        fieldName: "horsepowerName",
        span: 6,
        // ds: powerTypeDs,
        rules: [
          { required: true, message: "请输入马力" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "燃料种类",
        fieldName: "fuelTypeCode",
        span: 6,
        ds: fuelTypeDs,
        rules: [
          { required: true, message: "请选择燃料种类" }
        ],

      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "行驶里程",
        fieldName: "mileage",
        span: 6,
        rules: [
          { required: true, message: "请输入行驶里程" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "排放标准",
        fieldName: "emissionStandardCode",
        span: 6,
        ds: emissionDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "变速箱品牌",
        fieldName: "gearboxBrandCode",
        span: 6,
        ds: gearboxTypeDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "发动机品牌",
        fieldName: "engineBrandCode",
        span: 6,
        ds: engineDs,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "排量",
        fieldName: "emission",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        mode: "multiple",
        fieldLabel: "车身颜色",
        fieldName: "colorCode",
        span: 6,
        ds: colorDs,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "出厂日期",
        fieldName: "produceTime",
        allowClear: true,
        rules: [
          { required: true, message: "请选择出厂日期" }
        ],
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "报废期止",
        fieldName: "scrapTime",
        allowClear: true,
        rules: [
          { required: true, message: "请选择报废日期" }
        ],
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "功率",
        fieldName: "power",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "所在地",
        fieldName: "addressName",
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "初次上牌时间",
        fieldName: "firstLicenseTime",
        allowClear: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "价格",
        fieldName: "price",
        span: 6
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "总质量",
        fieldName: "totalMass",
        span: 6
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "是/否接受议价",
        fieldName: "bargainFlag",
        span: 6,
        ds: BooleanFlagDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "是/否可过户",
        fieldName: "transferOwnershipFlag",
        span: 6,
        ds: BooleanFlagDs,
        rules: [
          { required: true, message: "请选择是否可过户" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "标签选择",
        fieldName: "labelCode",
        span: 6,
        ds: labelDs,
      },
    ]
  };

  useEffect(() => {
    uploadRef.current?.setAllFile({
      appReport: selectedRowData?.ShcFilemgr?.appReport,
      photo: selectedRowData?.ShcFilemgr?.photo?.split(','),
      registerInfo: selectedRowData?.ShcFilemgr?.registerInfo,
      video: selectedRowData?.ShcFilemgr?.video,
    })
    const drivingLicense = selectedRowData?.ShcFilemgr?.drivingLicense
    if (drivingLicense) {
      setFileList([{
        uid: drivingLicense,
        url: getBaseImgLookUrl(drivingLicense),
        response: {
          retData: drivingLicense
        }
      } as UploadFile])
    }
  }, [selectedRowData])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传行驶证</div>
    </div>
  );

  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const handleChange = async (info: UploadChangeParam) => {
    setFileList(info.fileList)
    const formData = await formRef.current?.getFieldsValue()
    const otherData = await baseFormRef.current?.getFieldsValue()
    setformCache({
      ...formData,
      ...otherData,
    })
  };
  const handSubmit = async (type: 'save' | 'publish') => {
    try {
      formRef.current?.validateFields()
      const drivingLicense = fileList[0]
      const filesData = uploadRef.current?.getAllFile()
      const formData = await formRef.current?.validateFields();
      const baseFormData = await baseFormRef.current?.validateFields()
      const params = {
        ...formData,
        ...baseFormData,
        files: undefined,
        userType: "supplier",
        orderNum: selectedRowData?.orderNum,
        colorCode: baseFormData?.colorCode ? baseFormData?.colorCode.join(',') : '',
        registerTime: formData.registerTime ? moment(formData.registerTime).format("YYYY-MM-DD HH:mm:ss") : "",
        produceTime: baseFormData.produceTime ? moment(baseFormData.produceTime).format("YYYY-MM-DD HH:mm:ss") : "",
        scrapTime: baseFormData.scrapTime ? moment(baseFormData.scrapTime).format("YYYY-MM-DD HH:mm:ss") : "",
        firstLicenseTime: baseFormData.firstLicenseTime ? moment(baseFormData.firstLicenseTime).format("YYYY-MM-DD HH:mm:ss") : "",
        createTime: formData.createTime ? moment(formData.createTime).format("YYYY-MM-DD HH:mm:ss") : "",
      }
      if (editModel === 'add') {
        const insertParams = {
          ...params,
          userId: DEFAULT_SUPPLIER,
          drivingLicense: drivingLicense?.response?.retData,
          businessType: "source",
          photo: filesData?.photo.join(","),
          registerInfo: filesData?.registerInfo,
          appReport: filesData?.appReport,
          video: filesData?.video,
          status: type === "save" ? 0 : 1
        }
        const { success } = await pVehicleResourceService.insert(insertParams)
        if (success) {
          globalPrompt('message', { text: '新增成功！', type: 'success' })
          onCancel()
        }
      }
      if (editModel === 'edit') {
        const editParams = {
          ...params,
          id: selectedRowData?.id,
          createUser: undefined,
          createTime: undefined,
          filemgr: {
            drivingLicense: drivingLicense?.response?.retData,
            businessType: "source",
            id: selectedRowData?.ShcFilemgr?.id,
            photo: filesData?.photo.join(","),
            registerInfo: filesData?.registerInfo,
            appReport: filesData?.appReport,
            video: filesData?.video,
          },
          status: type === "publish" ? 1 : selectedRowData?.status
        }
        const { success } = await pVehicleResourceService.update(editParams)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
          onCancel()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onCancel = () => {
    replaceNav("ucms-02-02-01", "ucms-02-02")
    // loadData().then()
  }
  /**
   * 驳回
   */
  const handleReject = async (reason: string) => {
    try {
      const { retData } = await pVehicleResourceService.reject(selectedRowData?.id as string, reason)
      if (retData) {
        globalPrompt('message', { text: '驳回成功', type: 'success' })
        onCancel()
      } else {
        globalPrompt('message', { text: '驳回失败', type: 'error' })
      }
    } catch (error) {

    }
  }
  /**
 * 预览
 * @param type 预览类型
 * @param file 预览文件
 */
  const handlePreview = async (file: UploadFile) => {
    let url = ""
    if (!file.url && !file.preview) {
      url = await getBase64(file.originFileObj) as string;
    } else {
      url = file.url ?? file.preview as string
    }
    setPreviewUrl(url)
  };
  const onDowloadImage = async () => {
    const image = await httpRequest.getFile(previewUrl)
    convertRes2Blob(image, `${selectedRowData?.vehicleCode ?? '车辆信息'} ${moment().format('YYYY-MM-DD HH_mm_ss')}.png`, false)
  }
  const Title = <>
    <Description
      label="车辆编号"
      value={selectedRowData?.vehicleCode as string}
      color={DescriptionColorEnum.green}
      status={true}
    />
    <Description
      label="状态"
      value={
        selectedRowData?.status === 0 ? '待审批'
          : selectedRowData?.status === 1 ? '待售中'
            : selectedRowData?.status === 2 ? '已转接'
              : selectedRowData?.status === 3 ? '已售出'
                : selectedRowData?.status === 9 ? '已驳回'
                  : selectedRowData?.status as number
      }
      color={DescriptionColorEnum.green}
      status={true}
    />
  </>
  const Action = (
    <>
      {editModel === 'look' ? null : <>
        <Button type="link" shape="round" hidden={selectedRowData?.status !== undefined && selectedRowData?.status !== 0} onClick={() => handSubmit("publish")}>发布</Button>
        <Button type="link" shape="round" onClick={() => handSubmit("save")}>保存</Button>
        <Button type="link" shape="round" hidden={editModel !== 'edit' || selectedRowData?.status !== 0} onClick={() => { setShowReject(true) }}>驳回</Button>
      </>}
      <Button type="link" shape="round" onClick={onCancel}>返回</Button>
    </>
  )
  return (
    <div>
      <YhBox title={editModel === "add" ? "车辆详情" : Title} action={Action} />
      <YhBox title="行驶本信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...formConfig} >
          <Col span="6">
            <Form.Item
              label="行驶证"
              name="files"
              labelCol={{ span: 8 }}
              required={true}
            >
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                maxCount={1}
                beforeUpload={(file) => beforeUpload(file)}
                disabled={editModel === 'look'}
                action={getBaseImgUploadUrl()}
                data={getBaseUploadData()}
                onChange={(info) => {
                  setFileList(info.fileList)
                  if (info.file.status === 'done' && Number(info.file.response.retCode) === 0) {
                    message.info({ content: `${info.file.name}导入${info.file.response.retData}`, duration: 8 });
                    handleChange(info)
                  } else if (info.file.status === 'error' && info.file.response && info.file.response.retData) {
                    message.error(`${info.file.response.retData}：${info.file.name}导入异常，请尝试重新导入`);
                  } else if (info.file.status === 'error') {
                    message.error("导入错误")
                  }
                }}
                onPreview={(file) => handlePreview(file)}
                fileList={fileList}
                onRemove={(e) => setFileList([])}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
      <YhBox title="基本信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...baseFormConfig}>
          <Col span="6">
            <Form.Item
              label="外轮廓尺寸"
              name="size"
              labelCol={{ span: 8 }}
            >
              <div className="flex">
                <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
                <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
                <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
      <YhBox title="相关信息上传" style={{ marginTop: '15px', padding: "20px" }}>
        <UploadFiles uploadRef={uploadRef} vehicleCode={selectedRowData?.vehicleCode} disabled={editModel === 'look'} />
      </YhBox>
      <Modal visible={showReject} title="请填写驳回原因" onOk={() => { setShowReject(false); handleReject(reject) }} onCancel={() => setShowReject(false)}>
        <Form.Item label="驳回原因">
          <Input onChange={v => setReject(v.target.value)} />
        </Form.Item>
      </Modal>
      {/* 图片预览 */}
      <Modal visible={!!previewUrl} title={'图片预览'} footer={<Button type="link" shape="round" className="ml-10" onClick={onDowloadImage}>下载图片</Button>} onCancel={() => setPreviewUrl('')} >
        <img alt="example" style={{ width: '100%' }} src={previewUrl} />
      </Modal >
    </div >
  )
}