
export enum MdEnableStatusEnum {
    DISABLE,
    ENABLE
}

export enum DisableFlagEnum {
    normal,
    disabled,
}
// 编辑操作模式
export enum EditModeEnum {
    edit,
    look,
    add
}
export enum MaterialTypeEnum {
    Engine = '发动机',
    Gearbox = '变速箱',
    Axle = '车桥',
    Vehicle = '整车'
}

export enum DetailType {
    "add" = "add",
    "edit" = "edit",
    "look" = "look"
}

export enum titleType {
    add = "新增",
    edit = "编辑",
    look = "查看",
}