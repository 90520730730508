import { Button, Col, Form, message, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { OptModelTypeEnum } from '@components/YHDict/Types'
import { YHDict } from '@components/YHDict'
import { FormItemTypeEnum, IFormItem, IYhFormRef } from '@components/YhFormBox/Types'
import { globalPrompt } from '@components/YhMessage'
import { useDictModel } from './dictModel'
import useNavModel from '@model/navModel'
import { dictService } from './DictService'
import { BranchDs } from '@src/types/FmsDs'
import { importPropsConfig } from '@src/utils/importFile'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { getBaseImgLookUrl } from '@src/utils/constants'
import { PlusOutlined } from '@ant-design/icons'

export const Dict = () => {
  const formRef = useRef<IYhFormRef>()
  const {
    selectedData,
    selectedRowKeys,
    disableData,
    getTreeData,
    dataSource,
    optModelType,
    setSelectedRowKeys,
    setSelectParentData,
    setSelectedData,
    setOptModelType,
    loadData,
    setFormValues,
    formValues,
  } = useDictModel()

  const { closeNav } = useNavModel()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [formCache, setformCache] = useState<any>()

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formValues.pcode === 'brand') {
      setFileList([{
        response: {
          retData: formValues.value
        }
      } as UploadFile])
    }
  }, [formValues])


  const items: IFormItem[] = [
    {
      type: FormItemTypeEnum.Select,
      fieldLabel: "分支编码",
      fieldName: "branch",
      span: 13,
      ds: BranchDs,
    }, {
      //   type: FormItemTypeEnum.Input,
      //   fieldLabel: "数据版本号",
      //   fieldName: "ver",
      //   disable: optModelType === OptModelTypeEnum.DETAIL,
      //   hidden: optModelType !== OptModelTypeEnum.DETAIL,
      //   span: 13,
      // }, {
      type: FormItemTypeEnum.Input,
      fieldLabel: "父级编码",
      fieldName: "pcode",
      disable: true,
      hidden: optModelType === OptModelTypeEnum.ADD_ROOT,
      span: 13,
    }, {
      type: FormItemTypeEnum.Input,
      fieldLabel: "字典编码",
      disable: optModelType === OptModelTypeEnum.DETAIL,
      fieldName: "code",
      rules: [{ required: true, message: '该项为必填项' }],
      span: 13,
    }, {
      type: FormItemTypeEnum.Input,
      fieldLabel: "字典名称-中文",
      fieldName: "name",
      span: 13,
      rules: [{ required: true, message: '该项为必填项' }]
    }, {
      type: FormItemTypeEnum.Input,
      fieldLabel: "字典名称-英文",
      fieldName: "nameEn",
      span: 13,
      rules: [{ required: true, message: '该项为必填项' }]
    }, {
      //   type: FormItemTypeEnum.Input,
      //   fieldLabel: "value",
      //   fieldName: "value",
      //   span: 13,
      //   // rules: [{ required: true, message: '该项为必填项' }]
      // }, {
      type: FormItemTypeEnum.Input,
      fieldLabel: "备注",
      fieldName: "note",
      span: 13,
    }, {
      type: FormItemTypeEnum.Switch,
      fieldLabel: "状态",
      fieldName: "status",
      hidden: optModelType !== OptModelTypeEnum.DETAIL,
      valuePropName: "checked",
      span: 13,
    }
  ]

  const action = <>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      onClick={() => onAddPeer()}
    >增加同级</Button>
    <Button
      className="mr-5"
      type="link"
      shape="round"
      disabled={selectedRowKeys.length === 0}
      onClick={() => onAddSon()}
    >增加下级</Button>
    {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            disabled={selectDictRowKey.length === 0}
            onClick={() => remove()}
        >删除</Button> */}
    {/* <Button
      type="link"
      shape="round"
      onClick={() => disableData(true)}
      hidden={!(selectedRowKeys.length === 1 && selectedData?.status === 0)}
    >禁用</Button>
    <Button
      type="link"
      shape="round"
      hidden={!(selectedRowKeys.length === 1 && selectedData?.status === 1)}
      onClick={() => disableData(false)}
    >启用</Button> */}
    {/* <Button
      type="link"
      shape="round"
      onClick={syncData}
    >同步</Button> */}
  </>

  const onAddPeer = () => {
    if (selectedData) {
      setFormValues({
        pcode: selectedData.pcode,
        branch: selectedData.branch,
        code: '',
        name: '',
        nameEn: '',
        note: '',
        value: '',
        status: 0,
      })
      setOptModelType(OptModelTypeEnum.ADD_SAME_LEVEL);
    } else {//当前选中为根级元素的时候，他的字典编码是可以自定义编辑的
      setSelectedData(undefined)
      setOptModelType(OptModelTypeEnum.ADD_ROOT)
    }
  }

  const onAddSon = () => {
    if (selectedData) {
      const { children, code } = selectedData
      let dictRowLen = children?.length as number
      setFormValues({
        pcode: code,
        code: `${code}-0${dictRowLen >= 10 ? dictRowLen + 1 : '0' + (dictRowLen + 1)}`,
        branch: selectedData.branch,
        name: '',
        nameEn: '',
        note: '',
        status: 0,
      })
      setOptModelType(OptModelTypeEnum.ADD_CHILD_LEVEL);
    } else {
      globalPrompt('message', { type: 'warning', text: '请选择父级字典项' })
    }
  }


  const onSubmit = async () => {
    try {
      const formData = await formRef.current?.validateFields();
      if (formData) {
        const params = {
          ...formData,
          status: formData.status ? 0 : 1,
          value: formValues.pcode === 'brand' ? fileList[0].response.retData : undefined
        }
        if (optModelType !== OptModelTypeEnum.DETAIL) {
          const insertRet = await dictService.insert(params);
          if (insertRet.success) {
            await globalPrompt("message", { type: "success", text: '新增成功!' });
            await loadData();
            onCancel()
          } else {
            await globalPrompt("message", { type: "error", text: "新增失败!" + insertRet.retMsg });
          }
        } else {
          const updateRet = await dictService.update(params);
          if (updateRet.success) {
            await globalPrompt("message", { type: "success", text: '修改成功!' });
            await loadData();
            onCancel()
          } else {
            await globalPrompt("message", { type: "error", text: "修改失败!" + updateRet.retMsg });
          }
        }
      }
    } catch (e) {
      console.log("失败! ", e);
    }
  }

  const onCancel = () => {
    setFormValues({
      pcode: undefined,
      code: undefined,
      branch: undefined,
      name: undefined,
      nameEn: undefined,
      note: undefined,
      status: undefined,
      value: undefined,
    })
  }

  const onReset = () => {
    formRef.current?.resetFields()
  }

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    // console.log("selecet: ", selectedKeys, info)
    const selectedNode = info.selectedNodes[0];
    const pNode = dataSource.find(item => item.code === selectedNode?.pcode)
    setSelectParentData(pNode)
    setSelectedData(selectedNode.el)
    setFormValues({ ...selectedNode.el })
    setSelectedRowKeys(selectedKeys)
    setOptModelType(OptModelTypeEnum.DETAIL)
  }
  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const handleChange = async (info: UploadChangeParam) => {
    console.log([info.file])
    setFileList([info.file])
    const formData = await formRef.current?.getFieldsValue()
    setformCache({
      ...formData,
    })
  };
  return (
    <YHDict
      // form
      items={items}
      optModelType={optModelType}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onReset={onReset}
      initialValues={formCache ? { ...formCache } : {
        ...formValues,
        status: formValues?.status === 0
      }}
      formRef={formRef}
      // disabled={false}

      // tree
      action={action}
      treeData={getTreeData()}
      onSelect={onSelect}
    >
      <Col span={13} hidden={formValues.pcode !== 'brand'}>
        <Form.Item
          label="Logo"
          name="files"
          labelCol={{ span: 6 }}
          required={true}
        >
          <Upload
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // disabled={editModel === 'look'}
            beforeUpload={beforeUpload}
            {...importPropsConfig('/uploadAttach', handleChange)}
            action="https://file-server.laoniuche.cn/file/uploadFile"
          >
            <div className="flex-column items-center justify-center" style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
              {fileList.length > 0 ?
                <img src={getBaseImgLookUrl(fileList[0].response.retData as string)} alt="logo" style={{ width: '100%', }} />
                : <>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传Logo</div>
                </>
              }
            </div>
          </Upload>
        </Form.Item>
      </Col>
    </YHDict >
  )
}