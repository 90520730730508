import { IGMsgFields, IGMsgSearch, IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { IPVehicleResourceDetail } from "@src/views/purchase/vehicleResource/VehicleResourceTypes";
import { ISaleDemandDetail } from "@src/views/sale/demand/DemandTypes";

class GMsgdService {
  /**
   * 更新需求
   * @param documentsId
   * @returns 
   */
  async findOne(documentsId: string) {
    const url = `shc2/message/search_one/${documentsId}`;
    return await httpRequest.post<ISaleDemandDetail | IPVehicleResourceDetail>(url);
  }

  /**
   * 分页查询需求
   * @param params 
   * @returns 
   */
  async page(params: IGMsgSearch) {
    const url = "/shc2/message/search_page";
    return await httpRequest.post<IPage<IGMsgFields>>(url, params);
  }

}
export const gMsgService = new GMsgdService()