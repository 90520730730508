import React, { useState } from 'react';
import { createModel } from 'hox';
import { OptionDsType } from '@src/types/FmsTypes';
import { dictService } from '@src/views/base/dict/DictService';
import { trasDsToTreeData } from '@src/views/base/dict/dictModel';
import { findDsFromTree } from '@src/utils/utils';

function useModel() {
	/** 车辆类型 */
	const [vehicleTypeDs, setVehicleTypeDs] = useState<OptionDsType[]>();
	/** 变速箱 */
	const [gearboxTypeDs, setGearboxTypeDs] = useState<OptionDsType[]>();
	/** 驱动类型 */
	const [drivingTypeDs, setDrivingTypeDs] = useState<OptionDsType[]>();
	/** 燃油类型 */
	const [fuelTypeDs, setFuelTypeDs] = useState<OptionDsType[]>();
	/** 马力类型 */
	const [powerTypeDs, setPowerTypeDs] = useState<OptionDsType[]>();
	/** 标签 */
	const [labelDs, setLabelDs] = useState<OptionDsType[]>();
	/** 车辆品牌 */
	const [vehicleBrandDs, setVehicleBrandDs] = useState<OptionDsType[]>();
	/** 排放标准 */
	const [emissionDs, setEmissionDs] = useState<OptionDsType[]>();
	/** 车辆颜色 */
	const [colorDs, setColorDs] = useState<OptionDsType[]>();
	/** 发动机品牌 */
	const [engineDs, setEngineDs] = useState<OptionDsType[]>();
	/** 所在地 */
	const [addressDs, setAddressDs] = useState<OptionDsType[]>();
	/** 使用性质 */
	const [useNatureDs, setUseNatureDs] = useState<OptionDsType[]>();
	/** 贸易国别 */
	const [tradeCountryDs, setTradeCountryDs] = useState<OptionDsType[]>();
	/** 客户供应商认证类型 */
	const [authTypeDs, setAuthTypeDs] = useState<OptionDsType[]>();
	/** 交易币种 */
	const [currencyDs, setCurrencyDs] = useState<OptionDsType[]>();
	/** 销售订单进度负责人 */
	const [directorDs, setDirector] = useState<OptionDsType[]>();
	/** 浏览类型 */
	const [borwsTypeDs, setBorwsTypeDs] = useState<OptionDsType[]>();
	/** 收藏类型 */
	const [collectTypeDs, setCollectTypeCodeDs] = useState<OptionDsType[]>();

	const initDs = async () => {
		try {
			let { retData } = await dictService.allTree({});
			let treeData = trasDsToTreeData(retData, 'code', 'name');
			let vehicleDs = findDsFromTree(treeData, 'vehicle-type');
			let gearboxDs = findDsFromTree(treeData, 'gearbox-brand');
			let fuelDs = findDsFromTree(treeData, 'fuel-type');
			let drivingDs = findDsFromTree(treeData, 'driving-form');
			let powerDs = findDsFromTree(treeData, 'horsepower');
			let labelDs = findDsFromTree(treeData, 'label');
			let vehicleBrandDs = findDsFromTree(treeData, 'brand');
			let emissionDs = findDsFromTree(treeData, 'emission-standard');
			let colorDs = findDsFromTree(treeData, 'vehicle-color');
			let engineDs = findDsFromTree(treeData, 'engine-brand');
			let addressDs = findDsFromTree(treeData, 'address');
			let useNatureDs = findDsFromTree(treeData, 'use-nature');
			let tradeCountryDs = findDsFromTree(treeData, 'trade-country');
			let authTypeDs = findDsFromTree(treeData, 'customer-type');
			let currencyDs = findDsFromTree(treeData, 'currency');
			let directorDs = findDsFromTree(treeData, 'director');
			let browseTypeDs = findDsFromTree(treeData, 'browse-type');
			let collectTypeDs = findDsFromTree(treeData, 'collect-type');
			setVehicleTypeDs(vehicleDs);
			setGearboxTypeDs(gearboxDs);
			setDrivingTypeDs(drivingDs);
			setFuelTypeDs(fuelDs);
			setPowerTypeDs(powerDs);
			setLabelDs(labelDs);
			setVehicleBrandDs(vehicleBrandDs);
			setEmissionDs(emissionDs);
			setColorDs(colorDs);
			setEngineDs(engineDs);
			setAddressDs(addressDs);
			setUseNatureDs(useNatureDs);
			setTradeCountryDs(tradeCountryDs);
			setAuthTypeDs(authTypeDs);
			setCurrencyDs(currencyDs);
			setDirector(directorDs);
			setBorwsTypeDs(browseTypeDs);
			setCollectTypeCodeDs(collectTypeDs);
		} catch (error) {}
	};

	return {
		vehicleTypeDs,
		gearboxTypeDs,
		drivingTypeDs,
		fuelTypeDs,
		powerTypeDs,
		labelDs,
		vehicleBrandDs,
		emissionDs,
		initDs,
		colorDs,
		engineDs,
		addressDs,
		useNatureDs,
		tradeCountryDs,
		authTypeDs,
		currencyDs,
		directorDs,
		borwsTypeDs,
		collectTypeDs,
	};
}

export const useDsModel = createModel(useModel);
