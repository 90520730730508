import React, { FC, useEffect, useRef, useState } from 'react'
import { YhBox } from '@src/components/YhBox'
import { YhForm, YhFormBox, YhFormItemsBox } from '@src/components/YhFormBox'
import { RouterParamType } from "@src/model/navModel";
import { FormItemTypeEnum, IYhForm, IYhFormBox, IYhFormItemsBox, IYhFormRef } from '@src/components/YhFormBox/Types'
import useNavModel from '@src/model/navModel'
import { globalPrompt } from "@src/components/YhMessage";
import { Button, Table, Steps, Form, Input, Space, Col, message } from 'antd'
import { IDetailOne, IPurchaseOrderDetail, ShcProcess, ShcContract, ShcVehicle } from '../orderTypes'
import { usePurchaseOrderModel } from '../orderModel'
import { ColumnsType } from "antd/es/table";
import { AddVehicleResourceModal } from "../drawer/AddVehicleResourceModal"
import { LookVehicleResourceModel } from '../drawer/LookVehicleResourceModel'
import moment from "moment"
import { DetailType } from '@src/types/FmsEnums';
import { purchaseOrderService } from '../orderService';
import { DescriptionColorEnum, IDescription } from '@src/components/YhBox/Types';
import { Description } from '@src/components/YhBox/Description';
import { useDsModel } from '@src/model/dsModel';
import useGlobalModel from '@src/model/globalModel';
const { Step } = Steps

export interface priceNum {
    totalPrice?: number,
    price?: number,
    contractPrice?: string
}

export const PurchaseOrderDetail = () => {
    const [form] = Form.useForm();
    const formRef = useRef<IYhFormRef>();
    const {
        setDrawerVisible,
        detailDetailList,
        setLookDrawerVisible,
        detailDataSource,
        detailLookList,
        setDetailDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        detailLookForm,
        getDetailEditOne,
        contract,
        editDetail,
        resetEditCatch,
        getVehicleDetailOne,
        updateEditFormVal,
        status,
        setStatus,
        process,
        setStep,
        step,
        concelOrder,
        setProcess,
        setContract
    } = usePurchaseOrderModel()
    const { getSelectedNavState, selectedNavId, replaceNav, findNav } = useNavModel()
    const { user } = useGlobalModel()
    const { vehicleTypeDs, fuelTypeDs } = useDsModel()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && detailsInfo.mode === DetailType.edit) {
            getDetailEditOne(detailsInfo.id, detailsInfo.mode)
        }
        detailsInfo.mode === DetailType.look && getDetailEditOne(detailsInfo.id, detailsInfo.mode)
    }, [detailsInfo.id, detailsInfo.mode])

    useEffect(() => {
        return () => {
            !findNav("ucms-02-03-01") && resetEditCatch();
        }
    }, [selectedNavId])

    useEffect(() => {
        if (contract.length > 0) {
            form.setFieldsValue({ ShcContract: contract })
        }
    }, [contract])

    useEffect(() => {
        console.log(step, process);
    }, [])

    const onChange = (changedValues: { [key: string]: any }, values?: any) => {
        updateEditFormVal(changedValues)
    }
    const baseFormConfig: IYhForm = {
        formRef: formRef,
        disabled: detailsInfo.mode === 'look' || status === 9,
        justify: 'start',
        labelCol: { span: 8 },
        initialValues: detailsInfo.mode === "edit" ? {
            ...detailEditForm,
            createTime: detailEditForm.createTime && moment(detailEditForm.createTime).format("YYYY-MM-DD HH:mm:ss"),
            bargainTime: detailEditForm.bargainTime && moment(detailEditForm.bargainTime),
            // createUser:user.userName,
            onChange
        } : {
            ...detailLookForm,
            createTime: detailLookForm.createTime && moment(detailLookForm.createTime).format("YYYY-MM-DD HH:mm:ss"),
            bargainTime: detailLookForm.bargainTime && moment(detailLookForm.bargainTime),
            // createUser:user.userName
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "userName",
                disable: true,
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "合同编号",
                fieldName: "contractCode",
                span: 6,
                // rules: [
                //     { required: true, message: "请填写合同编号" }
                // ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "合同成交价",
                fieldName: "contractPrice",
                disable: true,
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                span: 6,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "tel",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售数量",
                fieldName: "purorderNumber",
                disable: true,
                span: 6,
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "成交时间",
                fieldName: "bargainTime",
                format: "YYYY/MM/DD",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
                span: 6,
            }
        ]
    };

    const goLook = (id: string) => {
        setLookDrawerVisible(true)
        getVehicleDetailOne(id)
    }


    const columns = [
        {
            title: '序号',
            align: 'center',
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: "分订单号",
            align: 'center',
            dataIndex: 'divideOrder',
            fixed: "left",
        }, {
            title: "车辆编号",
            align: 'center',
            dataIndex: 'vehicleCode',
            fixed: "left",
            render: (text: any, record: ShcVehicle) => <span onClick={() => goLook(record.id as string)}>{text}</span>
        }, {
            title: '车辆类型',
            align: 'center',
            dataIndex: 'typeName',
        }, {
            title: '车辆品牌',
            align: 'center',
            dataIndex: 'modelName',
        }, {
            title: '车辆型号',
            align: 'center',
            dataIndex: 'vehicleModel',
        }, {
            title: '出厂日期',
            align: 'center',
            dataIndex: 'produceTime',
            render: (v: any) => moment(v).format('YYYY-MM-DD')
        }, {
            title: '驱动形式',
            align: 'center',
            dataIndex: 'drivingFormName',
        }, {
            title: '马力',
            align: 'center',
            dataIndex: 'horsepowerName',
        }, {
            title: '燃料种类',
            align: 'center',
            dataIndex: 'fuelTypeName',
        }, {
            title: '行驶里程',
            align: 'center',
            dataIndex: 'mileage',
        }, {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            render: (v: any, r: any, i: any) => v === 0 ? '待审批'
                : v === 1 ? '待售中'
                    : v === 2 ? '已转接'
                        : v === 3 ? '已售出'
                            : '已驳回'
        }, {
            title: '创建人',
            align: 'center',
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            render: (v: any) => moment(v).format("YYYY-MM-DD HH:mm:ss")
        }
    ];


    const orderStatus: IDescription[] = [
        {
            label: "订单编号",
            value: detailsInfo.mode === "edit" ? detailEditForm.code as string : detailLookForm.code as string,
            color: DescriptionColorEnum.green,
            status: true
        },
        {
            label: "状态",
            value: status === 0 ? "已开单" : status === 1 ? "已报价" : status === 2 ? "签合同" : status === 3 ? "已付款" : status === 4 ? "已过户" : status === 5 ? "已售出" : "已取消",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]


    // 取消订单
    const onConcelOrder = (id: string, mode: string) => {
        concelOrder(id, mode).then(() => {
            getDetailEditOne(detailsInfo.id, detailsInfo.mode)
        })
    }
    const onCancel = () => {
        globalPrompt("modal", {
            width: 380,
            text: `采购订单${detailEditForm.code} ，是否确定取消？`,
            okText: "确定",
            cancelText: "取消",
            onCancel: () => {
            },
            onOk: () => { onConcelOrder(detailEditForm.id as string, detailsInfo.mode as string) }
        })
    }



    // 提交编辑订单
    const onSave = () => {
        formRef.current?.validateFields().then(async res => {
            // debugger
            if (detailsInfo.mode === "edit") {
                let { ShcContract, ShcProcess, ShcVehicle, ...form } = detailEditForm
                let stepArr = step.filter(item => !item.id && item.updateUser)
                let stepArr1: ShcProcess[] = step.filter(item => item.id)
                let stepArr2: ShcProcess[] = []
                stepArr2.push(stepArr1[stepArr1.length - 1])
                if (stepArr.length === 0 && stepArr1.length === 0) {
                    stepArr2 = [{
                        createTime: detailEditForm.createTime,
                        createUser: detailEditForm.userName,
                        deleteFlag: false,
                        id: process[0].id,
                        orderId: detailsInfo.id,
                        orderNum: 0,
                        status: 0,
                        updateTime: detailEditForm.createTime,
                        updateUser: user.userName
                    }]
                }
                const resData = await editDetail({
                    ...res,
                    ShcContract: contract,
                    ShcVehicle: detailDataSource.map(item => ({ ...item, id: item.id ? item.id : item.key })),
                    ShcProcess: stepArr.length <= 0 ? stepArr2 : stepArr,
                    updateUser: user.userName,
                    orderNum: 0,
                    id: detailEditForm.id,
                    code: detailEditForm.code,
                    userType: "supplier",
                    // status: status
                })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    resetEditCatch()
                    goBack()
                } else {
                    globalPrompt("message", { text: "编辑失败", type: "error" })
                }

            }
        })
    }

    const goBack = () => {
        replaceNav(selectedNavId, "ucms-02-03")
    }
    const Action = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode === 'look' || status === 9 ? true : false}
                onClick={onSave}
            >保存</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode === 'look' || status === 9 || status > 2 ? true : false}
                onClick={onCancel}
            >取消订单</Button>
            <Button type="link" className="mr-5" shape="round" onClick={goBack}>返回</Button>
        </>
    )

    const add = () => {
        setDrawerVisible(true)
    }
    const rightAction = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode === 'look' || status === 9 || status === 2 ? true : false}
                onClick={add}
            >新增</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode === 'look' || status === 9 || status === 5 ? true : false}
                onClick={() => { detailDetailList(detailsInfo.mode) }}
            >删除</Button>
        </>
    )
    const onContract = (item: any, index: number) => {
        globalPrompt("modal", {
            width: 400,
            text: <>
                <div style={{ marginBottom: '15px' }} >订单编号{detailEditForm.code},  是否完成{item.code ?? ""}？</div>
            </>,
            okText: "确定",
            cancelText: "返回",
            onCancel: () => {
            },
            onOk: () => {
                setStatus(status + 1)
                let currentProcess = [...step]
                let value = currentProcess[status]
                currentProcess[status] = {
                    ...value,
                    principal: "",
                    dateTime: "",
                    orderId: detailsInfo.id as string,
                    orderNum: 0,
                    updateUser: user.userName
                }
                setStep(currentProcess)
                const updateProcess: ShcProcess[] = []
                updateProcess.push(currentProcess[status])
                console.log(updateProcess);
                setProcess(updateProcess)
            }
        })
    }
    /**
     * 修改订单总价
     * @param index 索引
     * @param value 单价
     */
    const onChangePrice = (index: number, value: number) => {
        const newArr = contract.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    price: value,
                    totalPrice: Number(item.number) * value,
                }
            }
            return item
        })
        setContract(newArr)
    }

    const onContractChange = (value: { ShcContract: ShcContract[] }, val: { ShcContract: ShcContract[] }) => {
        console.log(value, val)
        let saleNum = 0;  // 销售数量
        let amountTotal = 0  // 合同成交价
        val.ShcContract.forEach(item => {
            saleNum += +(item.number ?? 0);
            amountTotal += +((item.price ?? 0) * (item.number ?? 0));
        })
        updateEditFormVal({ contractPrice: `${amountTotal}`, purorderNumber: `${saleNum}` })
    }

    useEffect(() => {
        console.log(contract)
        form.setFieldsValue({
            ShcContract: contract
        })
    }, [contract])

    const statusLen = process.length
    return (
        <div>
            <YhBox title={<>
                {orderStatus.map((item, index) => <Description key={index} {...item} />)}
            </>} action={Action} />

            <YhBox title="基础信息" style={{ marginTop: '15px', padding: "20px" }}>
                <YhForm {...baseFormConfig} >
                </YhForm>
            </YhBox>
            <YhBox title="采购进度条" style={{ marginTop: '15px', padding: "20px" }}>
                <Steps size="small" current={status < 8 ? status - 1 : statusLen > 1 ? statusLen - 2 : statusLen - 3} style={{ width: '850px' }}>{
                    step.map((item, index) => {
                        return <Step title={<span onClick={status === index && detailsInfo.mode === "edit" ? () => onContract(item, index) : undefined}>{item.name}</span>} />
                    })
                }
                </Steps>
            </YhBox>
            <YhBox title="车辆信息" rightChildren={rightAction} style={{ marginTop: '15px', padding: "20px" }}>
                <Table
                    columns={columns as ColumnsType<ShcVehicle>}
                    rowSelection={{
                        selectedRowKeys: detailDataRowKeys.map(item => item.key ?? ""), //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IPurchaseOrderDetail[]) => {
                            setDetailDataRowKeys(selectedRows);
                        }
                    }}
                    dataSource={detailsInfo.mode === "edit" ? detailDataSource : detailLookList}
                    pagination={false}
                    onRow={record => ({
                        onClick: event => {
                            if (detailDataRowKeys.length > 0 && detailDataRowKeys[0] === record.id) {
                                setDetailDataRowKeys([]);
                            } else {
                                setDetailDataRowKeys([record]);
                            }
                        },
                    })}
                />
            </YhBox>
            <YhBox title="合同信息" style={{ marginTop: '15px', padding: "20px" }}>
                <Form form={form}
                    onValuesChange={(changedValues: any, values: any) => {
                        onContractChange(changedValues, values)
                    }}
                    initialValues={[...contract]}
                >
                    <Form.List name="ShcContract" >
                        {(files) => {
                            return (
                                <>
                                    {files.map((item, index) => (
                                        <Space key={item.key} style={{ display: 'flex', justifyContent: 'space-around' }} align="baseline">
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'modelName']}
                                                fieldKey={[item.fieldKey, 'modelName']}
                                                label="车辆品牌"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'vehicleModel']}
                                                fieldKey={[item.fieldKey, 'vehicleModel']}
                                                label="车辆型号"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'number']}
                                                fieldKey={[item.fieldKey, 'number']}
                                                label="数量"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'price']}
                                                fieldKey={[item.fieldKey, 'price']}
                                                label="单价"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    onChange={e => onChangePrice(index, +e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'totalPrice']}
                                                fieldKey={[item.fieldKey, 'totalPrice']}
                                                label="总价"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'id']}
                                                fieldKey={[item.fieldKey, 'id']}
                                                label="id"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'updateUser']}
                                                fieldKey={[item.fieldKey, 'updateUser']}
                                                label="更新者"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'orderNum']}
                                                fieldKey={[item.fieldKey, 'orderNum']}
                                                label="orderNum"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )
                        }}

                    </Form.List>
                </Form>
            </YhBox>
            <AddVehicleResourceModal />
            <LookVehicleResourceModel />
        </div>
    )
}