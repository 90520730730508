import React, { memo } from 'react'
import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { usePurchaseDemandModle } from '../demandModel'
import { IPurchaseDemandDetail } from '../DemandTypes'
import moment from 'moment'

export function DataTable() {
  const {
    pgCfg,
    selectedRowKeys,
    dataSource,
    setSelectedRowKeys,
    loadData,
    setSelectedRowData,
    handleChangeStatus,
  } = usePurchaseDemandModle()
  const columns: ColumnsType<IPurchaseDemandDetail> = [
    {
      align: 'center',
      title: '序号',
      render: (_v, _r, i) => i + 1
    },
    {
      align: 'center',
      title: "求购编号",
      dataIndex: 'demandCode'
    },
    {
      align: 'center',
      title: "车辆品牌",
      dataIndex: 'modelName'
    },
    {
      align: 'center',
      title: "车辆型号",
      dataIndex: 'vehicleModel'
    },
    {
      align: 'center',
      title: "求购数量",
      dataIndex: 'number'
    },
    {
      align: 'center',
      title: "车辆类型",
      dataIndex: 'typeName'
    },
    {
      align: 'center',
      title: "驱动形式",
      dataIndex: 'drivingFormName'
    },
    {
      align: 'center',
      title: "马力",
      dataIndex: 'horsepowerName'
    },
    {
      align: 'center',
      title: "燃料种类",
      dataIndex: 'fuelTypeName'
    },
    {
      align: 'center',
      title: "行驶里程",
      dataIndex: 'mileage'
    },
    {
      align: 'center',
      title: "出厂日期",
      dataIndex: 'produceTime',
      render: (v) => v ? moment(v).format('YYYY-MM-DD') : ""
    },
    {
      align: 'center',
      title: "创建人",
      dataIndex: 'createUser'
    },
    {
      align: 'center',
      title: "创建时间",
      dataIndex: 'createTime',
      render: (v) => v ? moment(v).format('YYYY-MM-DD HH:mm:ss') : ""
    },
    {
      align: 'center',
      title: "状态",
      dataIndex: 'status',
      render: (v, r, i) =>
        <Switch checked={v === 1} checkedChildren="启用" unCheckedChildren="禁用" onChange={(value) => handleChangeStatus(r, value)} />
      // v === 0 ? '禁用' : '启用'
    },
  ]

  return (
    <Table
      style={{ cursor: "pointer" }}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IPurchaseDemandDetail[]) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRowData(selectedRows[0])
        },
        selectedRowKeys
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...pgCfg,
        showTotal: () => `共${pgCfg.total}条`,
        position: ["bottomCenter"],
        onChange: (pageNum, pageSize) => {
          loadData({ pageNum, pageSize }).then();
        }
      }}
      onRow={record => {
        return {
          onClick: event => {
            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
              setSelectedRowKeys([]);
              setSelectedRowData({} as IPurchaseDemandDetail)
            } else {
              setSelectedRowKeys([record.key]);
              setSelectedRowData(record as IPurchaseDemandDetail)
            }
          },
        };
      }}
    />
  )
}