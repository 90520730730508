import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { DatePicker } from "antd";
import moment from "moment";

type PropType = ISearchProp & ISearchField;
const { RangePicker } = DatePicker;
export const SearchRangePicker = (props: PropType) => {
    const value = props.searchValues?.[props.fieldName] as [string, string];
    return <RangePicker
        placeholder={props.placeholder as [string, string]}
        className="ant-picker-round"
        format={props.format || "YYYY-MM-DD"}
        value={
            (
                value
                && value.length === 2
                && value[0] !== ''
                && value[1] !== ''
            )
                ? [moment(value[0]), moment(value[1])]
                : undefined
        }
        onChange={(value, timeStr) => {
            console.log(value, timeStr)
            props.onChange?.({ [props.fieldName]: timeStr });
            if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled} />;
}
