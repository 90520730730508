import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import useNavModel from '@src/model/navModel';
import { Button } from 'antd';
import React from 'react'
import { usePurchaseSupplierModle } from '../supplierModel';
import { DataTable } from './DataTable'

export function DataBox() {
  const { addNav } = useNavModel()
  const {
    selectedRowKeys,
    exportSupplier,
    setEditModel,
  } = usePurchaseSupplierModle()
  const action = (
    <>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length === 0}
        onClick={() => {
          setEditModel("edit")
          addNav('ucms-02-06-01')
        }}
      >编辑</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={() => { exportSupplier() }}
      >导出</Button>
    </>
  );
  return (
    <YhBox title={<OrderedListOutlined />} action={action}>
      <DataTable />
    </YhBox>
  )
}