import React from 'react'
import { usePurchaseHistoryModel } from '../historyModel'
import { ISearchValue, SearchTypeEnum } from "@src/components/YhPageHeader/Types"
import { YhPageHeader } from '@src/components/YhPageHeader'
import { PCTypeDs } from '@src/types/FmsDs'
import { useDsModel } from '@src/model/dsModel'

export function PageHeader () {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchaseHistoryModel()
    const { borwsTypeDs } = useDsModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '车辆编号',
            fieldName: 'vehicleCode',
            xxl: 4,
            span: 4
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '供应商名称',
            fieldName: 'userName',
            xxl: 4,
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '浏览类型',
            fieldName: 'browseTypeCode',
            xxl: 4,
            span: 4,
            options: borwsTypeDs
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: '浏览时间',
            fieldName: 'createTime',
            xxl: 4,
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '浏览时长',
            fieldName: 'browseDuration',
            xxl: 4,
            span: 4,
        }
    ]

    return (
        <YhPageHeader
            title="浏览痕迹列表"
            triggerSearch={false}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    )
}