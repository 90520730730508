import {httpRequest} from '@src/utils/HttpRequest'
import { IPage } from "@src/types/FmsTypes";
import {ISaleHistoryFields,ISaleHistoryDetail,ISaleHistorySearch,ISaleHistoryInsert} from './historyTypes'
class SaleHistoryService{
    async page(params:ISaleHistorySearch) {
        const apiUrl = '/shc2/trace/search_page'
        return await httpRequest.post<IPage<ISaleHistoryFields>>(apiUrl,params)
    }

    // 新增
    async insert(params:ISaleHistoryInsert){
        const apiUrl = '/shc2/salesRelease/purBrowseTrace/insert'
        return await httpRequest.post<boolean>(apiUrl,params)
    }
}

export const saleHistoryService =new SaleHistoryService()