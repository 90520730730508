import React, { useEffect } from 'react'
import { Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { usePolicyModel } from "../policyModel"
import { IPolicyDetail } from "../policyTypes"
import { useDictModel } from "../../../base/dict/dictModel"
import moment from "moment"
import { getBaseImgLookUrl } from '@src/utils/constants'
export function DataTable () {
	const {
		dataSource,
		selectedRowKeys,
		pgCfg,
		loadData,
		setSelectedRowKeys,
		loading,
	} = usePolicyModel()

	const { getTreeData } = useDictModel()

	const columns: ColumnsType<IPolicyDetail> = [
		{
			title: '序号',
			align: 'center',
			fixed: 'left',
			render: (t, r, i) => i + 1
		},
		{
			title: "缩略图",
			align: "center",
			dataIndex: "photo",
			render: (v: string) => v ? <img width="50px" height="40px" src={getBaseImgLookUrl(v)} /> : ""
		},
		{
			title: "标题",
			align: 'center',
			dataIndex: 'title'
		},
		{
			title: '归属栏目',
			align: 'center',
			dataIndex: 'column',
			render: (v) => v === 0 ? '收车版小程序' : '二手车小程序'
		},
		{
			title: '创建人',
			align: 'center',
			dataIndex: 'createUser'
		},
		{
			title: '创建时间',
			align: 'center',
			dataIndex: 'createTime',
			render: (v) => moment(v).format("YYYY-MM-DD HH:mm:ss")
		},
		{
			title: '是否置顶',
			align: 'center',
			dataIndex: 'status',
			render: (v) => v === 0 ? "否" : "是"
		}
	]

	useEffect(() => {
		loadData()
	}, [])


	return (
		<Table
			style={{ cursor: "pointer" }}
			rowKey={record => record.id as string}
			loading={loading}
			rowSelection={{
				type: "radio",
				onChange: (selectedRowKeys: React.Key[], selectedRows: IPolicyDetail[]) => {
					setSelectedRowKeys(selectedRowKeys as string[]);
				},
				selectedRowKeys
			}}
			columns={columns}
			dataSource={dataSource}
			pagination={{
				...pgCfg,
				position: ["bottomCenter"],
				showTotal: () => `共${pgCfg.total}条`,
				onChange: (pageNum, pageSize) => {
					loadData({ pageNum, pageSize }).then();
				}
			}}
			onRow={record => ({
				onClick: event => {
					if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
						setSelectedRowKeys([]);
					} else {
						setSelectedRowKeys([record?.id ?? ""]);
					}
				},
			})}
			scroll={{ x: 'max-content' }}
		/>
	)
}
