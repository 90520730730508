import { IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { importPropsConfig } from "@src/utils/importFile";
import { IPVehicleResourceDetail, IPVehicleResourceFields, IPVehicleResourceSearch, ITransOrder } from "./VehicleResourceTypes";

class PVehicleResourceService {
  /**
   * 新增
   * @param params 
   * @returns 
   */
  async insert(params: IPVehicleResourceFields) {
    const url = "/shc2/source/insert";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 修改
   * @param params 
   * @returns 
   */
  async update(params: IPVehicleResourceFields) {
    const url = "/shc2/source/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 删除
   * @param id 
   * @returns 
   */
  async remove(id: string) {
    const url = "/shc2/source/delete";
    return await httpRequest.post<boolean>(url, { id });
  }

  /**
   * 在线翻译
   * @param text 
   * @returns 
   */
  async translate(text: string) {
    const url = "/shc2/source/translation";
    return await httpRequest.post<{ dst: string, src: string }>(url, { text });
  }

  /**
   * 分页查询
   * @param params 
   * @returns 
   */
  async page(params: IPVehicleResourceSearch) {
    const url = "/shc2/source/search_page";
    return await httpRequest.post<IPage<IPVehicleResourceDetail>>(url, params);
  }

  /**
   * 查询单条意向
   * @param id
   * @returns 
   */
  async findOne(id: string) {
    const url = `/shc2/source/search_one/${id}`;
    return await httpRequest.post<IPVehicleResourceDetail>(url);
  }

  /**
   * 售车意向转订单
   * @param id
   * @returns 
   */
  async transOrder(params: ITransOrder) {
    const url = `/shc2/order/insert`;
    return await httpRequest.post<string>(url, params);
  }

  /**
   * 售车意向转客户车源
   * @param id
   * @returns 
   */
  async transClient(params: IPVehicleResourceFields) {
    const url = `/shc2/source/TransferCustomerCarSource`;
    return await httpRequest.post<string>(url, params);
  }

  /**
   * 导出
   * @returns 
   */
  async export() {
    const url = `/shc2/source/export`;
    return await httpRequest.getFile(url)
  }

  /**
   * 导入
   * @param callback 
   * @returns 
   */
  import(callback: any, createUser: string) {
    const url = `/shc2/source/import`;
    return importPropsConfig(url, callback, createUser)
  }

  /**
   * 驳回
   * @param  id
   * @param  result
   * @returns 
   */
  async reject(id: string, reason: string) {
    const url = `/shc2/source/reject`;
    return await httpRequest.post(url, { id, reason })
  }
}

export const pVehicleResourceService = new PVehicleResourceService()