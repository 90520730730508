import React from "react"
import { useDynamicModel } from "../dynamicModel"
import { ISearchValue, SearchTypeEnum } from "@src/components/YhPageHeader/Types"
import { YhPageHeader } from "@src/components/YhPageHeader"
import { useDictModel } from "@src/views/base/dict/dictModel"
import { ColumnDs } from '@src/types/FmsDs'
export function PageHeader () {
	const {
		searchParam,
		initPgCfg,
		updataSearchParam,
		resetSearchParam,
		loadData
	} = useDynamicModel()
	// const { getSelectedDictOne } = useDictModel();
	const searchFields = [
		{
			type: SearchTypeEnum.Input,
			placeholder: "标题",
			xxl: 4,
			span: 4,
			fieldName: "title"
		},
		{
			type: SearchTypeEnum.Select,
			placeholder: "归属栏目",
			xxl: 4,
			span: 4,
			fieldName: "column",
			options: ColumnDs
		},
		{
			type: SearchTypeEnum.Datepicker,
			placeholder: "创建时间",
			xxl: 4,
			span: 4,
			fieldName: "createTime"
		}
	]

	return (
		<YhPageHeader
			title="本站动态"
			searchFields={searchFields}
			searchValues={searchParam as ISearchValue}
			onChange={updataSearchParam}
			triggerSearch
			handleSearch={loadData}
			handleReset={resetSearchParam}
		/>
	)
}