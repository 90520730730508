import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import useNavModel from '@src/model/navModel';
import { Button } from 'antd';
import React from 'react'
import { useVehicleModle } from '../vehicleModel';
import { DataTable } from './DataTable'

export function DataBox() {
  const { addNav } = useNavModel()
  const { selectedRowKeys, exportVehicle } = useVehicleModle()
  const action = (
    <>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length === 0}
        onClick={() => {
          addNav('ucms-05-02-01')
        }}
      >查看</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={exportVehicle}
      >导出</Button>
    </>
  );
  return (
    <YhBox title={<OrderedListOutlined />} action={action}>
      <DataTable />
    </YhBox>
  )
}