import React, { useEffect, useRef, useState } from "react";
import { YHDrawer } from "@components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@components/YhFormBox/Types";
import { YhForm } from "@components/YhFormBox";
import { usePVehicleResourceModle } from "../vehicleResourceModel";
import { globalPrompt } from "@src/components/YhMessage";
import { pVehicleResourceService } from "../VehicleResourceService";
import { purchaseSupplierService } from "../../../user/UserService";
import useNavModel from "@src/model/navModel";
import { IUserFields } from "@src/views/user/UserTypes";
import moment from "moment";
import useGlobalModel from "@src/model/globalModel";
import { usePurchaseOrderModel } from "../../order/orderModel";


export function TransOrderDrawer() {
    const formRef = useRef<IYhFormRef>();
    const { resetEditCatch } = usePurchaseOrderModel();
    const { user } = useGlobalModel()
    const { addNav, findNav, selectNav, switchNavState } = useNavModel()
    const {
        orderVisible,
        selectedRow,
        setOrderVisible,
    } = usePVehicleResourceModle();
    const [supper, setSupper] = useState<IUserFields>()
    const [auth, setAuth] = useState<string>()

    useEffect(() => {
        orderVisible && initData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderVisible])

    const initData = () => {
        console.log("selectedRow:", selectedRow);
        var vehicle = selectedRow?.reduce((p, n) => {
            p[n.userId as string] ? p[n.userId as string]++ : p[n.userId as string] = 1

            p[n.ownership as string] ? p[n.ownership as string]++ : p[n.ownership as string] = 1

            // 供应商
            if (p[n.userId as string] > p.maxUserNum) {
                p.maxUserNum++;
                p.maxUserEl = n.userId as string
            }

            if (!p.maxUserNum) {
                p.maxUserNum = 1
                p.maxUserEl = n.userId as string
            }
            // 车辆所属
            if (p[n.ownership as string] > p.maxOwnerNum) {
                p.maxOwnerNum++;
                p.maxOwnerEl = n.ownership as string
            }

            if (!p.maxOwnerNum) {
                p.maxOwnerNum = 1
                p.maxOwnerEl = n.ownership as string
            }

            return p
        }, {} as {
            maxUserEl: string;
            maxUserNum: number;
            maxOwnerEl: string;
            maxOwnerNum: number;
            [key: string]: any;
        })
        initSupper(vehicle?.maxUserEl as string)
        setAuth(vehicle?.maxOwnerEl)
        console.log('出现最多的用户:', vehicle?.maxUserEl, '\n出现次数:',
            vehicle?.maxUserNum, '\n出现最多的归属:', vehicle?.maxOwnerEl, '\n出现次数:', vehicle?.maxOwnerNum);
    }


    const initSupper = async (id: string) => {
        try {
            const { retData } = await purchaseSupplierService.findOne(id)
            setSupper(retData)
            console.log("供应商：", retData);
        } catch (error) {

        }
    }
    const onCancel = () => {
        setOrderVisible(false);
        setAuth(undefined)
        setSupper(undefined)
    }
    const onOk = async () => {
        try {
            const formData = await formRef.current?.validateFields()
            const params = {
                ...formData,
                userType: "supplier",
                userCode: supper?.id,
                createUser: user.userName,
                ShcSource: selectedRow?.map(item => {
                    return {
                        ...item,
                        filemgr: undefined,
                        updateUser: undefined,
                        updateTime: undefined,
                        createUser: undefined,
                        createTime: undefined,
                    }
                }),
                ShcContract: selectedRow?.reduce((p, n) => {
                    let index = p.findIndex((item) => item.modelCode === n.modelCode && item.vehicleModel === n.vehicleModel)
                    index !== -1 ? p[index].number++ : p.push({
                        modelCode: n.modelCode as string,
                        modelName: n.modelName as string,
                        vehicleModel: n.vehicleModel as string,
                        number: 1
                    })
                    return p
                }, [] as Array<any>)
            }
            console.log('转订单：', params);
            const { retData, success } = await pVehicleResourceService.transOrder(params)
            if (success) {
                globalPrompt('message', { text: '转订单成功！', type: 'success' })
                let saleOrderEditKey = "ucms-02-03-01";
                let isFindNav = findNav(saleOrderEditKey);
                if (!isFindNav) {
                    addNav(saleOrderEditKey, { id: retData as string, mode: "edit" });
                } else {
                    globalPrompt("modal", {
                        title: "销售订单跳转详情",
                        type: "warning",
                        text: "您还有销售订单详情未保存，是否跳转",
                        okText: "跳转未保存页",
                        onOk: () => selectNav(isFindNav ? isFindNav.code : ""),
                        cancelText: "打开新页签",
                        onCancel: () => {
                            resetEditCatch()
                            switchNavState(saleOrderEditKey, { id: retData as string, mode: "edit" });
                            onCancel()
                        }
                    })
                }
            } else {
                globalPrompt('message', { text: '转订单失败！', type: "error" })
            }

        } catch (error) {
            console.log(error)
        }
    }
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        disabled: true,
        initialValues: {
            userName: supper?.authTypeCode === "customer-type.personal" ? supper?.userName : supper?.company,
            tel: supper?.manualTel,
            auth: auth,
            purorderNumber: selectedRow?.length
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "userName",
                disabled: true,
                span: 24,
                rules: [{ required: true, message: "未选择供应商" }]
            }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "供应商名称",
                //     fieldName: "userName",
                //     disabled: true,
                //     span: 24,
                //     rules: [{ required: true, message: "未选择供应商" }]
                // }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "姓名",
                //     fieldName: "userName",
                //     hidden: supper?.authTypeCode === "customer-type.personal",
                //     disabled: true,
                //     span: 24,
                // }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电话",
                fieldName: "tel",
                disabled: true,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆归属",
                fieldName: "auth",
                span: 24,
                rules: [{ required: true, message: "未生成归属" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "台数",
                fieldName: "purorderNumber",
                disabled: true,
                span: 24,
                rules: [{ required: true, message: "未选择台数" }]
            }
        ]
    };
    return (
        <YHDrawer
            title="车辆信息转订单"
            visible={orderVisible}
            onCancel={onCancel}
            onOk={onOk}
            showFooter={true}
        >
            <YhForm  {...formConfig} />
        </YHDrawer>
    )
}