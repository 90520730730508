import React, { FC, useState } from "react";
import { Button, Row } from "antd";
import { ISearchProp } from "./Types";
import { SearchItem } from "./item";
import "./header.less";

export const YhPageHeader: FC<ISearchProp> = (props) => {
    const {
        title,
        handleReset = () => {
        },
        handleSearch = () => {
        },
        searchFields,
        searchMoreFields,

    } = props;
    const [showMore, setShowMore] = useState(false);
    return (
        <div className="toolbar">
            <span className="modules-name" hidden={!title}>{title}</span>
            <div
                hidden={!searchFields || searchFields?.length === 0}
                className="pub-search-box"
            >
                <Row
                    gutter={[20, 10]}
                    justify="end"
                >
                    {searchFields?.map(
                        (el, index) => <SearchItem
                            key={index}
                            {...{ ...props, ...el }} />
                    )}
                </Row>
                <Row
                    hidden={!searchMoreFields || searchMoreFields?.length === 0}
                    gutter={[20, 10]}
                    justify="start"
                >
                    {
                        searchMoreFields?.map(
                            (el, index) => <SearchItem
                                key={index}
                                {...{ ...props, ...el }}
                            />
                        )}
                </Row>
            </div>
            <div
                hidden={!searchFields || searchFields?.length === 0}
                className="pub-search-right"
            >
                <Button
                    shape="round"
                    onClick={handleSearch}
                >
                    检索
                </Button>
                <Button
                    shape="round"
                    onClick={handleReset}
                >
                    重置
                </Button>
                <Button
                    hidden={!(searchMoreFields && searchMoreFields.length !== 0)}
                    shape="round"
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? "隐藏" : "更多"}
                </Button>
            </div>
        </div>
    );
}