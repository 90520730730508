import { httpRequest } from '@src/utils/HttpRequest';
import { IPage } from "@src/types/FmsTypes";
import { IPurchaseHistoryFields, IPurchaseHistorySearch, IPurchaseHistoryDetail } from './historyTypes';

export interface IUCMSPage<T, S> extends IPage<T> {
    statistics: S
}
class PurchaseHistoryService {
	async page(params: IPurchaseHistorySearch) {
		const apiUrl = '/shc2/trace/search_page';
		return await httpRequest.post<IPage<IPurchaseHistoryFields>>(apiUrl, params);
	}

	async insert(params: IPurchaseHistoryFields) {
		const apiUrl = '/shc2/purBrowseTrace/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}
}

export const purchaseHistoryService = new PurchaseHistoryService();
