import React from 'react'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { useSaleClientModle } from '../clientModel'

export function PageHeader() {
  const { onSearch, onReset, updateSearchParam, searchParam } = useSaleClientModle()
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '客户编码',
      fieldName: 'customerCode',
      size: "small",
    },
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '联系人',
      fieldName: 'person',
      size: "small",
    },
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '联系电话',
      fieldName: 'tel',
      size: "small",
    },
    {
      type: SearchTypeEnum.Datepicker,
      xxl: 4,
      span: 4,
      placeholder: '创建时间',
      fieldName: 'createTime',
      format: "YYYY-MM-DD",
      size: 'small',
    },
  ]
  return (
    <div>
      <YhPageHeader
        title="销售管理/客户管理"
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={onSearch}
        searchFields={searchFields}
        searchValues={searchParam}
      />
    </div>
  )
}