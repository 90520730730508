import React, { useEffect, useRef, useState } from "react";
import { YHDrawer } from "@components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@components/YhFormBox/Types";
import { YhForm } from "@components/YhFormBox";
import { useSaleDemandModle } from "../demandModel";
import { IUserDetail, IUserFields } from "@src/views/user/UserTypes";
import { saleClientService } from "@src/views/user/UserService";
import { sVehicleResourceService } from "../../vehicleResource/VehicleResourceService";
import { globalPrompt } from "@src/components/YhMessage";
import useNavModel from "@src/model/navModel";
import useGlobalModel from "@src/model/globalModel";


export function TransOrderDrawer() {
    const { user } = useGlobalModel()
    const { addNav, findNav, switchNavState, selectNav } = useNavModel()
    const {
        orderVisible,
        setOrderVisible,
        selectedRowData,
    } = useSaleDemandModle();

    const [client, setClient] = useState<IUserFields>()


    useEffect(() => {
        initClient(selectedRowData?.userId as string)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderVisible])


    const initClient = async (id: string) => {
        try {
            const { retData } = await saleClientService.findOne(id)
            setClient(retData)
            console.log("用户：", retData);
        } catch (error) {

        }
    }

    const onCancel = () => {
        setOrderVisible(false);
    }

    const onOk = async () => {
        try {
            const formData = await formRef.current?.validateFields()
            const params = {
                ...formData,
                userType: "customer",
                userCode: client?.id,
                createUser: user.userName,
                countryCode: selectedRowData?.countryCode,
                countryName: selectedRowData?.countryName,
                ShcDemand: [{
                    ...selectedRowData,
                    filemgr: undefined,
                    updateUser: undefined,
                    updateTime: undefined,
                    createUser: undefined,
                    createTime: undefined,
                }],
                ShcContract: [{
                    modelCode: selectedRowData?.modelCode,
                    modelName: selectedRowData?.modelName,
                    vehicleModel: selectedRowData?.vehicleModel,
                    number: formData.purorderNumber
                }]
            }
            console.log('转订单：', params);
            const { retData, success } = await sVehicleResourceService.transOrder(params)
            if (success) {
                globalPrompt('message', { text: '订单新增成功！', type: 'success' })
                let saleOrderEditKey = "ucms-03-02-01";
                let isFindNav = findNav(saleOrderEditKey);
                if (!isFindNav) {
                    addNav(saleOrderEditKey, { id: retData, mode: "edit" });
                } else {
                    globalPrompt("modal", {
                        title: "销售订单跳转详情",
                        type: "warning",
                        text: "您还有销售订单详情未保存，是否跳转",
                        okText: "跳转未保存页",
                        onOk: () => selectNav(isFindNav ? isFindNav.code : ""),
                        cancelText: "打开新页签",
                        onCancel: () => {
                            switchNavState(saleOrderEditKey, { id: retData, mode: "edit" });
                        }
                    })
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const formRef = useRef<IYhFormRef>();
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        disabled: true,
        initialValues: {
            company: client?.company,
            userName: client?.userName,
            tel: client?.autoTel,
            purorderNumber: Number(selectedRowData?.number) - Number(selectedRowData?.transorderNum ?? 0),
            // purorderNumber: selectedRow?.length
        },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "company",
                // disabled: true,
                span: 24,
                rules: [{ required: true, message: "未选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "姓名",
                fieldName: "userName",
                disabled: true,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电话",
                fieldName: "tel",
                disabled: true,
                span: 24,
            }, {
                type: FormItemTypeEnum.InputNumber,
                fieldLabel: "台数",
                min: 1,
                max: Number(selectedRowData?.number) - Number(selectedRowData?.transorderNum ?? 0),
                fieldName: "purorderNumber",
                disable: false,
                span: 24,
                rules: [{ required: true, message: "未选择台数" }]
                // }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "求购编码",
                //     fieldName: "demandCode",
                //     disabled: true,
                //     span: 24,
            }
        ]
    };

    return (
        <YHDrawer
            title="客户需求转订单"
            visible={orderVisible}
            onCancel={onCancel}
            onOk={onOk}
            showFooter={true}
        >
            <YhForm  {...formConfig} />
        </YHDrawer>
    )
}