import { YhBox } from '@src/components/YhBox'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum, IDescription } from '@src/components/YhBox/Types'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import { useDsModel } from '@src/model/dsModel'
import useGlobalModel from '@src/model/globalModel'
import useNavModel from '@src/model/navModel'
import { BooleanFlagDs } from '@src/types/FmsDs'
import { Button } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { usePurchaseDemandModle } from '../demandModel'

export const PurchaseDemandDetail = () => {
  const { user } = useGlobalModel()
  const {
    formRef,
    selectedRowData,
    editModel,
    handSubmit,
    loadData,
  } = usePurchaseDemandModle()
  const {
    vehicleTypeDs,
    engineDs,
    gearboxTypeDs,
    drivingTypeDs,
    fuelTypeDs,
    powerTypeDs,
    vehicleBrandDs,
    labelDs,
    emissionDs,
  } = useDsModel()
  const { addNav } = useNavModel()

  const formConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    disabled: editModel === 'look',
    initialValues: editModel === 'add' ? {
      createUser: user.userName,
      createTime: moment().format("YYYY-MM-DD HH:mm:ss")
    } : {
      ...selectedRowData,
      createTime: selectedRowData?.createTime ? moment(selectedRowData?.createTime).format('YYYY-MM-DD HH:mm:ss') : '',
      produceTime: selectedRowData?.produceTime ? moment(selectedRowData.produceTime) : '',
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆品牌",
        fieldName: "modelCode",
        ds: vehicleBrandDs,
        span: 6,
        rules: [
          { required: true, message: "请选择车辆品牌" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆型号",
        fieldName: "vehicleModel",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆类型",
        fieldName: "typeCode",
        span: 6,
        ds: vehicleTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "驱动形式",
        fieldName: "drivingFormCode",
        span: 6,
        ds: drivingTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择驱动形式" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "马力",
        fieldName: "horsepowerName",
        span: 6,
        // ds: powerTypeDs,
        rules: [
          { required: true, message: "请输入马力" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "燃料种类",
        fieldName: "fuelTypeCode",
        span: 6,
        ds: fuelTypeDs,
        rules: [
          { required: true, message: "请选择燃料种类" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "是/否可过户",
        fieldName: "transferOwnershipFlag",
        span: 6,
        ds: BooleanFlagDs,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "行驶里程",
        fieldName: "mileage",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "排放标准",
        fieldName: "emissionStandardCode",
        span: 6,
        ds: emissionDs,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "求购台数",
        fieldName: "number",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "发动机品牌",
        fieldName: "engineBrandCode",
        span: 6,
        ds: engineDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择发动机品牌" }
        // ],

      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "变速箱品牌",
        fieldName: "gearboxBrandCode",
        span: 6,
        ds: gearboxTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择变速箱品牌" }
        // ],

      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "出厂日期",
        fieldName: "produceTime",
        allowClear: true,
        // rules: [
        //   { required: true, message: "请选择出厂日期" }
        // ],
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "标签选择",
        fieldName: "labelCode",
        span: 6,
        ds: labelDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择标签选择" }
        // ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "求购描述",
        fieldName: "desc",
        labelCol: { span: 4 },
        span: 12,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建人",
        fieldName: "createUser",
        hidden: editModel === 'add',
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        hidden: editModel === 'add',
        disable: true,
        span: 6,
      },
    ]
  };

  const Title = <>
    <Description
      label="求购编号"
      value={selectedRowData?.demandCode as string}
      color={DescriptionColorEnum.green}
      status={true}
    />
    <Description
      label="状态"
      value={selectedRowData?.status === 1 ? "启用" : "禁用"}
      color={DescriptionColorEnum.green}
      status={true}
    />
  </>
  const submit = () => {
    handSubmit().then(() => {
      loadData().then()
      addNav('ucms-02-01');
    })
  }
  const Action = (
    <>
      <Button type="link" shape="round" hidden={editModel === 'look'} onClick={submit}>{editModel === 'add' ? '发布' : '保存'}</Button>
      <Button type="link" shape="round" onClick={() => { addNav('ucms-02-01') }}>返回</Button>
    </>
  )

  return (
    <div>
      <YhBox title={editModel === "add" ? "需求详情" : Title} action={Action} />
      <YhBox title="求购信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...formConfig} ></YhForm>
      </YhBox>
    </div>
  )
}