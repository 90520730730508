import { httpRequest } from '@src/utils/HttpRequest';
import { IPage } from "@src/types/FmsTypes";
import {
	ISaleCollectFields,
	ISaleCollectSearch,
	ISaleCollectDetail,
} from './collectTypes';

class SaleCollectService {
	async page(params: ISaleCollectSearch) {
		const apiUrl = '/shc2/collection/search_page';
		return await httpRequest.post<IPage<ISaleCollectFields>>(apiUrl, params);
	}

	async insert(params: ISaleCollectFields) {
		const apiUrl = '/shc2/collection/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	async remove(params: ISaleCollectFields) {
		const apiUrl = '/shc2/collection/delete';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

}

export const saleCollectService = new SaleCollectService();
