import { YhBox } from '@src/components/YhBox'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import useNavModel, { RouterParamType } from '@src/model/navModel'
import { ColumnDs, ModelTypeDs, TransferOwnershipFlagDs, TranslateDs } from '@src/types/FmsDs'
import { importPropsConfig, UploadInfo } from '@src/utils/importFile'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { Button, Card, Col, Form, message, Modal, Upload } from 'antd'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useAboutUsModel } from '../aboutUsModel'
import { getBase64 } from '@src/utils/utils'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import BraftEditor, { ControlType, ExtendControlType } from 'braft-editor'
import { globalPrompt } from '@src/components/YhMessage'
import { useGlobalModel } from "@src/model/globalModel"
import 'braft-editor/dist/index.css';
import { policyService } from '../../policy/policyService'
import axios from 'axios'
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from '@src/utils/constants'

export const AboutUsDetail = () => {
	const {
		formRef,
		loading,
		addEditorState,
		setAddEditorState,
		editEditorState,
		setEditEditorState,
		editFormVal,
		addFormVal,
		insertDetail,
		editDetail,
		resetAddCatch,
		resetEditCatch,
		updateEditFormVal,
		updateAddFormVal,
		selectedRow,
		setEditFormVal,
		translate
	} = useAboutUsModel()
	const { getSelectedNavState, replaceNav, selectedNavId, findNav } = useNavModel()
	const { user } = useGlobalModel()
	const [headFileList, setheadFileList] = useState<any[]>([])
	const [previewImage, setpreviewImage] = useState({ visible: false, md5: "" })
	const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

	useEffect(() => {
		if (detailsInfo.mode === "edit" && !editFormVal.id) {
			getDetailEditOne(detailsInfo.id)
		}
		detailsInfo.mode === "edit" ? setEditEditorState(BraftEditor.createEditorState(editEditorState)) :
			setAddEditorState(BraftEditor.createEditorState(addEditorState));
	}, [detailsInfo])


	useEffect(() => {
		return () => {
			!findNav("ucms-04-03-01") && resetAddCatch(detailsInfo.mode);
			!findNav("ucms-04-03-02") && resetEditCatch(detailsInfo.mode);
		}
	}, [selectedNavId])

	// 获取一条数据
	const getDetailEditOne = async (id: string) => {
		let { retData } = await policyService.one(id);
		const { ...form } = retData;
		if (retData) {
			let imgFileList = retData.photo ? [{
				uid: '-1',
				status: 'done',
				url: getBaseImgLookUrl(retData.photo),
			}] : [];
			//回显图片
			setheadFileList(imgFileList);
			setpreviewImage({ visible: false, md5: retData.photo as string })
			setEditFormVal({
				...form,
				photo: imgFileList as unknown as string
			});
			setEditEditorState(BraftEditor.createEditorState(form.articl));
		} else {
			globalPrompt('message', { type: 'success', text: '操作失败请重试!' });
		}
	};

	// 提交表单
	const onSave = () => {
		formRef.current?.validateFields().then(async res => {
			if (detailsInfo.mode === "add") {
				const retData = await insertDetail({
					...res,
					title: res.title.trim(),
					articl: addEditorState,
					status: res.status ? 1 : 0,
					photo: headFileList.length > 0 ? previewImage.md5 ? previewImage.md5 : null : null,
					insertType: "aboutUs"
				})
				if (retData) {
					globalPrompt("message", { type: "success", text: "发布成功!" })
					setAddEditorState('')
					updateAddFormVal(null);
					goBack()
				} else {
					globalPrompt('message', { type: 'error', text: "发布失败!" })
				}
			} else if (detailsInfo.mode === 'edit') {
				let { id } = editFormVal
				const resData = await editDetail({
					...res,
					id,
					title: res.title.trim(),
					articl: editEditorState,
					status: res.status ? 1 : 0,
					photo: headFileList.length > 0 ? previewImage.md5 ? previewImage.md5 : null : null,
					orderNum: 0
				})
				if (resData) {
					globalPrompt("message", { text: "编辑成功", type: "success" });
					updateEditFormVal(null);
					setEditEditorState('')
					goBack()
				} else {
					globalPrompt("message", { text: "编辑失败", type: "error" })
				}
			}
		})
	}


	const goBack = () => {
		replaceNav(selectedNavId, "ucms-04-03")
	}

	// 表单切换
	const onChange = (changedValues: { [key: string]: any }, values?: any) => {
		detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
	}

	const formConfig: IYhForm = {
		formRef,
		labelCol: { span: 8 },
		initialValues: detailsInfo.mode === "edit" ? {
			...editFormVal,
			createTime: editFormVal?.createTime?.replace(/T/g, " "),
		} : {
			...addFormVal,
			createTime: moment(selectedRow?.createTime).format("YYYY-MM-DD HH:mm:ss"),
			createUser: user.userName
		},
		justify: 'start',
		onChange,
		items: [
			{
				type: FormItemTypeEnum.Input,
				fieldLabel: "内容编码",
				fieldName: "code",
				disable: true,
				span: 6,
				placeholder: "自动生成"
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "文章标题",
				fieldName: "title",
				span: 6,
				rules: [{ required: true, message: "请输入文章标题" }]
			}, {
				type: FormItemTypeEnum.Select,
				fieldLabel: "归属栏目",
				fieldName: "column",
				span: 6,
				rules: [{ required: true, message: "请选择归属栏目" }],
				ds: ColumnDs
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "作者",
				span: 6,
				fieldName: "author",
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "来源",
				span: 6,
				fieldName: "source",
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "关键词",
				fieldName: "keyword",
				span: 6,
				rules: [{ required: true, message: "请输入关键词" }]
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "创建时间",
				span: 6,
				disable: true,
				fieldName: "createTime",
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "创建人",
				span: 6,
				disable: true,
				fieldName: "createUser",
			}, {
				type: FormItemTypeEnum.Input,
				fieldLabel: "描述",
				span: 12,
				fieldName: "desc",
				labelCol: { span: 4 },
				rules: [{ required: true, message: "请输入描述" }]
			}, {
				type: FormItemTypeEnum.Switch,
				fieldLabel: "置顶",
				span: 6,
				fieldName: "status",
			}
		]
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>上传缩略图</div>
		</div>
	);

	// 图片上传
	const handleImgChange = async (info: any) => {
		setheadFileList([]);
		if (info.file.status === "done") {
			let md5 = info.file.response.retData;
			setpreviewImage({ visible: false, md5: md5 })
			setheadFileList(info.fileList);
		}
	};

	const normFile = (e: any) => {
		console.log('Upload event:', e);
		if (e?.file.status === 'done' && e?.file.response.retCode === "200") {
			e.fileList[0] = { ...e.file, uid: e.file.response.retData }
		}
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};
	/**
	 * 预览
	 * @param type 预览类型
	 * @param file 预览文件
	 */

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as Blob) as string;
		}
		globalPrompt('modal', {
			icon: <></>,
			closable: true,
			title: '图片预览',
			footer: <></>,
			text: <img alt="example" style={{ width: '100%' }} src={file.url || file.preview} />
		})
	};


	//富文本配置
	const controls: ControlType[] | undefined = [
		'undo', 'redo', 'separator',
		'font-size', 'line-height', 'letter-spacing', 'separator',
		'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
		'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
		'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
		'link', 'separator', 'hr', 'separator',
		'media', 'separator',
		'clear'
	];
	// 富文本上传图片
	const myUploadFn = (param: any) => {
		const formData = new FormData()
		formData.append('file', param.file)
		Object.entries(getBaseUploadData()).forEach(item => {
			formData.append(item[0], item[1].toString())
		})
		axios.post(getBaseImgUploadUrl(), formData, {
			headers: { "Content-Type": "multipart/form-data;" },
			onUploadProgress (event) {
				param.progress(event.loaded / event.total * 100)
			},
		}).then(result => {
			console.log(JSON.stringify(result, null, 2));
			param.success({
				url: getBaseImgLookUrl(result.data.retData)
			})
		}).catch(error => {
			param.error({
				msg: '上传失败'
			})
		})
	}

	const onTranslation = () => {
		translate(detailsInfo.mode)
	}

	const Action = (
		<>
			<Button
				className="mr-5"
				type="link"
				shape="round"
				onClick={onSave}
			>发布</Button>
			<Button
				className="mr-5"
				type="link"
				shape="round"
				onClick={onTranslation}
				disabled={editFormVal.column === 0 || addFormVal.column === 0 ? true : false}
			>在线翻译</Button>
			<Button type="link" className="mr-5" shape="round" onClick={goBack}>返回</Button>
		</>
	)
	return (
		<>
			<YhBox title='关于我们详情' action={Action} style={{ marginTop: '15px', padding: "20px" }}>
				<YhForm {...formConfig} >
					<Col span="6">
						<Form.Item
							label="缩略图"
							name="photo"
							labelCol={{ span: 8 }}
							valuePropName="fileList"
							getValueFromEvent={normFile}
							required={true}
						>
							<Upload
								// action="https://file-server.laoniuche.cn/file/uploadFile"
								action={getBaseImgUploadUrl()}
                        		data={getBaseUploadData()}
								listType="picture-card"
								className="avatar-uploader"
								fileList={headFileList}
								onPreview={handlePreview}
								onChange={handleImgChange}
							>
								{headFileList.length >= 1 ? null : uploadButton}
							</Upload>
						</Form.Item>
					</Col>
				</YhForm>
				<Card title="文章内容:">
					<BraftEditor
						value={detailsInfo.mode === "edit" ? editEditorState : addEditorState}
						controls={controls}
						onChange={(e) => detailsInfo.mode === "edit" ? setEditEditorState(e.toHTML()) : setAddEditorState(e.toHTML())}
						media={{ uploadFn: myUploadFn }}
						placeholder="请输入正文内容"
					/>
				</Card>
			</YhBox>
		</>
	)
}

