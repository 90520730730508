import React from "react";

export function Home() {

    return (
        <div className="flex-column home-content">
            Home
        </div >
    )
}
