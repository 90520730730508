import React from "react"
import { Button } from "antd";
import { IDictFormProps, OptModelTypeEnum } from "./Types";
import { YhBox } from "../YhBox";
import { YhForm } from "../YhFormBox";



const DictForm = (props: IDictFormProps) => {
    const {
        initialValues,
        items,
        disabled,
        optModelType,
        onSubmit,
        onReset,
        onCancel,
        formRef,
        formTitle,
        children,
    } = props

    return (
        <YhBox title={formTitle ?? "字典表单"} width="100vw">
            <YhForm
                labelCol={{ span: 6 }}
                disabled={disabled}
                initialValues={initialValues}
                items={items}
                formRef={formRef}
                justify="start"
            >
                {children}
            </YhForm>
            <div style={{ marginLeft: "150px" }} hidden={disabled}>
                <Button
                    onClick={onSubmit}
                    shape="round"
                    type="link"
                >
                    {
                        optModelType !== OptModelTypeEnum.DETAIL
                            ? "添加"
                            : "修改"
                    }
                </Button>
                <Button
                    onClick={onReset}
                    shape="round"
                    type="link"
                >
                    重置
                </Button>
                <Button
                    onClick={onCancel}
                    shape="round"
                >
                    取消
                </Button>
            </div>
        </YhBox>
    )
}
export default DictForm
