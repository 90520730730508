import { YhBox } from '@src/components/YhBox'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum } from '@src/components/YhBox/Types'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm, IYhFormRef } from '@src/components/YhFormBox/Types'
import { globalPrompt } from '@src/components/YhMessage'
import { useDsModel } from '@src/model/dsModel'
import useNavModel from '@src/model/navModel'
import { Button } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useSaleDemandModle } from '../demandModel'
import { saleDemandService } from '../DemandService'

export const SaleDemandDetail = () => {
  const {
    vehicleTypeDs,
    gearboxTypeDs,
    engineDs,
    drivingTypeDs,
    fuelTypeDs,
    powerTypeDs,
    vehicleBrandDs,
    labelDs,
    tradeCountryDs,
  } = useDsModel()
  const formRef = useRef<IYhFormRef>();
  const {
    selectedRowData,
    editModel
  } = useSaleDemandModle()
  const { replaceNav } = useNavModel()
  const formConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    // disabled: editModel === 'look',
    initialValues: {
      ...selectedRowData,
      createTime: moment(selectedRowData?.createTime),
      // author: user.userName,
      // shops: selectedRowData?.shops?.map(item => item.code),
      // roles: selectedRowData?.roles?.map(item => item.code),
      // status: selectedRowData?.status === 0
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆品牌",
        fieldName: "modelCode",
        ds: vehicleBrandDs,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆型号",
        fieldName: "vehicleModel",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆类型",
        fieldName: "typeCode",
        span: 6,
        ds: vehicleTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "驱动形式",
        fieldName: "drivingFormCode",
        span: 6,
        ds: drivingTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "马力",
        fieldName: "horsepowerName",
        span: 6,
        // ds: powerTypeDs,
        rules: [
          { required: true, message: "请选择马力" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "燃料种类",
        fieldName: "fuelTypeCode",
        span: 6,
        ds: fuelTypeDs,
        rules: [
          { required: true, message: "请输入燃料种类" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "求购数量",
        fieldName: "number",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "贸易国别",
        fieldName: "countryName",
        span: 6,
        // ds: tradeCountryDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "标签选择",
        fieldName: "labelCode",
        span: 6,
        ds: labelDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择标签选择" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "发动机品牌",
        fieldName: "engineBrandCode",
        span: 6,
        ds: engineDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择发动机品牌" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "变速箱品牌",
        fieldName: "gearboxBrandCode",
        span: 6,
        ds: gearboxTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择变速箱品牌" }
        ],
      }, {
        //   type: FormItemTypeEnum.Input,
        //   fieldLabel: "报废年限",
        //   fieldName: "ageLimit",
        // }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "求购描述",
        fieldName: "desc",
        labelCol: { span: 4 },
        span: 12,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建人",
        fieldName: "createUser",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        disable: true,
        span: 6,
      },
    ]
  };
  const handSubmit = async () => {
    try {
      const formData = await formRef.current?.validateFields()
      const params = {
        ...formData,
        createUser: undefined,
        createTime: undefined,
      }
      console.log(params);
      if (editModel === 'add') {
        const { retData, success } = await saleDemandService.insert(params)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
          onCancel()
        }
      }
      if (editModel === 'edit') {
        const updateParams = {
          ...params,
          id: selectedRowData?.id,
          demandCode: selectedRowData?.demandCode
        }
        const { retData, success } = await saleDemandService.update(updateParams)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
          onCancel()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onCancel = () => {
    replaceNav('ucms-03-03-01', 'ucms-03-03')
  }


  const Title = <>
    <Description
      label="求购单号"
      value={selectedRowData?.demandCode as string}
      color={DescriptionColorEnum.green}
      status={true}
    />
    <Description
      label="状态"
      value={selectedRowData?.status === 0 ? "求购中"
        : selectedRowData?.status === 1 ? "转订单"
          : selectedRowData?.status === 2 ? "已成交"
            : selectedRowData?.status as number}
      color={DescriptionColorEnum.green}
      status={true}
    />
  </>
  const Action = (
    <>
      <Button type="link" shape="round" onClick={() => { handSubmit() }}>保存</Button>
      <Button type="link" shape="round" onClick={onCancel}>返回</Button>
    </>
  )
  return (
    <div>
      <YhBox title={Title} action={Action} />
      <YhBox style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...formConfig} ></YhForm>
      </YhBox>
    </div>
  )
}