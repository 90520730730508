import React, { useEffect } from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useSaleHistoryModel } from '../historyModel'
import { ISaleHistoryDetail } from '../historyTypes'
import moment from 'moment'
export function DataTable () {
    const {
        dataSource,
        selectedRowKeys,
        loadData,
        pgCfg
    } = useSaleHistoryModel()

    const columns: ColumnsType<ISaleHistoryDetail> = [
        {
            title: '序号',
            align: 'center',
            fixed: 'left',
            render: (t, r, i) => i + 1
        },
        {
            title: '客户名称',
            align: 'center',
            dataIndex: 'userName'
        },
        {
            title: '车辆编号',
            align: 'center',
            dataIndex: 'vehicleCode'
        },
        {
            align: 'center',
            title: "车辆品牌",
            dataIndex: 'modelName'
        },
        {
            align: 'center',
            title: "车辆型号",
            dataIndex: 'vehicleModel'
        },
        {
            title: '车辆类型',
            align: 'center',
            dataIndex: 'typeName'
        },
        {
            title: '出厂日期',
            align: 'center',
            dataIndex: 'produceTime',
            render: (v) => moment(v).format('YYYY-MM-DD')
        },
        {
            title: '驱动形式',
            align: 'center',
            dataIndex: 'drivingFormName'
        },
        {
            title: '马力',
            align: 'center',
            dataIndex: 'horsepowerName'
        },
        {
            title: '燃料种类',
            align: 'center',
            dataIndex: 'fuelTypeName'
        },
        {
            title: '行驶里程',
            align: 'center',
            dataIndex: 'mileage'
        },
        {
            title: '浏览类型',
            align: 'center',
            dataIndex: 'browseTypeName',
            // render: (v, r, i) => v === "001" ? '车源发布':''
        },
        {
            title: '开始浏览时间',
            align: 'center',
            dataIndex: 'browseStartTime',
            render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '浏览时长(秒)',
            align: 'center',
            dataIndex: 'browseDuration',
        }
    ]

    useEffect(() => {
        loadData()
    }, [])

    return (
        <Table
            style={{ cursor: "pointer" }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ['bottomCenter'],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then()
                }
            }}
            scroll={{ x: "max-content" }}
        />
    )
}