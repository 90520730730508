import { httpRequest } from '@src/utils/HttpRequest';
import { IPage } from '@src/types/FmsTypes';
import {
	IPurchaseCollectFields,
	IPurchaseCollectSearch,
	IPurchaseCollectDetail,
} from './collectTypes';

class PurchaseCollectService {
	async page(params: IPurchaseCollectSearch) {
		const apiUrl = '/shc2/collection/search_page';
		return await httpRequest.post<IPage<IPurchaseCollectFields>>(apiUrl, params);
	}

	async insert(params: IPurchaseCollectFields) {
		const apiUrl = '/shc2/collection/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	async delete(params: IPurchaseCollectFields) {
		const apiUrl = '/shc2/collection/delete';
		return await httpRequest.post<boolean>(apiUrl, params);
	}
}

export const purchaseCollectService = new PurchaseCollectService();
