
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { YhBox } from '@src/components/YhBox'
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from '@src/utils/constants';
import { convertRes2Blob } from '@src/utils/exportFile';
import { httpRequest } from '@src/utils/HttpRequest';
import { importPropsConfig, UploadInfo } from '@src/utils/importFile';
import toZip from '@src/utils/jszip';
import { getBase64 } from '@src/utils/utils';
import { Button, message, Modal, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, { FC, MutableRefObject, useImperativeHandle, useState } from 'react'
import DPlayer from 'react-dplayer';
const defaultFile = {} as UploadFile
const detaultVideo = { url: "https://file-server.laoniuche.cn/file/fetch?id=693c51c00f664546b9a791a2d1c26279" } as UploadFile
// eslint-disable-next-line import/first
import playIcon from "../../../../assets/images/play-icon.png";
interface ISetFile {
  photo?: string[];
  registerInfo?: string;
  appReport?: string;
  video?: string;
}
export interface IUploadRef {
  getAllFile: () => {
    photo: any,
    registerInfo: any,
    appReport: any,
    video: any,
  };
  setAllFile: (data: ISetFile) => void
}
export type IUploadFile = {
  uploadRef?: any;
  disabled?: boolean;
  vehicleCode?: string;
}
export const UploadFiles: FC<IUploadFile> = ({ uploadRef, disabled = false, vehicleCode }) => {
  // 车辆图片
  const [fileList, setFileList] = useState<UploadFile[]>([])
  // 预览
  const [previewImage, setPreviewImage] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  const [previewVideo, setPreviewVideo] = useState(false)
  // 机动车注册信息
  const [registerInfo, setRregisterInfo] = useState<UploadFile>(defaultFile)
  // 评估报告
  const [appReport, setAppReport] = useState<UploadFile>(defaultFile)
  const [videoUrl, setVideoUrl] = useState<UploadFile[]>([])


  useImperativeHandle(uploadRef, () => ({
    getAllFile: () => {
      return {
        photo: fileList.map(item => item?.status ? item?.response?.retData : item?.uid),
        registerInfo: registerInfo?.uid,
        appReport: appReport?.uid,
        video: videoUrl[0]?.uid,
      }
    },
    setAllFile: ({ photo, registerInfo, appReport, video }: ISetFile) => {
      photo ? setFileList(photo.map(item => {
        return {
          uid: item,
          url: getBaseImgLookUrl(item)
        } as UploadFile
      })) : handelRemove('imglist')
      registerInfo ? setRregisterInfo({
        uid: registerInfo,
        url: getBaseImgLookUrl(registerInfo)
      } as UploadFile) : handelRemove("regist")
      appReport ? setAppReport({
        uid: appReport,
        url: getBaseImgLookUrl(appReport)
      } as UploadFile) : handelRemove("appReport")
      video ? setVideoUrl([{
        uid: video,
        url: playIcon
      } as UploadFile]) : handelRemove("video")
    }
  }))

  function beforeUpload(file: { type: string; size: number }, type?: 'image' | 'video') {
    let typeBoolen = false
    if (type === "video") {
      typeBoolen = file.type.includes('video');
      if (!typeBoolen) {
        message.error('请上传视频格式文件!');
      }
    } else {
      typeBoolen = file.type.includes('image');
      if (!typeBoolen) {
        message.error('请上传图片格式文件!');
      }
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must smaller than 5MB!');
    }
    return typeBoolen && isLt2M;
  }

  const handleChange = (info: UploadChangeParam, type: 'imglist' | 'regist' | 'appReport' | 'video') => {
    if (!info.file) {
      return handelRemove(type)
    }
    if (type === "regist") {
      setRregisterInfo({
        uid: info.file.response.retData,
        url: getBaseImgLookUrl(info.file.response.retData)
      } as UploadFile)
    }
    if (type === "appReport") {
      setAppReport({
        uid: info.file.response.retData,
        url: getBaseImgLookUrl(info.file.response.retData)
      } as UploadFile)
    }
    if (type === "video") {
      setVideoUrl([{
        uid: info.file.response.retData,
        url: "https://file-server.laoniuche.cn/file/fetch?id=693c51c00f664546b9a791a2d1c26279"
      } as UploadFile])
    }
    console.log('上传结果:', info)
  };
  /**
   * 删除文件
   * @param file 删除的文件
   * @param type 类型
   */
  const handelRemove = (type: 'imglist' | 'regist' | 'appReport' | 'video', file?: UploadFile) => {
    if (type === 'video') {
      setVideoUrl([])
    }
    if (type === 'regist') {
      setRregisterInfo(defaultFile)
    }
    if (type === 'appReport') {
      setAppReport(defaultFile)
    }
    if (type === 'imglist') {
      setFileList(fileList.filter(item => item.url !== file?.url))
    }
    console.log('上传结果:', file)
  };
  /**
   * 预览
   * @param type 预览类型
   * @param file 预览文件
   */
  const handlePreview = async (type: 'image' | 'video', file: UploadFile) => {
    let url = ""
    if (!file.url && !file.preview) {
      url = await getBase64(file.originFileObj) as string;
    } else {
      url = file.url ?? file.preview as string
    }
    setPreviewUrl(url)
    if (type === 'image') {
      setPreviewImage(true)
    } else {
      setPreviewVideo(false)
    }
  };
  /**
   * 取消预览
   */
  const handleCancelPreview = () => {
    setPreviewImage(false)
    setPreviewVideo(false)
    setPreviewUrl('')
  }

  const onDowloadAll = () => {
    toZip(fileList.map(item => item.url as string), vehicleCode ?? '车辆信息')
  }

  const onDowloadImage = async () => {
    const image = await httpRequest.getFile(previewUrl)
    convertRes2Blob(image, `${vehicleCode ?? '车辆信息'} ${moment().format('YYYY-MM-DD HH_mm_ss')}.png`, false)
  }

  const onDowloadVideo = async () => {
    const image = await httpRequest.getFile(getBaseImgLookUrl(videoUrl[0].uid))
    convertRes2Blob(image, `${vehicleCode ?? '车辆信息'} ${moment().format('YYYY-MM-DD HH_mm_ss')}.mp4`, false)
  }
  return (
    <div ref={uploadRef}>
      <div className="mx-15">
        <p>
          上传图片(数量1~10张)
          <Button type="link" shape="round" className="ml-10" onClick={onDowloadAll}>下载图片</Button>
        </p>
        <div className="ml-20">
          <Upload
            listType="picture-card"
            maxCount={10}
            multiple={false}
            disabled={disabled}
            beforeUpload={(file) => beforeUpload(file)}
            fileList={fileList}
            action={getBaseImgUploadUrl()}
            data={getBaseUploadData()}
            onChange={({ fileList }) => setFileList(fileList)}
            onPreview={(file) => handlePreview('image', file)}
            onRemove={(e) => handelRemove('imglist', e)}
          >
            {fileList.length >= 10 ? null : <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>上传图片</div>
            </div>}
          </Upload>
        </div>
      </div>
      <div className="flex mt-15" >
        <div className="flex-column items-center mx-15" >
          <p>注册登记机动车信息栏</p>
          <div style={{ margin: "0 -8px -8px 0" }}>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              maxCount={1}
              disabled={disabled}
              beforeUpload={(file) => beforeUpload(file)}
              {...importPropsConfig('/uploadAttach', (e: UploadChangeParam<UploadFile<any>>) => handleChange(e, 'regist'))}
              action={getBaseImgUploadUrl()}
              data={getBaseUploadData()}
              onPreview={(file) => handlePreview('image', file)}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  handleChange(info, 'regist');
                }
              }}
              fileList={registerInfo.url ? [registerInfo] : undefined}
              onRemove={(e) => handelRemove('regist', e)}
              onDownload={(e) => { }}
            >
              {registerInfo?.url ? null : <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>}
            </Upload>

          </div>
        </div>
        <div className="flex-column items-center mx-15">
          <p>评估报告</p>
          <div style={{ margin: "0 -8px -8px 0" }}>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              maxCount={1}
              disabled={disabled}
              beforeUpload={(file) => beforeUpload(file)}
              {...importPropsConfig('/upload', (e: UploadChangeParam<UploadFile<any>>) => handleChange(e, 'appReport'))}
              action={getBaseImgUploadUrl()}
              data={getBaseUploadData()}
              onPreview={(file) => handlePreview('image', file)}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  handleChange(info, 'appReport');
                }
              }}
              fileList={appReport.url ? [appReport] : undefined}
              onRemove={(e) => handelRemove('appReport', e)}
            >
              {appReport?.url ? null : <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>}
            </Upload>
          </div>
        </div>
        <div className="flex-column items-center mx-15">
          <p>10-15秒车辆视频</p>
          <div style={{ margin: "0 -8px -8px 0" }}>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              // showUploadList={false}
              name='file'
              disabled={disabled}
              beforeUpload={(file) => beforeUpload(file, 'video')}
              onChange={(info) => {
                setVideoUrl(info.fileList)
                if (info.file.status === 'done' && Number(info.file.response.retCode) === 0) {
                  message.info({ content: `${info.file.name}导入${info.file.response.retData}`, duration: 8 });
                  handleChange(info, 'video')
                } else if (info.file.status === 'error' && info.file.response && info.file.response.retData) {
                  message.error(`${info.file.response.retData}：${info.file.name}导入异常，请尝试重新导入`);
                } else if (info.file.status === 'error') {
                  message.error("导入错误")
                }
              }}
              action={getBaseImgUploadUrl()}
              data={getBaseUploadData()}
              onPreview={() => {
                setPreviewVideo(true)
              }}
              maxCount={1}
              onRemove={(e) => handelRemove('video', e)}
              fileList={videoUrl.length > 0 ? videoUrl : []}
            >
              {videoUrl.length ? null : <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传视频</div>
              </div>}
            </Upload>
          </div>
        </div>
      </div >
      {/* 图片预览 */}
      < Modal visible={previewImage} title={'图片预览'} footer={<Button type="link" shape="round" className="ml-10" onClick={onDowloadImage}>下载图片</Button>} onCancel={handleCancelPreview} >
        <img alt="example" style={{ width: '100%' }} src={previewUrl} />
      </Modal >
      {/* 视频预览 */}
      < Modal visible={previewVideo} width="600px" title="视频预览" footer={<Button type="link" shape="round" className="ml-10" onClick={onDowloadVideo}>下载视频</Button>} onCancel={handleCancelPreview} >
        <div style={{ width: '550px' }}>
          <DPlayer
            autoplay
            lang="zh-cn"
            options={{
              video: {
                url: videoUrl.length > 0 ? getBaseImgLookUrl(videoUrl[0].uid) : ""
                // url: 'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4'
                // url: 'https://file-server.laoniuche.cn/file/fetch?id=653dbf36b7a949808c2561c637cfd4a1'
                // url: 'https://file-server.laoniuche.cn/file/fetch?id=0532d45a77ba42b98aaaf859843ee71c',
              },
            }}
          ></DPlayer>
        </div>
      </Modal >
    </div >
  )
}