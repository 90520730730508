import React from 'react'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { usePurchaseDemandModle } from '../demandModel'
import { useDsModel } from '@src/model/dsModel'

export function PageHeader() {
  const { vehicleTypeDs, vehicleBrandDs } = useDsModel()
  const { onReset, loadData, updateSearchParam, searchParam } = usePurchaseDemandModle();
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '求购编号',
      fieldName: 'demandCode',
      size: "small",
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '车辆类型',
      fieldName: 'typeCode',
      size: 'small',
      options: vehicleTypeDs
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '车辆品牌',
      fieldName: 'modelCode',
      size: 'small',
      options: vehicleBrandDs
    },
    {
      type: SearchTypeEnum.Datepicker,
      xxl: 4,
      span: 4,
      placeholder: '创建时间',
      fieldName: 'createTime',
      format: "YYYY-MM-DD",
      size: "small",
    },
  ]
  return (
    <div>
      <YhPageHeader
        title="采购管理/我的需求"
        searchValues={searchParam}
        searchFields={searchFields}
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={loadData}
      />
    </div>
  )
}