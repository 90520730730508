import React, { useEffect } from 'react'
import { useSaleClientModle } from '../clientModel'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'

export const SaleClient = () => {
  const { loadData, resetState } = useSaleClientModle()
  useEffect(() => {
    loadData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
  </>
}