import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, TreeSelect } from "antd";

export const __FormTreeSelect: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <TreeSelect
                disabled={props.disable}
                allowClear={props.allowClear}
                treeData={props.treeDs}
                onChange={props.onChange}
                loadData={props.loadData}
            />
        </Form.Item>
    )
}