import { IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { IPurchaseDemandDetail, IPurchaseDemandFields, IPurchaseDemandSearch } from "./DemandTypes";

class PurchaseDemandService {
  /**
   * 新增需求
   * @param params 
   * @returns 
   */
  async insert(params: IPurchaseDemandFields) {
    const url = "/shc2/purret/insert";
    return await httpRequest.post<IPurchaseDemandDetail>(url, params);
  }
  /**
   * 删除需求
   * @param demandCode
   * @returns 
   */
  async remove(demandCode: string) {
    const url = "/shc2/purret/delete";
    return await httpRequest.post<IPurchaseDemandDetail>(url, { demandCode });
  }

  /**
   * 更新需求
   * @param params 
   * @returns 
   */
  async update(params: IPurchaseDemandFields) {
    const url = "/shc2/purret/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 分页查询需求
   * @param params 
   * @returns 
   */
  async page(params: IPurchaseDemandSearch) {
    const url = "/shc2/purret/search_page";
    return await httpRequest.post<IPage<IPurchaseDemandDetail>>(url, params);
  }

  /**
   * 查询单条需求
   * @param code
   * @returns 
   */
  async findOne(code: string) {
    const url = `/shc2/purret/one/${code}`;
    return await httpRequest.post<IPurchaseDemandDetail>(url);
  }

  /**
   * 需求状态修改
   * @param id
   * @param status
   * @returns 
   */
  async enable(id: string, status: '1' | '0') {
    const url = `/shc2/purret/status`;
    return await httpRequest.post<IPurchaseDemandDetail>(url, { id, status });
  }

}

export const purchaseDemandService = new PurchaseDemandService()