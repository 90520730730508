import React, { useState, useEffect } from 'react';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage } from '@src/types/FmsTypes';
import { ISaleHistoryFields, ISaleHistorySearch, ISaleHistoryDetail } from './historyTypes';
import { saleHistoryService } from './historyService';
import { createModel } from 'hox';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@src/utils/constants';
import useGlobalModel from '@src/model/globalModel';
import { transPgToPagination } from '@src/utils/utils';

export const useSaleHistoryModel = createModel(function () {
	const initSearchParam: ISaleHistoryFields = {};
	const initDataSource: ISaleHistoryDetail[] = [];
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	// 查询条件
	const [searchParam, setSearchParam] = useState<ISaleHistoryFields>(initSearchParam);
	// 列表数据源
	const [dataSource, setDataSource] = useState<ISaleHistoryDetail[]>(initDataSource);
	// 表单选中行
	const [selectedRow, setSelectedRow] = useState<ISaleHistoryDetail>({});
	// 表单选中行的key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	// 列表数据源的分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

	useEffect(() => {
		const filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectedRow(selectedData as ISaleHistoryFields);
	}, [selectedRowKeys]);

	const updatePgCfg = (updateParam: TablePaginationConfig) => {
		setPgCfg({ ...pgCfg, ...updateParam });
	};

	const updateSearchParam = (updateParam: ISaleHistorySearch) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	const resetSearchParam = () => {
		setSearchParam(initSearchParam);
		loadData(initPgCfg, {});
	};

	// 加载数据源
	const loadData = async (page?: ISearchPage, searchValue: ISaleHistoryDetail = searchParam) => {
		const searchParams: ISaleHistorySearch = {
			...searchValue,
			userType: 'customer',
			pg: {
				orders: [
					{
						rule: 'ASC',
						column: 'createTime',
					},
				],
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await saleHistoryService.page(searchParams);
		const { items, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(items);
	};

	return {
		searchParam,
		setSearchParam,
		dataSource,
		setDataSource,
		selectedRow,
		setSelectedRow,
		selectedRowKeys,
		setSelectedRowKeys,
		pgCfg,
		setPgCfg,
		initPgCfg,
		updatePgCfg,
		updateSearchParam,
		resetSearchParam,
		loadData,
	};
});
