import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Button, Form, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { importPropsConfig } from "@src/utils/importFile";

export const __FormUpload: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <Upload {...importPropsConfig(props.uploadUrl, props?.onUploadSuccess)} defaultFileList={props.defaultFileList}>
                <Button icon={<UploadOutlined />}>选择文件上传</Button>
            </Upload>
        </Form.Item>
    )
}