import React from 'react'
import { ISearchValue, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { useSaleHistoryModel } from '../historyModel'
import { useDsModel } from '@src/model/dsModel'

export function PageHeader () {
    const {
        searchParam,
        loadData,
        initPgCfg,
        updateSearchParam,
        resetSearchParam
    } = useSaleHistoryModel()
    const { vehicleTypeDs, vehicleBrandDs } = useDsModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '车辆编号',
            fieldName: 'vehicleCode',
            xxl: 4,
            span: 4
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '客户名称',
            fieldName: 'userName',
            xxl: 4,
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '车辆类型',
            fieldName: 'typeCode',
            xxl: 4,
            span: 4,
            options: vehicleTypeDs
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: '浏览时间',
            fieldName: 'createTime',
            xxl: 4,
            span: 4,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: '浏览时长',
            fieldName: 'browseDuration',
            xxl: 4,
            span: 4,
        }
    ]

    return (
        <YhPageHeader
            title="浏览痕迹列表"
            triggerSearch={false}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    )
}