import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form } from "antd";
import JSONTree from 'react-json-tree'
export const __FormJsonView: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}

        >
            <JSONTree data={JSON.parse(props.json)}> </JSONTree>
        </Form.Item>
    )
}
