
import { YhBox } from '@src/components/YhBox'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum } from '@src/components/YhBox/Types'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import { globalPrompt } from '@src/components/YhMessage'
import { useDsModel } from '@src/model/dsModel'
import useGlobalModel from '@src/model/globalModel'
import useNavModel from '@src/model/navModel'
import { BooleanFlagDs } from '@src/types/FmsDs'
import { DEFAULT_CLIENT } from '@src/utils/constants'
import { IUploadRef, UploadFiles } from '@src/views/purchase/vehicleResource/view/UploadFiles'
import { Button, Col, Form, Input } from 'antd'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { Switch } from 'react-router-dom'
import { useSVehicleResourceModle } from '../vehicleResourceModel'
import { sVehicleResourceService } from '../VehicleResourceService'

const { TextArea } = Input

export const SaleVehicleResourceDetail = () => {
  const uploadRef = useRef<IUploadRef>()
  const { user } = useGlobalModel()
  const {
    vehicleTypeDs,
    gearboxTypeDs,
    drivingTypeDs,
    fuelTypeDs,
    vehicleBrandDs,
    labelDs,
    engineDs,
    colorDs,
    emissionDs,
  } = useDsModel()
  const {
    formRef,
    editModel,
    selectedRowData,
  } = useSVehicleResourceModle()
  const { replaceNav } = useNavModel()


  useEffect(() => {
    uploadRef.current?.setAllFile({
      appReport: selectedRowData?.ShcFilemgr?.appReport,
      photo: selectedRowData?.ShcFilemgr?.photo?.split(','),
      registerInfo: selectedRowData?.ShcFilemgr?.registerInfo,
      video: selectedRowData?.ShcFilemgr?.video,
    })

  }, [selectedRowData])

  const handSubmit = async () => {
    try {
      const filesData = uploadRef.current?.getAllFile()
      const formData = await formRef.current?.validateFields()

      const params = {
        ...formData,
        userType: "supplier",
        colorCode: formData?.colorCode ? formData?.colorCode.join(',') : '',
        top: formData?.top ? 1 : 0,
        produceTime: formData.produceTime ? moment(formData.produceTime).format("YYYY-MM-DD HH:mm:ss") : "",
        // registerTime: formData.registerTime ? moment(formData.registerTime).format("YYYY-MM-DD HH:mm:ss") : "",
        // scrapTime: baseFormData.scrapTime ? moment(baseFormData.scrapTime).format("YYYY-MM-DD HH:mm:ss") : "",
        // firstLicenseTime: baseFormData.firstLicenseTime ? moment(baseFormData.firstLicenseTime).format("YYYY-MM-DD HH:mm:ss") : "",
        files: undefined,
        createTime: undefined,
      }
      console.log(params)
      if (editModel === 'add' || editModel === 'copy') {
        const insertParams = {
          ...params,
          status: 1,
          userId: DEFAULT_CLIENT,
          drivingLicense: selectedRowData?.ShcFilemgr?.drivingLicense ?? "",
          businessType: "sales",
          photo: filesData?.photo.join(","),
          registerInfo: filesData?.registerInfo,
          appReport: filesData?.appReport,
          video: filesData?.video,
        }
        const { retData, success } = await sVehicleResourceService.insert(insertParams)
        if (success) {
          globalPrompt('message', { text: '新增成功！', type: 'success' })
          onCancel()
        } else {
          globalPrompt('message', { text: '操作失败！', type: 'error' })
        }
      }
      if (editModel === 'edit') {
        const editParams = {
          ...params,
          id: selectedRowData?.id,
          status: selectedRowData?.status,
          createUser: undefined,
          filemgr: {
            drivingLicense: selectedRowData?.ShcFilemgr?.drivingLicense,
            businessType: "sales",
            id: selectedRowData?.ShcFilemgr?.id,
            photo: filesData?.photo.join(","),
            registerInfo: filesData?.registerInfo,
            appReport: filesData?.appReport,
            video: filesData?.video,
          },
        }
        const { retData, success } = await sVehicleResourceService.update(editParams)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
          onCancel()
        } else {
          globalPrompt('message', { text: '操作失败！', type: 'error' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onCancel = () => {
    replaceNav('ucms-03-01-01', 'ucms-03-01')
  }

  const baseFormConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    disabled: editModel === 'look',
    initialValues: editModel === 'add' ? {
      createUser: user.userName,
      createTime: moment().format("YYYY-MM-DD HH:mm:ss")
    } : {
      ...selectedRowData,
      colorCode: selectedRowData?.colorCode ? selectedRowData?.colorCode.split(',') : '',
      createTime: moment(selectedRowData?.createTime).format("YYYY-MM-DD HH:mm:ss"),
      produceTime: selectedRowData?.produceTime ? moment(selectedRowData?.produceTime) : "",
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆类型",
        fieldName: "typeCode",
        span: 6,
        ds: vehicleTypeDs,
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆品牌",
        fieldName: "modelCode",
        ds: vehicleBrandDs,
        span: 6,
        rules: [
          { required: true, message: "请选择车辆品牌" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆型号",
        fieldName: "vehicleModel",
        span: 6,
        rules: [
          { required: true, message: "请输入车辆型号" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "驱动形式",
        fieldName: "drivingFormCode",
        span: 6,
        ds: drivingTypeDs,
        rules: [
          { required: true, message: "请选择驱动形式" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "是/否可过户",
        fieldName: "transferOwnershipFlag",
        span: 6,
        ds: BooleanFlagDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择是否" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "行驶里程",
        fieldName: "mileage",
        span: 6,
        rules: [
          { required: true, message: "请输入行驶里程" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "马力",
        fieldName: "horsepowerName",
        span: 6,
        // ds: powerTypeDs,
        rules: [
          { required: true, message: "请输入马力" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "燃料种类",
        fieldName: "fuelTypeCode",
        span: 6,
        ds: fuelTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择燃料种类" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        mode: "multiple",
        fieldLabel: "车身颜色",
        fieldName: "colorCode",
        span: 6,
        ds: colorDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择车身颜色" }
        // ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "排放标准",
        fieldName: "emissionStandardCode",
        span: 6,
        ds: emissionDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择排放标准" }
        // ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "变速箱品牌",
        fieldName: "gearboxBrandCode",
        span: 6,
        ds: gearboxTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择变速箱品牌" }
        // ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "排量",
        fieldName: "emission",
        span: 6,
        // rules: [
        //   { required: true, message: "请选择排量" }
        // ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "所在地",
        fieldName: "addressName",
        span: 6,
        // ds: noticeTypeDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        rules: [
          { required: true, message: "请选择所在地" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "价格",
        fieldName: "price",
        span: 6,
        rules: [
          { required: true, message: "请输入价格" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "发动机品牌",
        fieldName: "engineBrandCode",
        span: 6,
        ds: engineDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择发动机品牌" }
        // ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "功率",
        fieldName: "power",
        span: 6,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
        // rules: [
        //   { required: true, message: "请选择功率" }
        // ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "标签选择",
        fieldName: "labelCode",
        span: 6,
        ds: labelDs,
        // filterOption: (inputValue: string, option: ISelectOption) => option.label.includes(inputValue),
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "出厂日期",
        fieldName: "produceTime",
        allowClear: true,
        // rules: [
        //   { required: true, message: "请选择出厂日期" }
        // ],
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建人",
        fieldName: "createUser",
        span: 6,
        disable: true,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "卖家描述",
        fieldName: "desc",
        span: 12,
        labelCol: { span: 4 },
      }, {
        type: FormItemTypeEnum.Switch,
        fieldLabel: "置顶",
        fieldName: "top",
        span: 6,
      },
    ]
  };


  const Title = <>
    <Description
      label="车辆编号"
      value={selectedRowData?.vehicleCode as string}
      color={DescriptionColorEnum.green}
      status={true}
    />
    <Description
      label="状态"
      value={selectedRowData?.status === 1 ? "待售中"
        : selectedRowData?.status === 2 ? "已转接"
          : selectedRowData?.status === 3 ? "已售出"
            : selectedRowData?.status as number}
      color={DescriptionColorEnum.green}
      status={true}
    />
  </>
  const Action = (
    <>
      {editModel === 'look' ? null : <>
        <Button type="link" shape="round" hidden={editModel === 'edit'} onClick={handSubmit}>发布</Button>
        <Button type="link" shape="round" hidden={editModel === 'add' || editModel === "copy"} onClick={handSubmit}>保存</Button>
      </>}
      <Button type="link" shape="round" onClick={onCancel}>返回</Button>
    </>
  )

  return (
    <div>
      <YhBox title={(editModel === "add" || editModel === "copy") ? "车源详情" : Title} action={Action} />
      <YhBox title="基本信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...baseFormConfig} >
          <Col span="6">
            <Form.Item
              label="外轮廓尺寸"
              name="size"
              labelCol={{ span: 8 }}
            >
              <div className="flex">
                <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
                <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
                <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                  <Input disabled={editModel === 'look'} />
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              label="服务保障"
              name="serviceGuarantee"
              labelCol={{ span: 2 }}
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 6 }} disabled={editModel === 'look'} />
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
      <YhBox title="相关信息上传" style={{ marginTop: '15px', padding: "20px" }}>
        <UploadFiles uploadRef={uploadRef} vehicleCode={selectedRowData?.vehicleCode} disabled={editModel === 'look'} />
      </YhBox>
    </div>
  )
}