import { OrderedListOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import useNavModel from '@src/model/navModel';
import { Button } from 'antd';
import React from 'react'
import { usePurchaseDemandModle } from '../demandModel';
import { IPurchaseDemandDetail } from '../DemandTypes';
import { DataTable } from './DataTable'

export function DataBox() {
  const { addNav } = useNavModel()
  const { setEditModel, setSelectedRowData, selectedRowKeys, handleRemove } = usePurchaseDemandModle()
  const handleAdd = () => {
    setEditModel('add');
    addNav('ucms-02-01-01');
    setSelectedRowData({} as IPurchaseDemandDetail)
  }
  const handleEdit = () => {
    setEditModel('edit');
    addNav('ucms-02-01-01');
  }
  const handleRelete = () => {
    if (selectedRowKeys.length > 0) {
      handleRemove()
    }
  }
  const action = (
    <>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={handleAdd}
      >新增</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length === 0}
        onClick={handleEdit}
      >编辑</Button>
      <Button
        className="mr-5"
        type="link"
        shape="round"
        disabled={selectedRowKeys.length === 0}
        onClick={handleRelete}
      >删除</Button>
    </>
  );
  return (
    <YhBox title={<OrderedListOutlined />} action={action}>
      <DataTable />
    </YhBox>
  )
}