import React, { useEffect, useRef, useState } from "react";
import { YHDrawer } from "@components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@components/YhFormBox/Types";
import { YhForm } from "@components/YhFormBox";
import { usePVehicleResourceModle } from "../vehicleResourceModel";
import { Col, Form, Input, Row } from "antd";
import { IUploadRef, UploadFiles } from "../view/UploadFiles";
import moment from "moment";
import { useDsModel } from "@src/model/dsModel";
import { BooleanFlagDs } from "@src/types/FmsDs";
import { sVehicleResourceService } from "@src/views/sale/vehicleResource/VehicleResourceService";
import { globalPrompt } from "@src/components/YhMessage";
import { pVehicleResourceService } from "../VehicleResourceService";
import { ISVehicleResourceFields } from "@src/views/sale/vehicleResource/VehicleResourceTypes";
import useNavModel from "@src/model/navModel";

const { TextArea } = Input

export function TransClientDrawer() {
    const { replaceNav } = useNavModel()
    const uploadRef = useRef<IUploadRef>()
    const {
        vehicleTypeDs,
        gearboxTypeDs,
        drivingTypeDs,
        fuelTypeDs,
        powerTypeDs,
        vehicleBrandDs,
        labelDs,
        engineDs,
        colorDs,
        emissionDs,
    } = useDsModel()
    const {
        clientVisible,
        setClientVisible,
        selectedRowData,
        loadData,
    } = usePVehicleResourceModle();
    const onCancel = () => {
        setClientVisible(false);
        loadData().then()
    }

    const formRef = useRef<IYhFormRef>();
    const [serviceGuarantee, setServiceGuarantee] = useState<string>("")
    const [serviceGuaranteeTranslate, setServiceGuaranteeTranslate] = useState<string>("")

    useEffect(() => {
        console.log("附件信息：", selectedRowData?.ShcFilemgr);
        if (selectedRowData) {
            uploadRef.current?.setAllFile({
                appReport: selectedRowData?.ShcFilemgr?.appReport,
                photo: selectedRowData?.ShcFilemgr?.photo?.split(','),
                registerInfo: selectedRowData?.ShcFilemgr?.registerInfo,
                video: selectedRowData?.ShcFilemgr?.video,
            })
        }
    }, [clientVisible, selectedRowData])


    const onOk = async () => {
        try {
            const formData = await formRef.current?.validateFields()
            const filesData = uploadRef.current?.getAllFile()
            const params: ISVehicleResourceFields = {
                ...formData,
                userType: "customer",
                registerTime: formData.registerTime ? moment(formData.registerTime).format("YYYY-MM-DD HH:mm:ss") : "",
                produceTime: formData.produceTime ? moment(formData.produceTime).format("YYYY-MM-DD HH:mm:ss") : "",
                scrapTime: formData.scrapTime ? moment(formData.scrapTime).format("YYYY-MM-DD HH:mm:ss") : "",
                firstLicenseTime: formData.firstLicenseTime ? moment(formData.firstLicenseTime).format("YYYY-MM-DD HH:mm:ss") : "",
                createUser: formData.createUser,
                serviceGuarantee: serviceGuaranteeTranslate,
                businessType: "sales",
                photo: filesData?.photo.join(","),
                registerInfo: filesData?.registerInfo,
                appReport: filesData?.appReport,
                video: filesData?.video,
                drivingLicense: selectedRowData?.ShcFilemgr?.drivingLicense
            }
            const { retData, success } = await sVehicleResourceService.insert(params)
            if (success) {
                globalPrompt('message', { text: '新增成功！', type: 'success' })
                setClientVisible(false);
                replaceNav("ucms-02-02", "ucms-03-01")
            }

        } catch (error) {
            console.log(error)
        }
    }
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        // disabled: true,
        // justify: "start",
        initialValues: {
            ...selectedRowData,
            registerTime: selectedRowData?.registerTime ? moment(selectedRowData.registerTime) : '',
            produceTime: selectedRowData?.produceTime ? moment(selectedRowData?.produceTime) : '',
            scrapTime: selectedRowData?.scrapTime ? moment(selectedRowData.scrapTime) : '',
            firstLicenseTime: selectedRowData?.firstLicenseTime ? moment(selectedRowData.firstLicenseTime) : '',
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "",
                hidden: true,
                fieldName: "createUser",
                ds: vehicleBrandDs,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆品牌",
                fieldName: "modelCode",
                ds: vehicleBrandDs,
                span: 12,
                rules: [
                    { required: true, message: "请选择车辆品牌" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                span: 12,
                rules: [
                    { required: true, message: "请输入车辆型号" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "typeCode",
                ds: vehicleTypeDs,
                span: 12,
                rules: [
                    { required: true, message: "请选择车辆类型" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "驱动形式",
                fieldName: "drivingFormCode",
                ds: drivingTypeDs,
                span: 12,
                rules: [
                    { required: true, message: "请选择驱动形式" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "马力",
                fieldName: "horsepowerName",
                // ds: powerTypeDs,
                span: 12,
                rules: [
                    { required: true, message: "请输入马力" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "排放标准",
                fieldName: "emissionStandardCode",
                ds: emissionDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "变速箱品牌",
                fieldName: "gearboxBrandCode",
                ds: gearboxTypeDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车身颜色",
                fieldName: "colorCode",
                ds: colorDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "出厂日期",
                showTime: false,
                format: "YYYY-MM-DD",
                fieldName: "produceTime",
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所在地",
                fieldName: "addressName",
                span: 12,
                rules: [
                    { required: true, message: "请选择所在地" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "价格",
                fieldName: "price",
                span: 12,
                rules: [
                    { required: true, message: "请输入价格" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是/否可过户",
                fieldName: "transferOwnershipFlag",
                ds: BooleanFlagDs,
                span: 12,
                rules: [
                    { required: true, message: "请选择是否" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "燃料种类",
                fieldName: "fuelTypeCode",
                ds: fuelTypeDs,
                span: 12,
                rules: [
                    { required: true, message: "请选择燃料种类" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "行驶里程",
                fieldName: "mileage",
                span: 12,
                rules: [
                    { required: true, message: "请输入行驶里程" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "发动机品牌",
                fieldName: "engineBrandCode",
                ds: engineDs,
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "排量",
                fieldName: "emission",
                span: 12,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "功率",
                fieldName: "power",
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "标签选择",
                fieldName: "labelCode",
                ds: labelDs,
                span: 12,
            }
        ]
    };

    const onTranslation = async () => {
        try {
            const { retData } = await pVehicleResourceService.translate(serviceGuarantee)
            console.log(retData.dst);
            setServiceGuaranteeTranslate(retData.dst)
        } catch (error) {

        }
    }
    return (
        <YHDrawer
            title="转客户车源"
            width={900}
            visible={clientVisible}
            onOk={onOk}
            onCancel={onCancel}
            showFooter={true}
            footerButs={[
                {
                    text: "在线翻译(英文)",
                    onClick: onTranslation
                }
            ]}
        >
            <p style={{ fontSize: '18px', marginLeft: '20px' }}>基本信息</p>
            <YhForm  {...formConfig}>
                <Col span="24">
                    <Form.Item
                        label="外轮廓尺寸"
                        name="size"
                        labelCol={{ span: 3 }}
                    >
                        <div className="flex">
                            <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                                <Input />
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Col>
            </YhForm>
            <p style={{ fontSize: '18px', marginLeft: '20px' }}>相关附件</p>
            <UploadFiles uploadRef={uploadRef} />
            <p style={{ fontSize: '18px', marginLeft: '20px' }}>服务保障</p>
            <Col span="24">
                <Form.Item
                    label="服务保障"
                    name="size"
                    labelCol={{ span: 3 }}
                >
                    <TextArea autoSize={{ minRows: 3, maxRows: 6 }} onChange={v => setServiceGuarantee(v.target.value)} />
                </Form.Item>
                <div style={{ margin: "0px 20px 20px 120px" }}>{serviceGuaranteeTranslate}</div>
            </Col>

        </YHDrawer>
    )
}