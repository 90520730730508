import { ITreeData } from "@src/components/YhFormBox/Types";
import { globalPrompt } from "@src/components/YhMessage";
import { EditModeType, ISearchPage, ISearchPageField, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { convertRes2Blob } from "@src/utils/exportFile";
import { transPgToPagination } from "@src/utils/utils";
import { dictService } from "@src/views/base/dict/DictService";
import { IUserFields } from "@src/views/login/LoginTypes";
import { IUserDetail, IUserSearch } from "@src/views/user/UserTypes";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import { useState } from "react";
import { purchaseSupplierService } from "../../user/UserService";

const useModel = () => {
  const initSearchParam: IUserFields = {};
  const initDataSource: IUserDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数
  //查询条件
  const [searchParam, setSearchParam] = useState<IUserFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<IUserDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IUserDetail>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [resourceVisible, setResourceVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")

  const [resetState, setResetState] = useState(false)

  const [vehicleTypeDs, setUserTypeDs] = useState<OptionDsType[]>()
  const [orgDs, setOrgDs] = useState<ITreeData[]>()


  const getDs = async () => {
    try {

      let vehicleDs = await dictService.oneTreeDs({ code: "vehicle-type" })
      setUserTypeDs(vehicleDs)
    } catch (error) {

    }
  }

  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: IUserFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }

  const loadData = async (page?: ISearchPage) => {
    const searchParams: IUserSearch = {
      ...searchParam,
      insertType: "supplier",
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await purchaseSupplierService.page(searchParams);
    setSelectedRowData(undefined)
    setSelectedRowKeys([])
    const { items, ...pg } = retData;
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item.id as string } }));
  }

  const exportSupplier = async () => {
    const result = await purchaseSupplierService.exportSupplier()
    convertRes2Blob(result)
  }


  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    drawerVisible,
    resetState,
    resourceVisible,
    editModel,
    vehicleTypeDs,
    orgDs,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setDrawerVisible,
    onSearch,
    onReset,
    setResourceVisible,
    setEditModel,
    getDs,
    exportSupplier,
  }
}

export const usePurchaseSupplierModle = createModel(useModel)