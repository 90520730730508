import React from "react";
import {ISearchField, ISearchProp} from "../Types";
import {TreeSelect} from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchTreeSelect = (props: PropType) => {
    return <TreeSelect
        allowClear
        className="ant-select-round"
        value={props.searchValues?.[props.fieldName]}
        placeholder={props.placeholder}
        onChange={(value) => {
            props.onChange?.({[props.fieldName]: value})
            if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled}
        treeData={props.treeDs}
    />
}
