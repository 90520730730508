import { IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { ISaleDemandDetail, ISaleDemandFields, ISaleDemandSearch } from "./DemandTypes";

class SaleDemandService {
  /**
   * 新增意向
   * @param params 
   * @returns 
   */
  async insert(params: ISaleDemandFields) {
    const url = "/shc2/carPurchention/insert";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 更新意向
   * @param params 
   * @returns 
   */
  async update(params: ISaleDemandFields) {
    const url = "/shc2/carPurchention/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 分页查询意向
   * @param params 
   * @returns 
   */
  async page(params: ISaleDemandSearch) {
    const url = "/shc2/carPurchention/search_page";
    return await httpRequest.post<IPage<ISaleDemandDetail>>(url, params);
  }

  /**
   * 查询单条意向
   * @param id
   * @returns 
   */
  async findOne(id: string) {
    const url = `/shc2/carPurchention/search_one/${id}`;
    return await httpRequest.post<ISaleDemandDetail>(url);
  }

  /**
   * 购车意向转订单
   * @param params
   * @returns 
   */
  async transOrder(params: ISaleDemandSearch) {
    const url = `/shc2/carPurchention/transOrder`;
    return await httpRequest.post<string>(url, params);
  }

  /**
   * 购车意向转供应商求购信息
   * @param params
   * @returns 
   */
  async transPurchase(params: ISaleDemandSearch) {
    const url = `/shc2/purret/insert`;
    return await httpRequest.post<string>(url, params);
  }

  /**
   * 导出
   * @returns 
   */
  async export() {
    const url = `/shc2/carPurchention/export`;
    return await httpRequest.getFile(url)
  }

}

export const saleDemandService = new SaleDemandService()