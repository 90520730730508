import React from 'react'
import { PageHeader } from "./PageHeader"
import { DataBox } from './DataBox'

export const SaleOrder = () => {
  return (
    <>
      <PageHeader />
      <DataBox />
    </>
  )
}