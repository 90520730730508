import React, { useEffect, useRef, useState } from 'react'
import { YhBox } from '@src/components/YhBox'
import { YhForm, YhFormBox } from '@src/components/YhFormBox'
import { RouterParamType } from "@src/model/navModel";
import { FormItemTypeEnum, ISelectOption, IYhForm, IYhFormBox, IYhFormItemsBox, IYhFormRef } from '@src/components/YhFormBox/Types'
import useNavModel from '@src/model/navModel'
import { globalPrompt } from "@src/components/YhMessage";
import { Button, Table, Steps, Input, Select, DatePicker, Form, message, Space } from 'antd'
import { IDetailOne, ISaleOrderDetail, ShcProcess, ShcVehicle } from '../orderTypes'
import { useSaleOrderModel } from '../orderModel'
import { ColumnsType } from "antd/es/table";
import { AddVehicleResourceModal } from "../drawer/AddVehicleResourceModel"
import { LookVehicleResourceModel } from '../drawer/LookVehicleResourceModel'
import { DetailType } from '@src/types/FmsEnums';
import moment from 'moment';
import { saleOrderService } from '../orderService';
import { DescriptionColorEnum, IDescription } from '@src/components/YhBox/Types';
import { Description } from '@src/components/YhBox/Description';
import { useDsModel } from '@src/model/dsModel';
import { PrincipalDs } from '@src/types/FmsDs';
import useGlobalModel from '@src/model/globalModel';
import { ShcContract } from '@src/views/purchase/order/orderTypes';
const { Step } = Steps
export const SaleOrderDetail = () => {
    const { user } = useGlobalModel();
    const [form] = Form.useForm();
    const { getSelectedNavState, selectedNavId, replaceNav, findNav } = useNavModel()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { currencyDs, tradeCountryDs, directorDs } = useDsModel()
    const formRef = useRef<IYhFormRef>();
    const modelFormRef = useRef<IYhFormRef>();
    const {
        setDrawerVisible,
        detailDetailList,
        setLookDrawerVisible,
        detailDataSource,
        detailLookList,
        setDetailDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        detailLookForm,
        getDetailEditOne,
        editDetail,
        resetEditCatch,
        contract,
        concelOrder,
        status,
        setStatus,
        setStep,
        step,
        getVehicleDetailOne,
        updatEditFormVal,
        setProcess,
        setContract,
        process
    } = useSaleOrderModel()
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && detailsInfo.mode === DetailType.edit) {
            getDetailEditOne(detailsInfo.id, detailsInfo.mode)
        }
        detailsInfo.mode === DetailType.look && getDetailEditOne(detailsInfo.id, detailsInfo.mode)
    }, [detailsInfo.id, detailsInfo.mode])

    useEffect(() => {
        return () => {
            !findNav("ucms-03-02-01") && resetEditCatch();
        }
    }, [selectedNavId])

    useEffect(() => {
        if (contract.length > 0) {
            form.setFieldsValue({ ShcContract: contract })
        }
    }, [contract])

    useEffect(() => {
        detailEditForm.contractPrice = contract.reduce((sum, e) => sum + Number(e.totalPrice ?? 0), 0);
        detailEditForm.purorderNumber = contract.reduce((sum, e) => sum + Number(e.number ?? 0), 0)
    }, [])
    const onChange = (changedValues: { [key: string]: any }, values?: any) => {
        updatEditFormVal(changedValues)
    }
    const baseFormConfig: IYhForm = {
        formRef: formRef,
        disabled: detailsInfo.mode === 'look' || status === 9,
        labelCol: { span: 8 },
        justify: 'start',
        initialValues: detailsInfo.mode === "edit" ? {
            ...detailEditForm,
            createTime: detailEditForm.createTime && moment(detailEditForm.createTime).format("YYYY-MM-DD HH:mm:ss"),
            bargainTime: detailEditForm.bargainTime && moment(detailEditForm.bargainTime),
            onChange
        } : {
            ...detailLookForm,
            createTime: detailLookForm.createTime && moment(detailLookForm.createTime).format("YYYY-MM-DD HH:mm:ss"),
            bargainTime: detailLookForm.bargainTime && moment(detailLookForm.bargainTime)
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "company",
                span: 6,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "姓名",
                fieldName: "userName",
                span: 6,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "合同编号",
                fieldName: "contractCode",
                span: 6,
                // rules: [
                //     { required: true, message: "请填写合同编号" }
                // ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "合同成交价",
                fieldName: "contractPrice",
                span: 6,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "贸易国别",
                fieldName: "countryName",
                span: 6,
                // ds: tradeCountryDs
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "tel",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售数量",
                fieldName: "purorderNumber",
                disable: true,
                span: 6,
            },
            {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "成交时间",
                fieldName: "bargainTime",
                format: "YYYY/MM/DD",
                span: 6,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "交易币种",
                fieldName: "currencyName",
                ds: currencyDs,
                span: 6,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "贸易条款",
                fieldName: "tradeClause",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付条款",
                fieldName: "payTerm",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发运港",
                fieldName: "shipmentPort",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "目的港",
                fieldName: "destinationPort",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                disable: true,
                fieldName: "createUser",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
                span: 6,
            }
        ]
    };

    const principalChange = (value: string, option: ISelectOption) => {
        option && onChange({ principalName: option.label, principalCode: option.value });
    }

    const modalFormItem: IYhForm = {
        formRef: modelFormRef,
        justify: 'start',
        initialValues: {
        },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "负责人",
                fieldName: "principalCode",
                labelCol: { span: 4 },
                ds: directorDs,
                // onSelectChange: principalChange,
                span: 24
            },
            {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "日期",
                fieldName: "dateTime",
                format: "YYYY.MM.DD",
                labelCol: { span: 4 },
                placeholder: '年/月/日',
                span: 24
            }
        ]
    }

    const goLook = (id: string) => {
        setLookDrawerVisible(true)
        getVehicleDetailOne(id)
    }

    const columns = [
        {
            title: '序号',
            align: 'center',
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: "分订单号",
            align: 'center',
            dataIndex: 'divideOrder',
            fixed: "left",
        }, {
            title: "车辆编号",
            align: 'center',
            dataIndex: 'vehicleCode',
            fixed: "left",
            render: (text: any, record: ShcVehicle) => <span onClick={() => goLook(record.id as string)}>{text}</span>
        }, {
            title: '车辆类型',
            align: 'center',
            dataIndex: 'typeName',
        }, {
            title: '车辆品牌',
            align: 'center',
            dataIndex: 'modelName',
        }, {
            title: '车辆型号',
            align: 'center',
            dataIndex: 'vehicleModel',
        }, {
            title: '出厂日期',
            align: 'center',
            dataIndex: 'produceTime',
            render: (v: any) => moment(v).format('YYYY-MM-DD')
        }, {
            title: '驱动形式',
            align: 'center',
            dataIndex: 'drivingFormName',
        }, {
            title: '马力',
            align: 'center',
            dataIndex: 'horsepowerName',
        }, {
            title: '燃料种类',
            align: 'center',
            dataIndex: 'fuelTypeName',
        }, {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            render: (v: any, r: any, i: any) => v === 1 ? '待售中'
                : v === 2 ? '已转接'
                    : '已售出'

        }, {
            title: '创建人',
            align: 'center',
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            render: (v: any) => moment(v).format('YYYY-MM-DD')
        }
    ];

    const orderStatus: IDescription[] = [
        {
            label: "订单编号",
            value: detailsInfo.mode === "edit" ? detailEditForm.code as string : detailLookForm.code as string ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        },
        {
            label: "状态",
            value: status === 0 ? "已开单" : status === 1 ? "已报价" : status === 2 ? "签合同" : status === 3 ? "已付款" : status === 4 ? "已报关" : status === 5 ? "已发运" : status === 6 ? "已清关" : status === 7 ? "已售出" : "已取消",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]
    // 提交编辑订单
    const onSave = () => {
        try {
            formRef.current?.validateFields().then(async res => {
                if (detailsInfo.mode === "edit") {
                    let { ShcContract, ShcProcess, ShcVehicle, ...form } = detailEditForm;
                    let stepArr = step.filter(item => !item.id && item.principalName);
                    let stepArr1: ShcProcess[] = step.filter(item => item.id);
                    let stepArr2: ShcProcess[] = []
                    stepArr2.push(stepArr1[stepArr1.length - 1])
                    if (stepArr.length === 0 && stepArr1.length === 0) {
                        stepArr2 = [{
                            createTime: detailEditForm.createTime,
                            createUser: detailEditForm.userName,
                            deleteFlag: false,
                            id: process[0].id,
                            orderId: detailsInfo.id,
                            orderNum: 0,
                            status: 0,
                            updateTime: detailEditForm.createTime,
                            updateUser: user.userName
                        }]
                    }
                    const resData = await editDetail({
                        ...res,
                        ShcContract: contract,
                        ShcVehicle: detailDataSource.map(item => ({ ...item, id: item.id ? item.id : item.key })),
                        ShcProcess: stepArr.length <= 0 ? stepArr2 : stepArr,
                        updateUser: user.userName,
                        orderNum: 0,
                        id: detailEditForm.id,
                        code: detailEditForm.code,
                        userType: "customer",
                        status: status
                    })
                    if (resData) {
                        globalPrompt("message", { text: "编辑成功", type: "success" });
                        resetEditCatch()
                        goBack()
                    } else {
                        globalPrompt("message", { text: "编辑失败", type: "error" })
                    }

                }
            })
        } catch (error) {
            console.log(error);
        }

    }
    const goBack = () => {
        replaceNav(selectedNavId, "ucms-03-02")
    }

    const onConcelOrder = (id: string) => {
        concelOrder(id).then(() => {
            getDetailEditOne(detailsInfo.id, detailsInfo.mode)
        })
    }
    const onCancel = () => {
        globalPrompt("modal", {
            width: 380,
            text: `销售订单${detailEditForm.code} ，是否确定取消？`,
            okText: "确定",
            cancelText: "取消",
            onCancel: () => {
            },
            onOk: () => { onConcelOrder(detailEditForm.id as string) }
        })
    }
    const Action = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode == "look" || status === 9 ? true : false}
                onClick={onSave}
            >保存</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode == "look" || status === 9 || status > 2 ? true : false}
                onClick={() => { onCancel() }}
            >取消订单</Button>
            <Button type="link" className="mr-5" shape="round" onClick={() => { replaceNav(selectedNavId, 'ucms-03-02') }}>返回</Button>
        </>
    )

    const add = () => {
        setDrawerVisible(true)
    }

    const rightAction = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode == "look" || status === 9 || status > 1 ? true : false}
                onClick={add}
            >新增</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailsInfo.mode == "look" || status === 9 || status === 7 ? true : false}
                onClick={() => { detailDetailList(detailsInfo.mode) }}
            >删除</Button>

        </>
    )

    const onContract = (item: any, index: number) => {
        globalPrompt("modal", {
            width: 480,
            text: <>
                <div style={{ marginBottom: '15px' }} >订单编号{detailEditForm.code},  是否完成{item.code ?? ""}？</div>
                <YhForm {...modalFormItem} ></YhForm>
            </>,
            okText: "确定",
            cancelText: "返回",
            onCancel: () => {
            },
            onOk: () => {
                const formData = modelFormRef.current?.getFieldsValue()
                setStatus(status + 1)
                let currentProcess = [...step]
                let value = currentProcess[status]
                currentProcess[status] = {
                    ...value,
                    principalCode: formData.principalCode,
                    principalName: directorDs?.find(item => item.value === formData.principalCode)?.label,
                    dateTime: formData.dateTime && moment(formData.dateTime),
                    orderId: detailsInfo.id as string,
                    orderNum: 0,
                    updateUser: user.userName
                }
                setStep(currentProcess)
                const updateProcess: ShcProcess[] = []
                updateProcess.push(currentProcess[status])
                setProcess(updateProcess)
            }
        })
    }

    /**
 * 修改订单总价
 * @param index 索引
 * @param value 单价
 */
    const onChangePrice = (index: number, value: number) => {
        const newArr = contract.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    price: value,
                    totalPrice: Number(item.number) * value,
                }
            }
            return item
        })
        setContract(newArr)
    }

    const onContractChange = (value: { ShcContract: ShcContract[] }, val: { ShcContract: ShcContract[] }) => {
        console.log(value, val)
        let saleNum = 0;  // 销售数量
        let amountTotal = 0  // 合同成交价
        val.ShcContract.forEach(item => {
            saleNum += +(item.number ?? 0);
            amountTotal += +((item.price ?? 0) * (item.number ?? 0));
        })
        updatEditFormVal({ contractPrice: amountTotal, purorderNumber: saleNum })
    }

    useEffect(() => {
        console.log(step)
        form.setFieldsValue({
            ShcContract: contract
        })
    }, [contract])

    return (
        <div>
            <YhBox title={<>
                {orderStatus.map((item, index) => <Description key={index} {...item} />)}
            </>} action={Action} />

            {/* <YhFormBox {...baseFromConfig} /> */}
            <YhBox title="基础信息" style={{ marginTop: '15px', padding: "20px" }}>
                <YhForm {...baseFormConfig} >
                </YhForm>
            </YhBox>
            <YhBox title="销售进度条" style={{ marginTop: '15px', padding: "20px" }}>
                <Steps size="small" current={status < 8 ? status - 1 : -1} style={{ width: '1300px' }}>{
                    step.map((item, index) => {
                        return <Step title={<span onClick={status === index && detailsInfo.mode === "edit" ? () => onContract(item, index) : undefined}>{item.name}</span>} description={`${item.principalName} ${item.dateTime && moment(item.dateTime).format("YYYY.MM.DD")}`} />
                    })
                }
                </Steps>
            </YhBox>
            <YhBox title="车辆信息" rightChildren={rightAction} style={{ marginTop: '15px', padding: "20px" }}>
                <Table
                    columns={columns as ColumnsType<ShcVehicle>}
                    rowSelection={{
                        selectedRowKeys: detailDataRowKeys.map(item => item.key ?? ""), //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleOrderDetail[]) => {
                            console.log(selectedRows)
                            setDetailDataRowKeys(selectedRows);
                        }
                    }}
                    dataSource={detailsInfo.mode === "edit" ? detailDataSource : detailLookList}
                    pagination={false}
                    onRow={record => ({
                        onClick: event => {
                            if (detailDataRowKeys.length > 0 && detailDataRowKeys[0] === record.id) {
                                setDetailDataRowKeys([]);
                            } else {
                                setDetailDataRowKeys([record]);
                            }
                        },
                    })}
                />
            </YhBox>
            <YhBox title="合同信息" style={{ marginTop: '15px', padding: "20px" }}>
                <Form form={form}
                    onValuesChange={(changedValues: any, values: any) => {
                        onContractChange(changedValues, values)
                    }}
                    initialValues={
                        [...contract]

                    } >
                    <Form.List name="ShcContract" >
                        {(arr) => {
                            return (
                                <>
                                    {arr.map((item, index) => (
                                        <Space key={item.key} style={{ display: 'flex', justifyContent: 'space-around' }} align="baseline">
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'modelName']}
                                                fieldKey={[item.fieldKey, 'modelName']}
                                                label="车辆品牌"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}

                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'vehicleModel']}
                                                fieldKey={[item.fieldKey, 'vehicleModel']}
                                                label="车辆型号"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}

                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'number']}
                                                fieldKey={[item.fieldKey, 'number']}
                                                label="数量"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'price']}
                                                fieldKey={[item.fieldKey, 'price']}
                                                label="单价"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    onChange={e => onChangePrice(index, +e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={[item.name, 'totalPrice']}
                                                fieldKey={[item.fieldKey, 'totalPrice']}
                                                label="总价"
                                            >
                                                <Input
                                                    style={{ width: 200 }}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={'id'}
                                                fieldKey={'id'}
                                                label="id"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={'updateUser'}
                                                fieldKey={'updateUser'}
                                                label="更新者"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...item}
                                                name={'orderNum'}
                                                fieldKey={'orderNum'}
                                                label="orderNum"
                                                hidden={true}
                                            >
                                                <Input
                                                    style={{ width: 260 }}
                                                />
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )
                        }}

                    </Form.List>
                </Form>
            </YhBox>
            <AddVehicleResourceModal />
            <LookVehicleResourceModel />
        </div>
    )
}