import React, { useEffect } from 'react'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'
import { TransOrderDrawer } from '../drawer/TransOrderDrawer'
import { useSVehicleResourceModle } from '../vehicleResourceModel'

export const SaleVehicleResource = () => {
  const { resetState, loadData, initCustomerDs } = useSVehicleResourceModle()
  useEffect(() => {
    loadData().then()
    initCustomerDs().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
    <TransOrderDrawer />
  </>
}