import { httpRequest } from '@src/utils/HttpRequest';
import { IPage } from '@src/types/FmsTypes';
import {
	ISaleOrderDetail,
	ISaleOrderSearch,
	ISaleOrderFields,
	IInvalidate,
	IDetailOne,
	IDelete,
	ISaleOrderVehicleDetail,
} from './orderTypes';

class SaleOrderService {
	async page(searchVo: ISaleOrderSearch) {
		const apiUrl = '/shc2/order/search_page';
		return await httpRequest.post<IPage<ISaleOrderFields>>(apiUrl, searchVo);
	}

	async one(id: string) {
		const apiUrl = `/shc2/order/search_one/${id}`;
		return await httpRequest.post<IDetailOne>(apiUrl);
	}

	async update(updateVo: ISaleOrderFields) {
		const apiUrl = '/shc2/order/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}

	// 进度条新增
	async insert(insertVo: ISaleOrderFields) {
		const apiUrl = '/shc2/schedule/schedule/insert';
		return await httpRequest.post<boolean>(apiUrl, insertVo);
	}

	// 取消订单
	async remove(params: IInvalidate) {
		const apiUrl = '/shc2/order/remove';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 采购订单新增
	async orderInsert(params: ISaleOrderFields) {
		const apiUrl = '/shc2/order/schedule/insert';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	// 明细删除
	async delete(params: IDelete) {
		const apiUrl = 'shc2/vehicle/delete';
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	async findOne(id: string) {
		const url = `/shc2/sales/search_one/${id}`;
		return await httpRequest.post<ISaleOrderVehicleDetail>(url);
	}
}

export const saleOrderService = new SaleOrderService();
