import React from "react";
import "./drawer.less";


type FooterButtons = {
    text: string,
    style?: React.CSSProperties,
    onClick?: () => void
}

interface DrawerProps {
    visible: boolean,
    title?: string,
    closable?: boolean,
    mask?: boolean,
    maskClosable?: boolean,
    width?: number,
    children?: React.ReactNode,
    showFooter?: boolean,
    okText?: string
    onOk?: () => void,
    cancelText?: string
    onCancel?: () => void,
    footerButs?: FooterButtons[]
}

export const YHDrawer = ({
    visible,
    title = "标题",
    onCancel = () => null,
    maskClosable = true,
    mask = false,
    width = 474,
    closable = true,
    children = null,
    showFooter = true,
    okText = "确定",
    onOk,
    cancelText = "取消",
    footerButs

}: DrawerProps): JSX.Element => {

    const handleClose = () => {
        onCancel?.();
    }

    return <div className="drawer" data-visible={visible} style={{ width: visible ? "100%" : "0%" }}>
        <div className={["drawer-mask-warp", (mask && visible) ? 'drawer-mask-show' : "drawer-mask-hide"].join(' ')}
            onClick={() => {
                maskClosable && handleClose()
            }}>
        </div>
        <div className={["drawer-main", visible ? "drawer-main-show" : "drawer-main-hide"].join(' ')}
            style={{ width: `${width}px`, right: visible ? "0" : `-${width}px`, }}>
            <div className="drawer-head">
                <span>{title}</span>
                {closable && <span className="close-btn" onClick={handleClose}>X</span>}
            </div>
            <div className="drawer-body">
                {
                    visible ? children : null
                }
            </div>
            <div className="drawer-footer" hidden={!showFooter}>
                <span onClick={() => {
                    onOk?.();
                }} className="submit-button">{okText}</span>
                <span onClick={handleClose}>{cancelText}</span>
                {footerButs && footerButs.map((item, index) => (<span key={index} style={item.style} onClick={item.onClick}>{item.text}</span>))}
            </div>
        </div>
    </div>
}