
export enum DescriptionColorEnum {
    'red' = 'red',
    'green' = 'green',
    'blue' = 'blue'
}

export interface IDescription {
    label: string | JSX.Element;
    value: number | string | JSX.Element | (()=>(void));
    color: DescriptionColorEnum;
    status?: boolean
}


export interface IYhBox {
    title?: string | JSX.Element;
    descriptions?: IDescription[];
    action?: JSX.Element;
    searchBox?: JSX.Element;
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    style?: React.CSSProperties;
    rightChildren?: JSX.Element;
    contentClass?: string;
    hidden?: boolean
}