import React, { useState, useEffect, useRef, Key } from 'react';
import { ITreeData, IYhFormRef } from '@src/components/YhFormBox/Types';
import { createModel } from 'hox';
import { message } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { IPgProps, ISearchPage } from '@src/types/FmsTypes';
import {
	IPVehicleResourceSearch,
	IPVehicleResourceFields,
} from '../vehicleResource/VehicleResourceTypes';
import { pVehicleResourceService } from '../vehicleResource/VehicleResourceService';
import { globalPrompt } from '@src/components/YhMessage';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@src/utils/constants';
import { transPgToPagination } from '@src/utils/utils';
import {
	IPurchaseOrderSearch,
	IPurchaseOrderDetail,
	IPurchaseOrderFields,
	// IDetailOne,
	IDetailOne,
	ShcVehicle,
	ShcProcess,
	ShcContract,
	IPVehicleDetail,
	IDeletes,
} from './orderTypes';
import { purchaseOrderService } from './orderService';
import moment from 'moment';
import useGlobalModel from '@src/model/globalModel';
import { saleOrderService } from '@src/views/sale/order/orderService';

export const usePurchaseOrderModel = createModel(function () {
	let purchaseSteps: ShcProcess[] = [
		{ status: 1, code: '已报价', name: '报价', principal: '', userCode: '', dateTime: '', id: '' },
		{
			status: 2,
			code: '签合同',
			name: '签合同',
			principal: '',
			userCode: '',
			dateTime: '',
			id: '',
		},
		{ status: 3, code: '已付款', name: '付款', principal: '', userCode: '', dateTime: '', id: '' },
		{ status: 4, code: '已过户', name: '过户', principal: '', userCode: '', dateTime: '', id: '' },
		{
			status: 5,
			code: '已售出',
			name: '完成交易',
			principal: '',
			userCode: '',
			dateTime: '',
			id: '',
		},
	];
	const initSearchParam: IPurchaseOrderFields = {};
	// 新增车源信息的初始搜索条件
	const initPRSearchParam: IPVehicleResourceFields = {};
	const initDataSource: IPurchaseOrderDetail[] = [];
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const { user } = useGlobalModel();
	const formRef = useRef<IYhFormRef>();
	//查询条件
	const [searchParam, setSearchParam] = useState<IPurchaseOrderFields>(initSearchParam);
	//列表数据源
	const [dataSource, setDataSource] = useState<IPurchaseOrderDetail[]>(initDataSource);
	//表格选中行Key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	//表格选中的值
	const [selectedRow, setSelectedRow] = useState<IPurchaseOrderDetail>({});
	//列表数据源分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//新增车源信息弹框
	const [drawerVisible, setDrawerVisible] = useState(false);
	// 车源查看弹框
	const [lookDrawerVisible, setLookDrawerVisible] = useState(false);

	//查看数据
	//查看详情form
	const [detailLookForm, setDetailLookForm] = useState<IPurchaseOrderFields>({});
	//查看详情列表
	const [detailLookList, setDetailLookList] = useState<IDetailOne[]>([]);

	//详情数据
	//详情列表参数
	const [detailDataSource, setDetailDataSource] = useState<ShcVehicle[]>([]);
	//详情表格选中行
	const [detailDataRowKeys, setDetailDataRowKeys] = useState<IPurchaseOrderDetail[]>([]);
	const [detailDataRow, setDetailDataRow] = useState<ShcVehicle[]>([]);
	const [detailEditForm, setDetailEditForm] = useState<IDetailOne>({});

	// 车源信息列表配置
	// 数据源
	const [workDataSource, setWorkDataSource] = useState<IPVehicleResourceFields[]>([]);
	// 搜索条件
	const [searchWorkData, setSearchWorkData] = useState<IPVehicleResourceFields>(initPRSearchParam);
	// 页码
	const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//已选车源列表
	const [addDataSource, setAddDataSource] = useState<IPVehicleResourceFields[]>([]);

	// 新增变量控制进度条
	const [steps, setSteps] = useState<ShcProcess[]>([]);
	// 合同内容
	const [contract, setContract] = useState<ShcContract[]>([]);
	const [process, setProcess] = useState<ShcProcess[]>([]);
	const [status, setStatus] = useState(0);
	const [step, setStep] = useState<ShcProcess[]>(purchaseSteps);
	const [lookFormVal, setLookFormVal] = useState<IPVehicleDetail>({});

	useEffect(() => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectedRow(selectedData as IPurchaseOrderFields);
	}, [selectedRowKeys]);

	const updatePgCfg = (updateParam: TablePaginationConfig) => {
		setPgCfg({ ...pgCfg, ...updateParam });
	};
	const updateSearchParam = (updateParam: IPurchaseOrderFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};
	const resetSearchParam = () => {
		setSearchParam(initSearchParam);
		loadData(initPgCfg, {});
		setSelectedRowKeys([]);
		setSelectedRow({});
	};

	// 订单数据源
	const loadData = async (page?: ISearchPage, searchValue: IPurchaseOrderDetail = searchParam) => {
		const searchParams: IPurchaseOrderSearch = {
			...searchValue,
			insertType: 'source',
			pg: {
				orders: [
					{
						rule: 'ASC',
						column: 'createTime',
					},
				],
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await purchaseOrderService.page(searchParams);
		const { items, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(items);
	};

	// 编辑表单缓存
	const updateEditFormVal = (val: IPurchaseOrderFields) => {
		let newVal = val;
		setDetailEditForm({ ...detailEditForm, ...newVal });
	};

	//获取一条编辑或查看数据
	const getDetailEditOne = async (id: string, pattern: string) => {
		let { retData } = await purchaseOrderService.one(id);
		const { ShcProcess, ShcVehicle, ShcContract, createTime, ...form } = retData;
		const vehicleDetails = ShcVehicle?.map((vehicle) => {
			return {
				...vehicle,
				key: vehicle.id,
			};
		});
		let stepsArr = [...purchaseSteps];
		const processDetails: ShcProcess[] = [...step];
		// 使用后台返回的进度替换初始step值
		ShcProcess?.forEach((process) => {
			let { status } = process;
			if (status !== 0) {
				let findIndex = processDetails.findIndex((item) => item.status === process.status);
				if (findIndex !== -1) {
					processDetails.splice(findIndex, 1, process);
				}
			}
		});
		const processArr = ShcProcess?.map((item) => {
			return {
				...process,
			};
		});
		const arr2 = processDetails.filter((item) => item.id);
		console.log(arr2);
		const contractDetails = ShcContract?.map((contract) => {
			let { modelName, number, price, totalPrice, id, updateUser, orderNum, vehicleModel } =
				contract;
			return {
				...contract,
				modelName,
				number,
				price,
				totalPrice,
				id,
				updateUser,
				orderNum,
				vehicleModel,
			};
		});
		let arr1: any = ShcProcess?.map((item) => {
			return item.status;
		});
		let maxStatus = Math.max.apply(null, arr1);
		let ShcProcessStatus = ShcProcess && maxStatus;
		console.log('进度', ShcProcess);
		setProcess(ShcProcess as ShcProcess[]);
		if (retData && pattern == 'edit') {
			setDetailDataSource((vehicleDetails as IDetailOne[]) ?? []);
			setContract((contractDetails as ShcContract[]) ?? []);
			setSteps((processDetails as ShcProcess[]) ?? []);
			setStep(processDetails);
			setDetailEditForm({
				...form,
				createTime: moment(createTime).format('YYYY-MM-DD HH:mm:ss'),
			});
			setStatus(ShcProcessStatus as number);
			setDetailDataRow((vehicleDetails as IDetailOne[]) ?? []);
			// setProcess(arr2);
		} else if (retData && pattern == 'look') {
			setDetailLookForm({
				...form,
				createTime: moment(createTime).format('YYYY-MM-DD HH:mm:ss'),
			});
			setDetailLookList((vehicleDetails as IDetailOne[]) ?? []);
			setContract((contractDetails as ShcContract[]) ?? []);
			setSteps((processDetails as ShcProcess[]) ?? []);
			setStep(processDetails);
			setStatus(ShcProcessStatus as number);
			setDetailDataRow((vehicleDetails as IDetailOne[]) ?? []);
			// setProcess(arr2);
		}
	};

	// 获取一条车辆信息查看
	const getVehicleDetailOne = async (id: string) => {
		let { retData } = await purchaseOrderService.findOne(id);
		let { ...form } = retData;
		if (retData) {
			setLookFormVal(form);
		} else {
			globalPrompt('message', { type: 'success', text: '操作失败请重试!' });
		}
	};

	// 取消订单
	const concelOrder = async (id: string, mode: string) => {
		const params = {
			id,
		};
		const { retData } = await purchaseOrderService.remove(params);
		if (retData) {
			globalPrompt('message', { type: 'success', text: '取消成功!' });
			let statusLen = 0;
			if (mode === 'edit') {
				return statusLen - 1;
			} else {
				return statusLen - 2;
			}
		} else {
			globalPrompt('message', { type: 'error', text: '取消失败!' });
		}
	};
	//关闭弹框
	const resetWorkloadData = async () => {
		setSearchWorkData(initPRSearchParam);
		workloadData(initPgCfg, {});
	};

	//加载车源数据(弹框)
	const workloadData = async (
		page?: ISearchPage,
		searchValue: IPVehicleResourceFields = searchWorkData
	) => {
		const searchParams: IPVehicleResourceSearch = {
			...searchValue,
			status: 1,
			pg: {
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await pVehicleResourceService.page(searchParams);
		const { items, ...pg } = retData;
		setWorkPgCfg(transPgToPagination(pg));
		setWorkDataSource(items);
	};

	//筛选模块详情
	const modelScreenMethod = async (
		modelList: IPVehicleResourceFields[],
		detailList: ShcVehicle[]
	) => {
		const repetition: string[] = [];
		const newDetailData: IDetailOne[] = [];
		modelList.forEach((Item) => {
			let isSelect = detailList && detailList.find((item) => item.vehicleCode === Item.vehicleCode);
			if (isSelect === undefined) {
				newDetailData.push({
					...Item,
					key: Item.id,
					id: '',
				});
			} else {
				repetition.push(Item.vehicleCode as string);
			}
		});
		//新增的时候添加合同信息
		let contractArr = [...contract];
		console.log(newDetailData);
		newDetailData.forEach((item) => {
			let isHave = contract.find(
				(origin) => item.modelCode === origin.modelCode && item.vehicleModel === origin.vehicleModel
			);
			if (isHave) {
				let num = +(isHave.number ?? 0) + 1;
				let price = +(isHave.price ?? 0);
				isHave.number = num;
				isHave.totalPrice = num * price;
				let findIndex = contractArr.findIndex((item) => item.id === isHave?.id);
				contractArr.splice(findIndex, 1, isHave);
			} else {
				contractArr.push({
					modelCode: item.modelCode,
					modelName: item.modelName,
					vehicleModel: item.vehicleModel,
					number: 1,
					price: 0,
					totalPrice: 0,
					id: item.id ?? '',
				});
			}
		});
		setContract(contractArr);
		return { newDetailData, repetition };
	};

	//添加到详情列表
	const transformsToPoDetailList = async (mode: string) => {
		//详情列表
		if (mode === 'edit') {
			const { repetition, newDetailData } = await modelScreenMethod(
				addDataSource,
				detailDataSource
			);
			setDetailDataSource([...detailDataSource, ...newDetailData]);
			repetition.length != 0 &&
				globalPrompt('message', {
					text: `车源信息${repetition.join(',')}，重复的将不在添加。`,
					type: 'warning',
				});
		}
	};
	//删除详情列表数据
	const detailDetailList = async (mode: string) => {
		//删除商品
		if (!detailDataRowKeys.length) {
			message.warning('请选择你要删除的商品');
			return;
		}
		let contractArr1 = [...contract];
		let deleteArr: IDeletes = {
			ShcVehicle:
				detailDataRowKeys.filter((item) => item.id).map((item) => ({ id: item.id ?? '' })) ?? [],
			ShcContract:
				contractArr1.filter((item) => item.id).map((item) => ({ id: item.id ?? '' })) ?? [],
		};

		if (deleteArr.ShcVehicle.length) {
			await purchaseOrderService.delete(deleteArr);
		}
		let filterArr: string[] = detailDataRowKeys.map((item) => item.key ?? '');
		setDetailDataSource((origin) =>
			origin.filter((item) => filterArr.indexOf(item.key as string) < 0)
		);
		setSelectedRowKeys([]);
		//删除车辆信息时候删除合同信息
		let contractArr = [...contract];

		detailDataRowKeys.forEach((item) => {
			let isHave = contract.find(
				(origin) => item.modelCode === origin.modelCode && item.vehicleModel === origin.vehicleModel
			);
			if (isHave) {
				let findIndex = contractArr.findIndex((item) => item.id === isHave?.id);
				if (+(isHave.number ?? 0) > 1) {
					isHave.number = +(isHave.number ?? 0) - 1;
					contractArr.splice(findIndex, 1, isHave);
					return;
				}
				contractArr.splice(findIndex, 1);
			}
		});
		setContract(contractArr);
		message.success('删除成功');
	};
	//编辑采购订单
	const editDetail = async (params: IDetailOne) => {
		let newParams = {
			...params,
		};
		let { retData } = await purchaseOrderService.update(newParams);
		return retData;
	};

	//关闭页签清不同状态缓存
	const resetEditCatch = async () => {
		setDetailEditForm({});
		setDetailDataSource([]);
		setProcess([]);
		setContract([]);
		setStep(purchaseSteps);
	};

	return {
		formRef,
		initPgCfg,
		searchParam,
		setSearchParam,
		dataSource,
		setDataSource,
		selectedRowKeys,
		setSelectedRowKeys,
		selectedRow,
		setSelectedRow,
		pgCfg,
		setPgCfg,
		drawerVisible,
		setDrawerVisible,
		lookDrawerVisible,
		setLookDrawerVisible,
		updatePgCfg,
		updateSearchParam,
		resetSearchParam,
		loadData,
		detailLookForm,
		setDetailLookForm,
		detailLookList,
		setDetailLookList,
		detailDataSource,
		setDetailDataSource,
		detailDataRowKeys,
		setDetailDataRowKeys,
		detailDataRow,
		setDetailDataRow,
		detailEditForm,
		setDetailEditForm,
		workDataSource,
		setWorkDataSource,
		searchWorkData,
		setSearchWorkData,
		workPgCfg,
		setWorkPgCfg,
		addDataSource,
		setAddDataSource,
		getDetailEditOne,
		resetWorkloadData,
		workloadData,
		transformsToPoDetailList,
		detailDetailList,
		editDetail,
		resetEditCatch,
		steps,
		setSteps,
		contract,
		setContract,
		getVehicleDetailOne,
		updateEditFormVal,
		lookFormVal,
		setLookFormVal,
		concelOrder,
		status,
		setStatus,
		process,
		setProcess,
		step,
		setStep,
	};
});
