import React from 'react'
import { DataBox } from './DataBox'
import { PageHeader } from "./PageHeader"
export const AboutUs = () => {
  return (
    <>
      <PageHeader />
      <DataBox />
    </>
  )
}