import { ITreeData, IYhFormRef } from "@src/components/YhFormBox/Types";
import { globalPrompt } from "@src/components/YhMessage";
import { EditModeType, ISearchPage, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { convertRes2Blob } from "@src/utils/exportFile";
import { transPgToPagination } from "@src/utils/utils";
import { dictService } from "@src/views/base/dict/DictService";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import moment from "moment";
import { useRef, useState } from "react";
import { pVehicleResourceService } from "./VehicleResourceService";
import { IPVehicleResourceDetail, IPVehicleResourceFields, IPVehicleResourceSearch } from "./VehicleResourceTypes";

const useModel = () => {
  const initSearchParam: IPVehicleResourceFields = {};
  const initDataSource: IPVehicleResourceDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数
  const formRef = useRef<IYhFormRef>();
  const baseFormRef = useRef<IYhFormRef>();
  //查询条件
  const [searchParam, setSearchParam] = useState<IPVehicleResourceFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<IPVehicleResourceDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IPVehicleResourceDetail>();
  const [selectedRow, setSelectedRow] = useState<IPVehicleResourceDetail[]>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [orderVisible, setOrderVisible] = useState(false);
  const [clientVisible, setClientVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")

  const [resetState, setResetState] = useState(false)

  const [pVehicleResourceTypeDs, setPVehicleResourceTypeDs] = useState<OptionDsType[]>()
  const [orgDs, setOrgDs] = useState<ITreeData[]>()


  const getDs = async () => {
    try {

      let pVehicleResourceDs = await dictService.oneTreeDs({ code: "pVehicleResource-type" })
      setPVehicleResourceTypeDs(pVehicleResourceDs)
    } catch (error) {

    }
  }

  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: IPVehicleResourceFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }

  const loadData = async (page?: ISearchPage) => {
    const searchParams: IPVehicleResourceSearch = {
      ...searchParam,
      createTime: searchParam.createTime ? moment(searchParam.createTime).format('YYYY-MM-DD') : '',
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await pVehicleResourceService.page(searchParams);
    setSelectedRowData(undefined)
    setSelectedRowKeys([])
    const { items, ...pg } = retData;
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item.id as string } }));
  }

  const loadOneData = async (id?: string) => {
    let { retData } = await pVehicleResourceService.findOne(id ?? selectedRowKeys[0] as string);
    setSelectedRowData(retData)
  }

  // const importVehicleResource = async () => {
  //   const result = await 
  // }
  /**
   * 导出车源信息
   */
  const exportVehicleResource = async () => {
    const result = await pVehicleResourceService.export()
    convertRes2Blob(result)
  }


  // const handleChangeStatus = async (r: IPurchaseDemandFields, v: boolean) => {
  //   try {
  //     const { retData } = await pVehicleResourceService.enable(r.id as string, v ? '1' : '0')
  //     if (retData) {
  //       globalPrompt('message', { text: '修改成功', type: 'success' })
  //       loadData()
  //     } else {
  //       globalPrompt('message', { text: '修改失败', type: 'error' })
  //     }
  //   } catch (error) {

  //   }
  // }

  /**
   * 删除
   */
  const handleRemove = () => {
    globalPrompt("modal", {
      type: "warning",
      title: "正在进行删除操作",
      text: "是否确认删除",
      onOk: async () => {
        try {
          const { retData } = await pVehicleResourceService.remove(selectedRowKeys[0] as string)
          if (retData) {
            globalPrompt('message', { text: '删除成功', type: 'success' })
            loadData()
          } else {
            globalPrompt('message', { text: '删除失败', type: 'error' })
          }
        } catch (error) {

        }
      }
    })
  }

  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    orderVisible,
    resetState,
    clientVisible,
    editModel,
    pVehicleResourceTypeDs,
    orgDs,
    formRef,
    baseFormRef,
    selectedRow,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setOrderVisible,
    onSearch,
    onReset,
    setClientVisible,
    setEditModel,
    getDs,
    exportVehicleResource,
    handleRemove,
    setSelectedRow,
    loadOneData,
  }
}

export const usePVehicleResourceModle = createModel(useModel)