import React, { useEffect } from 'react'
import { usePurchaseSupplierModle } from '../supplierModel'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'

export const PurchaseSupplier = () => {
  const { resetState, loadData } = usePurchaseSupplierModle()
  useEffect(() => {
    loadData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
  </>
}