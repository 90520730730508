import React, { FC } from "react";
import { IDescription } from "./Types";


export const Description: FC<IDescription> = (props) => {
    return (
        <>
            {
                props.status ?
                    <span className="list-header-content-statu-item">
                        {props.label}：
                        <span className={'list-header-content-statu statu-' + props.color}>{props.value}</span>
                    </span> :
                    <span className="list-header-content-item">
                        {props.label}：
                        <span className={'list-header-content-money money-' + props.color}>{props.value}</span>
                    </span>
            }
        </>
    )
}

