import React, { FC } from "react";
import "./style.less";
import FontIcon from "../YhIcon";

const TypeColors = {
    blue: '#1296DB',
    green: '#028E19',
}

export type IYhButtonProps = {
    typeName?: string;
    style?: React.CSSProperties;
    type?: 'blue' | 'green';
    text: string;
    click: () => void;
    disabled?: boolean;
    children?: JSX.Element;
}

export const YhBut: FC<IYhButtonProps> = ({
    typeName,
    type,
    text,
    style,
    click,
    disabled = false,
    children
}) => {


    return <div className={`yh-but ${disabled ? 'yh-but-disabled' : ''}`} onClick={() => { !disabled && click() }}>
        <span className="yh-but-icon" style={{ background: disabled ? "#919292" : type ? TypeColors[type] : "#fff" }}>
            {
                children ?? <FontIcon type={typeName ?? 'icon-shezhi'} style={style ?? { color: "#fff", fontSize: "14px" }} />
            }
        </span>
        <span className="yh-but-txt">{text}</span>
    </div>
}