import { FormInstance, Rule } from "antd/es/form";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ColProps } from "antd/lib/grid/col";
import { FormLabelAlign } from "antd/lib/form/interface";
import { IYhBox } from "../YhBox/Types";
import { Key, MutableRefObject } from "react";
import { NamePath } from "rc-field-form/es/interface";


export enum FormItemTypeEnum {
    Input,
    InputNumber,
    Select,
    Datepicker,
    Switch,
    JsonView,
    TreeSelect,
    Password,
    TextArea,
    Upload,
    FileImage,
    Cascader
}

export interface ITreeData {
    key?: Key,
    value?: string,
    title?: string,
    disabled?: boolean,
    selectable?: boolean
    children?: ITreeData[]
}

export interface ISelectOption {
    label: string,
    value: string | number
}
export interface ICascaderOption {
    value: string;
    label: string
    children?: ICascaderOption[];
}
export interface IFormItem extends ColProps {
    type: FormItemTypeEnum;
    mode?: "multiple" | "tags";
    fieldLabel: string;
    fieldName: string;
    placeholder?: string;
    ds?: ISelectOption[];
    treeDs?: ITreeData[];
    cds?: ICascaderOption[]
    labelCol?: ColProps,
    hidden?: boolean;
    allowClear?: boolean;
    size?: SizeType;
    disable?: boolean;
    multiple?: boolean;
    labelInValue?: boolean,
    rules?: Rule[],
    span?: number,
    onChange?: (value: any, ...props: any) => void,
    [key: string]: any,
}

export interface IYhFormItemsBox extends IYhBox {
    disabled?: boolean;
    items: IFormItem[];
    justify?: "start" | "end" | "center" | "space-around" | "space-between";
    IclassName?: string;
}

export interface IYhFormBox<IValues = any> {
    formRef?: MutableRefObject<IYhFormRef | undefined>,
    colon?: boolean;
    name?: string;
    labelCol?: ColProps;
    labelAlign?: FormLabelAlign;
    onChange?: (changedValues: { [key: string]: any }, values?: IValues) => void;
    onFinish?: (values: IValues) => void
    onReset?: () => {};
    formValues?: {};
    disabled?: boolean;
    boxs: IYhFormItemsBox[];
}

export interface IYhFormRef<T = any> {
    validateFields: () => Promise<T>,
    getFieldsValue: () => T,
    setFieldsValue: (changedValues: { [key: string]: any }) => void;
    resetFields: (fields?: NamePath[]) => void,
    inst: () => FormInstance,
}

export interface IYhForm<T = any> {
    formRef?: MutableRefObject<IYhFormRef | undefined>,
    colon?: boolean;
    name?: string;
    labelCol?: ColProps;
    labelAlign?: FormLabelAlign;
    onChange?: (changedValues: { [key: string]: any }, values?: T) => void;
    onFinish?: (values: T) => void
    onReset?: () => {};
    initialValues?: any;
    formValues?: any;
    disabled?: boolean;
    items: IFormItem[];
    justify?: "start" | "end" | "center" | "space-around" | "space-between",
}
