import { ITreeData, IYhFormRef } from "@src/components/YhFormBox/Types";
import { globalPrompt } from "@src/components/YhMessage";
import { EditModeType, ISearchPage, ISearchPageField, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { convertRes2Blob } from "@src/utils/exportFile";
import { transPgToPagination } from "@src/utils/utils";
import { dictService } from "@src/views/base/dict/DictService";
import { vehicleService } from "@src/views/base/vehicle/VehicleService";
import { saleClientService } from "@src/views/user/UserService";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import { useRef, useState } from "react";
import { sVehicleResourceService } from "./VehicleResourceService";
import { ISVehicleResourceDetail, ISVehicleResourceFields } from "./VehicleResourceTypes";

const useModel = () => {
  const initSearchParam: ISVehicleResourceFields = {};
  const initDataSource: ISVehicleResourceDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数
  const formRef = useRef<IYhFormRef>();
  //查询条件
  const [searchParam, setSearchParam] = useState<ISVehicleResourceFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<ISVehicleResourceDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<ISVehicleResourceDetail>();
  const [selectedRow, setSelectedRow] = useState<ISVehicleResourceDetail[]>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [orderVisible, setOrderVisible] = useState(false);
  const [clientVisible, setClientVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")

  const [resetState, setResetState] = useState(false)
  const [customerDs, setCustomerDs] = useState<OptionDsType[]>()


  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: ISVehicleResourceFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }

  const initCustomerDs = async () => {
    try {
      const ds = await saleClientService.findUserDs("customer")
      setCustomerDs(ds)
    } catch (error) {

    }
  }

  const loadData = async (page?: ISearchPage) => {
    const searchParams: ISearchPageField = {
      ...searchParam,
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await sVehicleResourceService.page(searchParams);
    const { items, number, ...pg } = retData;
    setSelectedRowData(undefined)
    setSelectedRowKeys([])
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item.id as string } }));
  }

  const loadOneData = async () => {
    let { retData } = await sVehicleResourceService.findOne(selectedRowKeys[0] as string);
    setSelectedRowData(retData)
  }

  // const importVehicleResource = async () => {
  //   const result = await 
  // }
  /**
   * 导出车源信息
   */
  const exportVehicleResource = async () => {
    const result = await sVehicleResourceService.export()
    convertRes2Blob(result)
  }

  /**修改置顶状态 */
  const handleChangeTopStatus = async (id: string, status: boolean) => {
    const { retData, success } = await sVehicleResourceService.update({ id, top: status ? 1 : 0 })
    if (success) {
      globalPrompt('message', { type: 'success', text: '置顶成功！' })
      loadData({ pageNum: 1 }).then()
    }
  }
  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    orderVisible,
    resetState,
    clientVisible,
    editModel,
    formRef,
    selectedRow,
    customerDs,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setOrderVisible,
    onSearch,
    onReset,
    setClientVisible,
    setEditModel,
    exportVehicleResource,
    setSelectedRow,
    initCustomerDs,
    loadOneData,
    handleChangeTopStatus,
  }
}

export const useSVehicleResourceModle = createModel(useModel)