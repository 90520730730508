import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import { YhForm } from '@src/components/YhFormBox'
import { YHModal } from '@src/components/YHModal'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import useNavModel, { RouterParamType } from '@src/model/navModel'
import { importPropsConfig } from '@src/utils/importFile'
import { Col, Form, Input, message, Modal, Row, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { useEffect, useRef, useState } from 'react'
import { usePVehicleResourceModle } from '../../vehicleResource/vehicleResourceModel'
import { usePurchaseOrderModel } from '../orderModel'
import { getBase64 } from '@src/utils/utils'
import { IUploadRef, UploadFiles } from '../../vehicleResource/view/UploadFiles'
import { useDsModel } from '@src/model/dsModel'
import moment from 'moment'
import { BooleanFlagDs } from '@src/types/FmsDs'
import { getBaseImgLookUrl } from '@src/utils/constants'

export const LookVehicleResourceModel = () => {
    const uploadRef = useRef<IUploadRef>()
    const { lookDrawerVisible, setLookDrawerVisible, lookFormVal } = usePurchaseOrderModel()
    const {
        vehicleTypeDs,
        gearboxTypeDs,
        drivingTypeDs,
        fuelTypeDs,
        powerTypeDs,
        vehicleBrandDs,
        labelDs,
        engineDs,
        colorDs,
        emissionDs,
        useNatureDs,
    } = useDsModel()
    const {
        formRef,
        baseFormRef,
    } = usePVehicleResourceModle()
    const [loading, setLoading] = useState<Boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewImage, setPreviewImage] = useState(false)
    const [previewUrl, setPreviewUrl] = useState('')
    const [previewVideo, setPreviewVideo] = useState(false)
    const [formCache, setformCache] = useState<any>()
    useEffect(() => {
        uploadRef.current?.setAllFile({
            appReport: lookFormVal?.ShcFilemgr?.appReport,
            photo: lookFormVal?.ShcFilemgr?.photo?.split(","),
            registerInfo: lookFormVal?.ShcFilemgr?.registerInfo,
            video: lookFormVal?.ShcFilemgr?.video,
        })
        const drivingLicense = lookFormVal?.ShcFilemgr?.drivingLicense
        if (drivingLicense) {
            setFileList([{
                uid: drivingLicense,
                url: getBaseImgLookUrl(drivingLicense),
                response: {
                    retData: drivingLicense
                }
            } as UploadFile])
        }
    }, [lookFormVal, lookDrawerVisible])
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 8 },
        disabled: true,
        initialValues: {
            ...lookFormVal,
            createTime: lookFormVal.createTime && moment(lookFormVal.createTime).format("YYYY-MM-DD HH:mm:ss"),
            registerTime: lookFormVal.registerTime && moment(lookFormVal.registerTime)
        },
        justify: 'start',
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆牌照",
                fieldName: "horsepowerCode",
                span: 6,
                rules: [
                    { required: true, message: "请输入车辆牌照" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "typeCode",
                span: 6,
                ds: vehicleTypeDs,
                rules: [
                    { required: true, message: "请选择车辆类型" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "使用性质",
                fieldName: "countryCode",
                span: 6,
                ds: useNatureDs
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆品牌",
                fieldName: "modelCode",
                ds: vehicleBrandDs,
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆识别代码",
                fieldName: "vin",
                span: 6,
                rules: [
                    { required: true, message: "请选择车辆类型" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发动机号码",
                fieldName: "engineCode",
                span: 6,
                rules: [
                    { required: true, message: "请输入发动机号码" }
                ],
            },
            {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "注册日期",
                fieldName: "registerTime",
                span: 6,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆归属",
                fieldName: "ownership",
                span: 6,
                rules: [
                    { required: true, message: "请输入车辆归属" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "卖家描述",
                fieldName: "desc",
                span: 12,
                labelCol: {
                    span: 4
                }
            }, {

                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true,
                span: 6,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
                span: 6,
            }
        ]
    };
    const baseFormConfig: IYhForm = {
        formRef,
        labelCol: { span: 8 },
        disabled: true,
        initialValues: {
            ...lookFormVal,
            produceTime: lookFormVal.produceTime && moment(lookFormVal.produceTime),
            scrapTime: lookFormVal.scrapTime && moment(lookFormVal.scrapTime),
            firstLicenseTime: lookFormVal.firstLicenseTime && moment(lookFormVal.firstLicenseTime)
        },
        justify: 'start',
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "驱动形式",
                fieldName: "drivingFormCode",
                span: 6,
                ds: drivingTypeDs,
                rules: [
                    { required: true, message: "请选择驱动形式" }
                ],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "马力",
                fieldName: "horsepowerName",
                span: 6,
                // ds: powerTypeDs,
                rules: [
                    { required: true, message: "请选择马力" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "燃料种类",
                fieldName: "fuelTypeCode",
                span: 6,
                ds: fuelTypeDs,
                rules: [
                    { required: true, message: "请选择燃料种类" }
                ],

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "行驶里程",
                fieldName: "mileage",
                span: 6,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "排放标准",
                fieldName: "emissionStandardCode",
                span: 6,
                ds: emissionDs,
                rules: [
                    { required: true, message: "请选择排放标准" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "变速箱品牌",
                fieldName: "gearboxBrandCode",
                span: 6,
                ds: gearboxTypeDs,
                rules: [
                    { required: true, message: "请选择变速箱品牌" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "发动机品牌",
                fieldName: "engineBrandCode",
                span: 6,
                ds: engineDs,
                rules: [
                    { required: true, message: "请选择发动机品牌" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "排量",
                fieldName: "emission",
                span: 6,
                ds: emissionDs,
                rules: [
                    { required: true, message: "请选择排量" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车身颜色",
                fieldName: "colorCode",
                span: 6,
                ds: colorDs,
                rules: [
                    { required: true, message: "请选择车身颜色" }
                ],
            },
            {
                type: FormItemTypeEnum.Datepicker,
                format: "YYYY-MM-DD",
                fieldLabel: "出厂日期",
                fieldName: "produceTime",
                allowClear: true,
                rules: [
                    { required: true, message: "请选择出厂日期" }
                ],
                span: 6,
            }, {
                type: FormItemTypeEnum.Datepicker,
                format: "YYYY-MM-DD",
                fieldLabel: "报废期止",
                fieldName: "scrapTime",
                allowClear: true,
                rules: [
                    { required: true, message: "请选择报废日期" }
                ],
                span: 6,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "功率",
                fieldName: "power",
                span: 6,
                ds: powerTypeDs,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所在地",
                fieldName: "addressName",
                span: 6,
            },
            {
                type: FormItemTypeEnum.Datepicker,
                format: "YYYY-MM-DD",
                fieldLabel: "初次上牌时间",
                fieldName: "firstLicenseTime",
                allowClear: true,
                rules: [
                    { required: true, message: "请选择初次上牌时间" }
                ],
                span: 6,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "价格",
                fieldName: "price",
                span: 6
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是/否接受议价",
                fieldName: "bargainFlag",
                span: 6,
                ds: BooleanFlagDs,
                rules: [
                    { required: true, message: "请选择是否接受议价" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是/否可过户",
                fieldName: "transferOwnershipFlag",
                span: 6,
                ds: BooleanFlagDs,
                rules: [
                    { required: true, message: "请选择是否可过户" }
                ],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "标签选择",
                fieldName: "labelCode",
                span: 6,
                ds: labelDs,
            },
        ]
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传行驶证</div>
        </div>
    );

    function beforeUpload (file: { type: string; size: number }) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = async (info: UploadChangeParam) => {
        setFileList(info.fileList)
        const formData = await formRef.current?.getFieldsValue()
        const otherData = await baseFormRef.current?.getFieldsValue()
        setformCache({
            ...formData,
            ...otherData,
        })
    };
    /**
     * 预览
     * @param type 预览类型
     * @param file 预览文件
     */
    const handlePreview = async (type: 'image' | 'video', file: UploadFile) => {
        let url = ""
        if (!file.url && !file.preview) {
            url = await getBase64(file.originFileObj) as string;
        } else {
            url = file.url ?? file.preview as string
        }
        setPreviewUrl(url)
        if (type === 'image') {
            setPreviewImage(true)
        } else {
            setPreviewVideo(false)
        }
    };

    const onOK = async () => {
        onCancel()
    }
    const onCancel = () => {
        setLookDrawerVisible(false);
    };


    return (
        <YHModal
            visible={lookDrawerVisible}
            onOk={onOK}
            onCancel={onCancel}
            width={1400}
            bodyHeight={700}
            showFooter={true}
        >
            <YhBox title="行驶本信息" style={{ marginTop: '15px', padding: "20px" }}>
                <YhForm {...formConfig} >
                    <Col span="6">
                        <Form.Item
                            label="行驶证"
                            name="files"
                            labelCol={{ span: 8 }}
                            required={true}

                        >
                            <Upload
                                disabled={true}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                {...importPropsConfig('/uploadAttach', handleChange)}
                                action="https://file-server.laoniuche.cn/file/uploadFile"
                                onPreview={(file) => handlePreview('image', file)}
                            >
                                {fileList.length > 0 ? <img src={getBaseImgLookUrl(fileList[0].response.retData as string)} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                </YhForm>
            </YhBox>
            <YhBox title="基本信息" style={{ marginTop: '15px', padding: "20px" }}>
                <YhForm {...baseFormConfig} >
                    <Col span="6">
                        <Form.Item
                            label="外轮廓尺寸"
                            name="size"
                            labelCol={{ span: 8 }}
                        >
                            <div className="flex">
                                <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </Col>
                </YhForm>
            </YhBox>
            <YhBox title="相关信息上传" style={{ marginTop: '15px', padding: "20px" }}>
                <UploadFiles uploadRef={uploadRef} />
            </YhBox>
        </YHModal>
    )
}