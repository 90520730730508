import { ITreeData, IYhFormRef } from "@src/components/YhFormBox/Types";
import { globalPrompt } from "@src/components/YhMessage";
import { EditModeType, ISearchPage, ISearchPageField, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { findDsFromTree, transPgToPagination } from "@src/utils/utils";
import { trasDsToTreeData } from "@src/views/base/dict/dictModel";
import { dictService } from "@src/views/base/dict/DictService";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import moment from "moment";
import { useRef, useState } from "react";
import { purchaseDemandService } from "./DemandService";
import { IPurchaseDemandDetail, IPurchaseDemandFields, IPurchaseDemandSearch } from "./DemandTypes";

const useModel = () => {
  const initSearchParam: IPurchaseDemandFields = {};
  const initDataSource: IPurchaseDemandDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数
  const formRef = useRef<IYhFormRef>();
  //查询条件
  const [searchParam, setSearchParam] = useState<IPurchaseDemandFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<IPurchaseDemandDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IPurchaseDemandDetail>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [resourceVisible, setResourceVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")

  const [resetState, setResetState] = useState(false)


  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: IPurchaseDemandFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }

  const loadData = async (page?: ISearchPage) => {
    const searchParams: IPurchaseDemandSearch = {
      ...searchParam,
      createTime: searchParam.createTime ? moment(searchParam.createTime).format('YYYY-MM-DD') : undefined,
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await purchaseDemandService.page(searchParams);
    const { items, ...pg } = retData;
    setSelectedRowKeys([])
    setSelectedRowData(undefined)
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item.id as string } }));
  }

  const loadDetail = async () => {
    try {
      const { retData } = await purchaseDemandService.findOne(selectedRowKeys[0] as string)
      setSelectedRowData(retData)
    } catch (error) {
      console.log(error)
    }
  }

  const handSubmit = async () => {
    try {
      const formData = await formRef.current?.validateFields()
      const data = {
        ...formData,
        produceTime: formData.produceTime ? moment(formData.produceTime).format('YYYY-MM-DD HH:mm:ss') : "",
      }
      if (editModel === 'add') {
        const insertParams = {
          ...data,
          createTime: formData.createTime ? moment(formData.createTime).format("YYYY-MM-DD HH:mm:ss") : "",
          status: 1
        }
        const { retData, success } = await purchaseDemandService.insert(insertParams)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
        }
      }
      if (editModel === 'edit') {
        const params = {
          ...data,
          id: selectedRowData?.id,
          demandCode: selectedRowData?.demandCode,
          createUser: undefined,
          createTime: undefined,
        }
        const { retData, success } = await purchaseDemandService.update(params)
        if (success) {
          globalPrompt('message', { text: '编辑成功！', type: 'success' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeStatus = async (r: IPurchaseDemandFields, v: boolean) => {
    try {
      const { retData } = await purchaseDemandService.enable(r.id as string, v ? '1' : '0')
      if (retData) {
        globalPrompt('message', { text: '修改成功', type: 'success' })
        loadData()
      } else {
        globalPrompt('message', { text: '修改失败', type: 'error' })
      }
    } catch (error) {

    }
  }
  const handleRemove = () => {
    globalPrompt("modal", {
      type: "warning",
      title: "正在进行删除操作",
      text: "是否确认删除",
      onOk: async () => {
        try {
          const { retData } = await purchaseDemandService.remove(selectedRowData?.demandCode as string)
          if (retData) {
            globalPrompt('message', { text: '删除成功', type: 'success' })
            loadData()
          } else {
            globalPrompt('message', { text: '删除失败', type: 'error' })
          }
        } catch (error) {

        }
      }
    })
  }
  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    drawerVisible,
    resetState,
    resourceVisible,
    editModel,
    formRef,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setDrawerVisible,
    onSearch,
    onReset,
    setResourceVisible,
    setEditModel,
    loadDetail,
    handSubmit,
    handleChangeStatus,
    handleRemove,
  }
}

export const usePurchaseDemandModle = createModel(useModel)