import React from "react";
import { useSaleOrderModel } from "../orderModel";
import { ISearchValue, SearchTypeEnum } from "@src/components/YhPageHeader/Types";
import { YhPageHeader } from "@src/components/YhPageHeader";
import { SOStatusDs } from '@src/types/FmsDs'
import { useDsModel } from '@src/model/dsModel';
export function PageHeader () {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSaleOrderModel()
    const { vehicleTypeDs } = useDsModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "订单编号",
            xxl: 4,
            span: 4,
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "车辆类型",
            xxl: 4,
            span: 4,
            fieldName: "typeCode",
            options: vehicleTypeDs
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            xxl: 4,
            span: 4,
            fieldName: "userName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            xxl: 4,
            span: 4,
            fieldName: "status",
            options: SOStatusDs
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: "创建时间",
            xxl: 4,
            span: 4,
            fieldName: "createTime"
        }
    ]

    return (
        <YhPageHeader
            title="销售订单列表"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(val = {}) => loadData({ pageNum: 1 })}
            handleReset={resetSearchParam}
        />
    );
}