import React, { useEffect } from 'react'
import { usePurchaseDemandModle } from '../demandModel'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'

export const PurchaseDemand = () => {
  const { loadData, resetState } = usePurchaseDemandModle()
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
  </>
}