import React from "react";
import { Switch, Route } from "react-router-dom";


import { Home } from "../views/home/Home";
import { Dict } from "@src/views/base/dict";
import { AboutUs } from "@src/views/content/aboutUs/view";
import { AboutUsDetail } from "@src/views/content/aboutUs/view/AboutUsDetail"
import { Dynamic } from "@src/views/content/dynamic/view";
import { DynamicDetail } from "@src/views/content/dynamic/view/DynamicDetail"
import { Policy } from "@src/views/content/policy/view";
import { PolicyDetail } from "@src/views/content/policy/view/PolicyDetail";
import { PurchaseHistory } from "@src/views/purchase/history/view";
import { PurchaseOrder } from "@src/views/purchase/order/view";
import { PurchaseOrderDetail } from '@src/views/purchase/order/view/OrderDetail'
import { SaleCollect } from "@src/views/sale/collect/view";
import { SaleHistory } from "@src/views/sale/history/view";
import { SaleOrder } from "@src/views/sale/order/view";
import { SaleOrderDetail } from "@src/views/sale/order/view/OrderDetail";
import { PurchaseSupplierDetail } from "@src/views/purchase/supplier/view/supplierDetail";
import { PurchaseDemand } from "@src/views/purchase/demand/view";
import { PurchaseDemandDetail } from "@src/views/purchase/demand/view/DemandDetail";
import { PurchaseSupplier } from "@src/views/purchase/supplier/view";
import { SaleDemand } from "@src/views/sale/demand/view";
import { SaleClient } from "@src/views/sale/client/view";
import { SaleDemandDetail } from "@src/views/sale/demand/view/DemandDetail";
import { Vehicle } from "@src/views/base/vehicle/view";
import { VehicleDetail } from "@src/views/base/vehicle/view/VehicleDetail";
import { PurchaseCollect } from "@src/views/purchase/collect/view";
import { SaleClientDetail } from "@src/views/sale/client/view/ClientDetail";
import { PurchaseVehicleResource } from "@src/views/purchase/vehicleResource/view";
import { PurchaseVehicleResourceDetail } from "@src/views/purchase/vehicleResource/view/PurchaseVehicleResourceDetail";
import { SaleVehicleResource } from "@src/views/sale/vehicleResource/view";
import { SaleVehicleResourceDetail } from "@src/views/sale/vehicleResource/view/SaleVehicleResourceDetail";


function FmsRoute() {
    return (
        <Switch>
            <Route exact={true} path={"/"} component={Home} />
            <Route exact={true} path={"/purchase/demand"} component={PurchaseDemand} />
            <Route exact={true} path={"/purchase/demand/detail"} component={PurchaseDemandDetail} />
            <Route exact={true} path={"/purchase/vehicleResource"} component={PurchaseVehicleResource} />
            <Route exact={true} path={"/purchase/vehicleResource/detail"} component={PurchaseVehicleResourceDetail} />
            <Route exact={true} path={"/purchase/order"} key="02-03" component={PurchaseOrder} />
            <Route exact={true} path={"/purchase/order/edit"} key="02-03-01" component={PurchaseOrderDetail} />
            <Route exact={true} path={"/purchase/order/look"} key="02-03-02" component={PurchaseOrderDetail} />
            <Route exact={true} path={"/purchase/supplier"} component={PurchaseSupplier} />
            <Route exact={true} path={"/purchase/supplier/detail"} component={PurchaseSupplierDetail} />
            <Route exact={true} path={"/purchase/history"} component={PurchaseHistory} />
            <Route exact={true} path={"/purchase/collect"} component={PurchaseCollect} />
            <Route exact={true} path={"/sale/vehicleResource"} component={SaleVehicleResource} />
            <Route exact={true} path={"/sale/vehicleResource/detail"} component={SaleVehicleResourceDetail} />
            <Route exact={true} path={"/sale/order"} key="03-02" component={SaleOrder} />
            <Route exact={true} path={"/sale/order/edit"} key="03-02-01" component={SaleOrderDetail} />
            <Route exact={true} path={"/sale/order/look"} key="03-02-02" component={SaleOrderDetail} />
            <Route exact={true} path={"/sale/demand"} component={SaleDemand} />
            <Route exact={true} path={"/sale/demand/detail"} component={SaleDemandDetail} />
            <Route exact={true} path={"/sale/history"} component={SaleHistory} />
            <Route exact={true} path={"/sale/collect"} component={SaleCollect} />
            <Route exact={true} path={"/sale/client"} component={SaleClient} />
            <Route exact={true} path={"/sale/client/detail"} component={SaleClientDetail} />
            <Route exact={true} path={"/content/aboutUs"} component={AboutUs} key="04-03" />
            <Route exact={true} path={"/content/aboutUs/add"} component={AboutUsDetail} key="04-03-01" />
            <Route exact={true} path={"/content/aboutUs/edit"} component={AboutUsDetail} key="04-03-01" />
            <Route exact={true} path={"/content/policy"} component={Policy} key="04-01" />
            <Route exact={true} path={"/content/policy/add"} component={PolicyDetail} key="04-01-01" />
            <Route exact={true} path={"/content/policy/edit"} component={PolicyDetail} key="04-01-02" />
            <Route exact={true} path={"/content/dynamic"} component={Dynamic} key="04-02" />
            <Route exact={true} path={"/content/dynamic/add"} component={DynamicDetail} key="04-02-01" />
            <Route exact={true} path={"/content/dynamic/edit"} component={DynamicDetail} key="04-02-02" />
            <Route exact={true} path={"/base/vehicle"} component={Vehicle} />
            <Route exact={true} path={"/base/dict"} component={Dict} />
            <Route exact={true} path={"/base/vehicle"} component={Vehicle} />
            <Route exact={true} path={"/base/vehicle/detail"} component={VehicleDetail} />
        </Switch>
    )
}

export default FmsRoute;