import React, { Children } from "react";
import DictTree from "./dictTree"
import DictForm from "./dictForm"
import { IDictProps } from "./Types";

export const YHDict = (props: IDictProps): JSX.Element => {


    return (
        <div className="flex">
            <DictTree
                {
                ...props
                }
            />
            <DictForm
                {
                ...props
                }
            >
                {props.children}
            </DictForm>
        </div>
    )
}