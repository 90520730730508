import React from 'react'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { usePurchaseSupplierModle } from '../supplierModel'
import { useDsModel } from '@src/model/dsModel'

export function PageHeader() {
  const {
    authTypeDs,
  } = useDsModel()
  const { updateSearchParam, onReset, onSearch, searchParam } = usePurchaseSupplierModle()
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '联系人',
      fieldName: 'userName',
      size: "small",
    },
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '联系电话',
      fieldName: 'autoTel',
      size: "small",
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '供应商类型',
      fieldName: 'authTypeCode',
      size: 'small',
      options: authTypeDs
    },
  ]
  return (
    <div>
      <YhPageHeader
        title="采购管理/供应商管理"
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={onSearch}
        searchFields={searchFields}
        searchValues={searchParam}
      />
    </div>
  )
}