import React, { useEffect } from 'react'
import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'
import { TransOrderDrawer } from '../drawer/TransOrderDrawer'
import { TransPurchaseDrawer } from '../drawer/TransPurchaseDrawer'
import { useSaleDemandModle } from '../demandModel'

export const SaleDemand = () => {
  const { loadData, resetState } = useSaleDemandModle()
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState])
  return <>
    <PageHeader />
    <DataBox />
    <TransOrderDrawer />
    <TransPurchaseDrawer />
  </>
}