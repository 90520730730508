import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { YhBox } from '@src/components/YhBox'
import { Description } from '@src/components/YhBox/Description'
import { DescriptionColorEnum } from '@src/components/YhBox/Types'
import { YhForm } from '@src/components/YhFormBox'
import { FormItemTypeEnum, IYhForm } from '@src/components/YhFormBox/Types'
import { useDsModel } from '@src/model/dsModel'
import useNavModel from '@src/model/navModel'
import { getBaseImgLookUrl } from '@src/utils/constants'
import { importPropsConfig } from '@src/utils/importFile'
import { Button, Col, Form, Input, message, Row, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useVehicleModle } from '../vehicleModel'

export const VehicleDetail = () => {
  const {
    vehicleTypeDs,
    gearboxTypeDs,
    drivingTypeDs,
    fuelTypeDs,
    powerTypeDs,
    vehicleBrandDs,
    labelDs,
    engineDs,
    colorDs,
    emissionDs,
    useNatureDs,
  } = useDsModel()
  const { formRef, selectedRowData } = useVehicleModle()
  const { addNav } = useNavModel()
  const [loading, setLoading] = useState<Boolean>(false)
  const [imageUrl, setImageUrl] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    setLoading(false)
    setImageUrl('')
  }, [])

  useEffect(() => {
    const drivingLicense = selectedRowData?.filemgr?.drivingLicense
    if (drivingLicense) {
      setFileList([{
        uid: drivingLicense,
        url: getBaseImgLookUrl(drivingLicense),
        response: {
          retData: drivingLicense
        }
      } as UploadFile])
    }
  }, [selectedRowData])

  const formConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    disabled: true,
    initialValues: {
      ...selectedRowData,
      registerTime: moment(selectedRowData?.registerTime),
      createTime: moment(selectedRowData?.createTime).format("YYYY-MM-DD HH:mm:ss"),
      // author: user.userName,
      // shops: selectedRowData?.shops?.map(item => item.code),
      // roles: selectedRowData?.roles?.map(item => item.code),
      // status: selectedRowData?.status === 0
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆牌照",
        fieldName: "numberPlate",
        span: 6,
        rules: [
          { required: true, message: "请输入车辆牌照" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆类型",
        fieldName: "typeCode",
        span: 6,
        ds: vehicleTypeDs,
        rules: [
          { required: true, message: "请选择车辆类型" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "使用性质",
        fieldName: "useNatureCode",
        span: 6,
        ds: useNatureDs
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车辆品牌",
        fieldName: "modelCode",
        ds: vehicleBrandDs,
        span: 6,
        rules: [
          { required: true, message: "请选择车辆品牌" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆型号",
        fieldName: "vehicleModel",
        span: 6,
        rules: [
          { required: true, message: "请输入车辆型号" }
        ]
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "车辆识别代码",
        fieldName: "vin",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "发动机号码",
        fieldName: "engineCode",
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        fieldLabel: "注册日期",
        fieldName: "registerTime",
        span: 6,
        rules: [
          { required: true, message: "请选择注册日期" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建人",
        fieldName: "createUser",
        disable: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "创建时间",
        fieldName: "createTime",
        disable: true,
        span: 6,
      },
    ]
  };
  const baseFormConfig: IYhForm = {
    formRef,
    labelCol: { span: 8 },
    disabled: true,
    initialValues: {
      ...selectedRowData,
      produceTime: selectedRowData?.produceTime ? moment(selectedRowData?.produceTime) : '',
      scrapTime: selectedRowData?.scrapTime ? moment(selectedRowData?.scrapTime) : '',
      firstLicenseTime: selectedRowData?.firstLicenseTime ? moment(selectedRowData?.firstLicenseTime) : '',
      // author: user.userName,
      // shops: selectedRowData?.shops?.map(item => item.code),
      // roles: selectedRowData?.roles?.map(item => item.code),
      // status: selectedRowData?.status === 0
    },
    justify: 'start',
    items: [
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "驱动形式",
        fieldName: "drivingFormCode",
        span: 6,
        ds: drivingTypeDs,
        rules: [
          { required: true, message: "请选择驱动形式" }
        ],
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "马力",
        fieldName: "horsepowerName",
        span: 6,
        // ds: powerTypeDs,
        rules: [
          { required: true, message: "请输入马力" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "燃料种类",
        fieldName: "fuelTypeCode",
        span: 6,
        ds: fuelTypeDs,
        rules: [
          { required: true, message: "请选择燃料种类" }
        ],

      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "行驶里程",
        fieldName: "mileage",
        span: 6,
        rules: [
          { required: true, message: "请输入行驶里程" }
        ],
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "排放标准",
        fieldName: "emissionStandardCode",
        span: 6,
        ds: emissionDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "变速箱品牌",
        fieldName: "gearboxBrandCode",
        span: 6,
        ds: gearboxTypeDs,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "发动机品牌",
        fieldName: "engineBrandCode",
        span: 6,
        ds: engineDs,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "排量",
        fieldName: "emission",
        span: 6,
      }, {
        type: FormItemTypeEnum.Select,
        fieldLabel: "车身颜色",
        fieldName: "colorCode",
        span: 6,
        ds: colorDs,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "出厂日期",
        fieldName: "produceTime",
        allowClear: true,
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "报废期止",
        fieldName: "scrapTime",
        allowClear: true,
        rules: [
          { required: true, message: "请选择报废日期" }
        ],
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "功率",
        fieldName: "power",
        span: 6,
      }, {
        type: FormItemTypeEnum.Input,
        fieldLabel: "所在地",
        fieldName: "addressName",
        span: 6,
      }, {
        type: FormItemTypeEnum.Datepicker,
        format: "YYYY-MM-DD",
        fieldLabel: "初次上牌时间",
        fieldName: "firstLicenseTime",
        allowClear: true,
        span: 6,
      }
    ]
  };

  // const { loading, imageUrl } = this.state;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传行驶证</div>
    </div>
  );

  function beforeUpload (file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const handleChange = (info: any) => {

  };

  return (
    <div>
      <YhBox title={<Description
        label="车辆编号"
        value={selectedRowData?.vehicleCode as string}
        color={DescriptionColorEnum.green}
        status={true}
      />} action={<Button type="link" shape="round" onClick={() => { addNav('ucms-05-02') }}>返回</Button>} />
      <YhBox title="行驶本信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...formConfig} >
          <Col span="6">
            <Form.Item
              label="附件"
              name="files"
              labelCol={{ span: 8 }}
              required={true}
            >
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                disabled
                {...importPropsConfig('/uploadAttach', handleChange)}
                action="https://file-server.laoniuche.cn/file/uploadFile"
              >
                {fileList.length > 0 ? <img src={getBaseImgLookUrl(fileList[0].response.retData as string)} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
      <YhBox title="基本信息" style={{ marginTop: '15px', padding: "20px" }}>
        <YhForm {...baseFormConfig} >
          <Col span="6">
            <Form.Item
              label="外轮廓尺寸"
              name="size"
              labelCol={{ span: 8 }}
            >
              <div className="flex">
                <Form.Item name="length" label="长" labelCol={{ span: 10 }}>
                  <Input disabled />
                </Form.Item>
                <Form.Item name="width" label="宽" labelCol={{ span: 10 }}>
                  <Input disabled />
                </Form.Item>
                <Form.Item name="height" label="高" labelCol={{ span: 10 }}>
                  <Input disabled />
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </YhForm>
      </YhBox>
    </div>
  )
}