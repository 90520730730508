import { getBaseRequestUrl, getToken } from './constants';
import { message } from "antd";

export interface FilrResponse {
    retCode: number,
    retData: string,
    retMsg: string,
    success: boolean
}
export interface File {
    uid?: string,
    name?: string,
    status?: string,
    response: FilrResponse,
    linkProps?: any
}
export interface UploadInfo {
    file: File,
    fileList: any,
    event: any
}

export function importPropsConfig(url: string, callback: any, createUser?: string): object {
    let cfg = {
        name: 'file',
        action: getBaseRequestUrl() + url,
        headers: {
            authorization: getToken(),
        },
        data: {
            createUser
        },
        onChange(info: UploadInfo) {
            if (info.file.status === 'done' && Number(info.file.response.retCode) === 200) {
                message.info({ content: `${info.file.name}导入${info.file.response.retData}`, duration: 8 });
                callback && callback(info)
            } else if (info.file.status === 'error' && info.file.response && info.file.response.retMsg) {
                message.error(`${info.file.response.retMsg}：${info.file.name}导入异常，请尝试重新导入`);
            } else if (info.file.status === 'error') {
                message.error("导入错误")
            }
        },
    }
    return cfg
}