import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { message, PaginationProps } from 'antd';
import { IPgProps } from '../types/FmsTypes';
import { setIP } from './constants';
import { ITreeData } from '@src/components/YhFormBox/Types';
type InitBody = {
	[key: string]: unknown;
};

type IobjItem = {
	id: string;
	[key: string]: unknown;
};
/**
 * url参数解析
 * @example String "https://mp.csdn.net?id=123456&a=b"
 * @return Object {id:"123456",a:"b"}
 */
export function urlParse(url: string): object {
	let obj: InitBody = {};
	/*
	 * 正则匹配
	 * [?&]:匹配?或&其一
	 * [^?&]:非?或非&
	 * */
	let reg = /[?&][^?&]+=[^?&]+/g;
	let arr = url.match(reg);
	if (arr) {
		arr.forEach((item) => {
			let tempArr = item.substring(1).split('=');
			console.log(tempArr);
			let key = tempArr[0];
			let val = tempArr[1];
			obj[key] = val;
		});
	}
	return obj;
}
export function urlParse1(url: string): object {
	let obj: InitBody = {};
	/*
	 * 正则匹配
	 * [?&]:匹配?或&其一
	 * [^?&]:非?或非&
	 * */
	let reg = /[?&][^?&]+=[^?&]+/g;
	let arr = url.match(reg);
	if (arr) {
		arr.forEach((item) => {
			let tempArr = item.substring(1).split('=');
			let trimKey = tempArr[0]?.replace(/\s+/g, '');
			let key = trimKey;
			let val = tempArr[1];
			obj[key?.toLowerCase()] = val?.trim();
		});
	}
	return obj;
}

/**
 *根据要查找的key返回这一项 （递归查找）
 * @param {Array}  array 要查找的源数组
 * @param {string} code   唯一标识
 * @param {string} key   要查找的键名， 和code对应的  默认id
 * @param {Boolean} filter   查找是否过滤掉不要在左侧菜单展示的页面
 */

export function forFind(array: any[], code: string, key: string = 'id', filter: boolean = false) {
	for (const item of array) {
		if (item[key] === code && (filter ? item.isShow : true)) {
			return item;
		}
		if (item.children && item.children.length > 0) {
			let forFindRet: any = forFind(item.children, code, key, filter);
			if (forFindRet) {
				return forFindRet;
			}
		}
	}
}

//re-from 错误校验提示信息方法
export function getFormValidatorErrText(error: ValidateErrorEntity) {
	let { errorFields } = error;
	for (let key in errorFields) {
		if (errorFields[key]?.errors?.length > 0) {
			return errorFields[key]?.errors[0];
		}
	}
	return '未知问题!';
}

/**
 * 数组里面（过滤数组对象id为重复的）
 *    let arr = [
						{ id: "11", value: 11 },
						{ id: "11", value: 11 },
						{ id: "22", value: 22 },
				]
			console.log(filterArrId(arr)) 
 */

export function filterArrId(arr: IobjItem[]): IobjItem[] {
	let obj: InitBody = {};
	let newArr: IobjItem[] = [];
	arr.forEach((item) => {
		if (!obj[item.id]) {
			newArr.push(item);
			obj[item.id] = true;
		}
	});
	return newArr;
}

/**
 * 判断对象的值是不是全为空
 */
export function objectValueIsEmpty(object: InitBody): boolean {
	var isEmpty = true; //值全为空
	Object.keys(object).forEach(function (x) {
		if (object[x] != null && object[x] !== '') {
			isEmpty = false;
		}
	});
	return isEmpty;
}

/**
 * 判断是否是对象
 */
export function isObject(obj: any): boolean {
	return Object.prototype.toString.call(obj) === '[object Object]';
}

// 判断两个对象(包括数组)是否相等
export function isObjectEqual(a: any, b: any) {
	let aProps = Object.getOwnPropertyNames(a);
	let bProps = Object.getOwnPropertyNames(b);
	if (aProps.length !== bProps.length) {
		return false;
	}
	for (let i = 0; i < aProps.length; i++) {
		let propName = aProps[i];
		let propA = a[propName];
		let propB = b[propName];
		if (typeof propA === 'object') {
			if (!isObjectEqual(propA, propB)) {
				return false;
			}
		} else if (propA !== propB) {
			return false;
		}
	}
	return true;
}

/**
 * 递归去除参数的前后空格
 */
export const trimParams = (data: any) => {
	if (typeof data === 'string') return data.trim();
	if (data && typeof data === 'object') {
		for (let key in data) {
			data[key] = trimParams(data[key]);
		}
	}
	return data;
};

/**
 * 数字补零   返回num字符串
 */
export function formatNum(num: string | number | undefined): string {
	if (num === undefined || num === null || isNaN(+num)) {
		num = 0.0;
	}
	if (typeof num === 'number') {
		num = String(num);
	}

	// eslint-disable-next-line no-useless-escape
	num = num.replace(/[^0-9|\.]/g, ''); //清除字符串中的非数字非.字符

	if (/^0+/)
		//清除字符串开头的0
		num = num.replace(/^0+/, '');
	if (!/\./.test(num))
		//为整数字符串在末尾添加.00
		num += '.00';
	if (/^\./.test(num))
		//字符以.开头时,在开头添加0
		num = '0' + num;
	num += '00'; //在字符串末尾补零
	let newNum = num.match(/\d+\.\d{2}/);
	return newNum ? newNum[0] : '0.00';
}

/**
 * 整数数字转换  返回num字符串
 */
export function formatInt(
	num: string | number | undefined,
	contrast: number | null = null,
	msg: string = '错误警告'
): string {
	if (num === undefined || num === null || isNaN(+num)) {
		return '1';
	}
	//对比数值， 不对返回错误
	if (contrast && contrast < +num) {
		message.warning(msg);
		return '0';
	}
	return `${num}`.split('.')[0];
}
/**
 * 整数数字转换  返回num字符串
 */
export function formatDiscount(num: string | number | undefined): string {
	if (num === undefined || num === null || isNaN(+num)) {
		return '0';
	}
	num = `${num}`;
	if (/^0\.[0-9]{1,2}$|^0{1}$|^1{1}$|^1\.[0]{1,2}$/.test(num)) {
		return num;
	} else {
		return '0';
	}
}

/**
 * 数字字符串转换数字
 */
export function switchNum(val: string | undefined): number | null {
	if (val && typeof val === 'string' && !isNaN(+val)) {
		return Number(val);
	}
	return null;
}
/**
 * 数字字符串转换数字
 */
export function switchNum1(val: string | number | undefined, initNum?: string): string {
	if (val && typeof val === 'string' && !isNaN(+val)) {
		return val;
	}
	return initNum ?? '0';
}
/**
 * 生成指定区间的随机整数
 */
export function randomNum(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min) + min);
}

//阿拉伯数字转换成大写汉字
export function numberParseChina(money: number | string) {
	//汉字的数字
	var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
	//基本单位
	var cnIntRadice = ['', '拾', '佰', '仟'];
	//对应整数部分扩展单位
	var cnIntUnits = ['', '万', '亿', '兆'];
	//对应小数部分单位
	var cnDecUnits = ['角', '分', '毫', '厘'];
	//整数金额时后面跟的字符
	var cnInteger = '整';
	//整型完以后的单位
	var cnIntLast = '圆';
	//最大处理的数字
	var maxNum = 999999999999999.9999;
	//金额整数部分
	var integerNum;
	//金额小数部分
	var decimalNum;
	//输出的中文金额字符串
	var chineseStr = '';
	//分离金额后用的数组，预定义
	var parts;
	if (money === '') return '';
	money = parseFloat(money as string);
	if (money >= maxNum) {
		//超出最大处理数字
		return '';
	}
	if (money === 0) {
		chineseStr = cnNums[0] + cnIntLast + cnInteger;
		return chineseStr;
	}
	//转换为字符串
	money = money.toString();
	if (money.indexOf('.') === -1) {
		integerNum = money;
		decimalNum = '';
	} else {
		parts = money.split('.');
		integerNum = parts[0];
		decimalNum = parts[1].substr(0, 4);
	}
	//获取整型部分转换
	if (parseInt(integerNum, 10) > 0) {
		var zeroCount = 0;
		var IntLen = integerNum.length;
		for (var i = 0; i < IntLen; i++) {
			var n = integerNum.substr(i, 1);
			var p = IntLen - i - 1;
			var q = p / 4;
			var m = p % 4;
			if (n === '0') {
				zeroCount++;
			} else {
				if (zeroCount > 0) {
					chineseStr += cnNums[0];
				}
				//归零
				zeroCount = 0;
				chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
			}
			if (m === 0 && zeroCount < 4) {
				chineseStr += cnIntUnits[q];
			}
		}
		chineseStr += cnIntLast;
	}
	//小数部分
	if (decimalNum !== '') {
		var decLen = decimalNum.length;
		for (var j = 0; j < decLen; j++) {
			var s = decimalNum.substr(j, 1);
			if (s !== '0') {
				chineseStr += cnNums[Number(s)] + cnDecUnits[j];
			}
		}
	}
	if (chineseStr === '') {
		chineseStr += cnNums[0] + cnIntLast + cnInteger;
	} else if (decimalNum === '') {
		chineseStr += cnInteger;
	}
	return chineseStr;
}

/**
 * 将接口返回数据转换为页码数据 （递归查找）
 * @param {Object}  pg 代转换页码数据
 */
export function transPgToPagination(pg: IPgProps): PaginationProps {
	return {
		total: pg.total,
		pageSize: pg.pageSize,
		current: pg.pageNum
	}
}

export async function getYourIP() {
	try {
		const grepSDP = (sdp: string | undefined) => {
			console.log('SDP : ', sdp);
			sdp?.split('\r\n').forEach(function (line: string, index: any, arr: any) {
				let parts, addr, type;
				if (~line.indexOf('a=candidate')) {
					parts = line.split(' ');
					addr = parts[4];
					type = parts[7];
					if (type === 'host') setIP(addr);
				} else if (~line.indexOf('c=')) {
					parts = line.split(' ');
					addr = parts[2];
					setIP(addr);
				} else if (~line.indexOf('o=')) {
					parts = line.split(' ');
					addr = parts[parts.length - 1];
					setIP(addr);
				}
			});
		};
		var RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection;
		if (RTCPeerConnection) {
			var rtc = new RTCPeerConnection({ iceServers: [] });
			rtc.onicecandidate = function (evt) {
				if (evt.candidate) grepSDP('a=' + evt.candidate.candidate);
			};
			const offerDesc = await rtc.createOffer();
			grepSDP(offerDesc.sdp);
			rtc.setLocalDescription(offerDesc);
		}
	} catch (error) {
		console.log('请使用主流浏览器：chrome,firefox,opera,safari');
	}
}

/**
 * 读取文件流返回本地地址
 * @param file 文件
 * @returns
 */
export function getBase64(file: Blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

/**
 * 在树中筛选对应code的下属项
 * @param treeData
 * @param code
 * @returns
 */
export const findDsFromTree = (treeData: ITreeData[], code: string) => {
	const tree = treeData.filter((item) => item.key === code);
	if (tree.length === 0) return [];
	return tree[0].children?.map((item) => {
		return { label: item.title as string, value: item.key as string };
	});
};

//关闭浏览器页签
export function closeWebPage() {
	if (navigator.userAgent.indexOf('MSIE') > 0) {
		// IE
		if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
			// IE6
			window.opener = null;
			window.close();
		} else {
			// IE6+
			window.open('', '_top');
			window.top.close();
		}
	} else if (
		navigator.userAgent.indexOf('Firefox') > 0 ||
		navigator.userAgent.indexOf('Presto') > 0
	) {
		// FF和Opera
		window.location.href = 'about:blank';
		window.close(); // 火狐默认状态非window.open的页面window.close是无效的
	} else {
		window.opener = null;
		window.open('', '_self', '');
		window.close();
	}
}
