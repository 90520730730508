import React from 'react'
import { YhPageHeader } from '@src/components/YhPageHeader'
import { ISearchField, SearchTypeEnum } from '@src/components/YhPageHeader/Types'
import { StatusDs } from '@src/types/FmsDs'
import { useSVehicleResourceModle } from '../vehicleResourceModel'
import { useDsModel } from '@src/model/dsModel'

export function PageHeader() {
  const { vehicleTypeDs, vehicleBrandDs } = useDsModel()
  const { onSearch, onReset, updateSearchParam, searchParam } = useSVehicleResourceModle()
  const searchFields: ISearchField[] = [
    {
      type: SearchTypeEnum.Input,
      xxl: 4,
      span: 4,
      placeholder: '车辆编号',
      fieldName: 'vehicleCode',
      size: "small",
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '车辆类型',
      fieldName: 'typeCode',
      size: 'small',
      options: vehicleTypeDs
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '品牌型号',
      fieldName: 'modelCode',
      size: 'small',
      options: vehicleBrandDs
    },
    {
      type: SearchTypeEnum.Select,
      xxl: 4,
      span: 4,
      placeholder: '状态',
      fieldName: 'status',
      size: 'small',
      options: [
        {
          label: "待售中",
          value: 1
        },
        {
          label: "已转接",
          value: 2
        },
        {
          label: "已售出",
          value: 3
        },
      ]
    },
    {
      type: SearchTypeEnum.Datepicker,
      xxl: 4,
      span: 4,
      placeholder: '创建时间',
      fieldName: 'createTime',
      format: "YYYY-MM-DD",
      size: "small",
    },
  ]
  return (
    <div>
      <YhPageHeader
        title="销售管理/车源发布"
        onChange={updateSearchParam}
        handleReset={onReset}
        handleSearch={onSearch}
        searchFields={searchFields}
        searchValues={searchParam}
      />
    </div>
  )
}