import { IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { importPropsConfig } from "@src/utils/importFile";
import { ISVehicleResourceDetail, ISVehicleResourceFields, ISVehicleResourceSearch } from "./VehicleResourceTypes";

class SVehicleResourceService {
  /**
   * 新增
   * @param params 
   * @returns 
   */
  async insert(params: ISVehicleResourceFields) {
    const url = "/shc2/sales/insert";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 修改
   * @param params 
   * @returns 
   */
  async update(params: ISVehicleResourceFields) {
    const url = "/shc2/sales/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 删除
   * @param id 
   * @returns 
   */
  async delete(id: string) {
    const url = "/shc2/sales/delete";
    return await httpRequest.post<boolean>(url, { id });
  }

  /**
   * 在线翻译
   * @param params 
   * @returns 
   */
  async translate(params: ISVehicleResourceFields) {
    const url = "/shc2/sales/translation";
    return await httpRequest.post<ISVehicleResourceFields>(url, params);
  }

  /**
   * 分页查询
   * @param params 
   * @returns 
   */
  async page(params: ISVehicleResourceSearch) {
    const url = "/shc2/sales/search_page";
    return await httpRequest.post<IPage<ISVehicleResourceDetail>>(url, params);
  }

  /**
   * 查询单条意向
   * @param id
   * @returns 
   */
  async findOne(id: string) {
    const url = `/shc2/sales/search_one/${id}`;
    return await httpRequest.post<ISVehicleResourceDetail>(url);
  }

  /**
   * 转订单
   * @param params
   * @returns 
   */
  async transOrder(params: ISVehicleResourceFields) {
    const url = `/shc2/order/insert`;
    return await httpRequest.post<string>(url, params);
  }


  /**
   * 导出
   * @returns 
   */
  async export() {
    const url = `/shc2/sales/export`;
    return await httpRequest.getFile(url)
  }

  /**
   * 导入
   * @param callback 
   * @returns 
   */
  import(callback: any, createUser: string) {
    const url = `/shc2/sales/import`;
    return importPropsConfig(url, callback, createUser)
  }
}

export const sVehicleResourceService = new SVehicleResourceService()