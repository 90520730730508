import { Key } from "react";

export enum SearchTypeEnum {
    'Input' = 'input',
    'Select' = 'select',
    'TreeSelect' = 'treeSelect',
    'Datepicker' = 'datepicker',
    'RangePicker' = 'rangePicker',
    'Button' = 'button'
}

export interface ISearchValue {
    [propName: string]: string | number | [string, string]
}

export type TreeDsType = {
    key: Key,
    title?: string,
    children?: TreeDsType[],
    disabled?: boolean,
    selectable?: boolean
}
export interface ISearchField {
    fieldName: string;
    type: SearchTypeEnum;
    placeholder?: string | string[];
    showTime?: Object | boolean;
    format?: string;
    xxl?: number;
    lg?: number;
    span?: number;
    disabled?: boolean;
    size?: string;
    options?: { label: string, value: any }[],
    treeDs?: TreeDsType[]
}

export interface ISearchProp {
    title?: string | JSX.Element;
    triggerSearch?: boolean,
    searchFields?: ISearchField[],
    searchMoreFields?: ISearchField[],
    searchValues?: ISearchValue | any,
    onChange?: (arg: ISearchValue | any) => void
    handleReset?: () => void;
    handleSearch?: () => void;
    onClick?: () => void;
}
