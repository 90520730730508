import React, { FC, useEffect } from "react";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";

import { SmileTwoTone } from "@ant-design/icons";
import menuDataJson from "../../data/menuData.json";
import { IMenuNode } from "@src/layout/FmsMenu";
import { globalPrompt } from "@src/components/YhMessage";
import { loginService } from "./LoginService";
import useNavModel from "@src/model/navModel";
interface ITokenLogin {
  token: string;
}

declare const window: Window & { loginTimer: NodeJS.Timeout | null };

export const TokenLogin: FC<ITokenLogin> = ({ token }) => {
  const history = useHistory()
  const { addNav } = useNavModel();
  const { user, setUser, setResourceAuth, setMenuData, menuData } = useGlobalModel();

  useEffect(() => {
    if (!window.loginTimer) {
      window.loginTimer = setTimeout(function () {
        beforeLogin()
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let firstNav = menuData.filter(item => item.isShow === 0)[0]
    if (user.token) {
      if (!firstNav) {
        return globalPrompt('message', { text: '您当前未授权二手车菜单', type: 'warning' })
      }
      if (firstNav && firstNav.linkUrl) {
        addNav(firstNav?.code)
        user.token && history.push(firstNav?.linkUrl)
      } else {
        addNav(firstNav.children[0].code)
        user.token && history.push(firstNav.children[0].linkUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData])

  const beforeLogin = async () => {
    //初始页数
    let { retData, retMsg } = await loginService.getUser({ token: token })
    let { menus, resources, roles, shops, ...userInfo } = retData ?? {};
    loginService.appLogin(retData.userCode as string).then(res => console.log(res.retMsg))
    setResourceAuth(resources ?? []);  //设置资源列表

    //设置用户菜单所有权限列表,(递归查询到所有要展示得菜单地址。)
    // let menuArr: string[] = [];
    // function findMenuAll(array: Auth2MenuDetail[]) {
    //   array.forEach(item => {
    //     item.linkUrl && menuArr.push(item.linkUrl);
    //     if (item.children && item.children.length > 0)
    //       findMenuAll(item.children);
    //   })
    // }
    // findMenuAll(menus?.[CLIENT_APP_CODE] ?? []);
    setMenuData(menuDataJson as unknown as IMenuNode[]);
    setUser({ ...user, ...userInfo, token, loginType: 'token' });
    globalPrompt("notification", {
      type: "success", title: "登录成功",
      duration: 3,
      text: <p className="bold"><span className="block"><SmileTwoTone /> {`您好!${userInfo.userName ?? ""}`}</span>欢迎使用云豪二手车管理系统</p>
    });

    console.log('当前登录状态:', user.loginType);
    // history.push("/purchase/demand");
  }


  return (
    <div className="login">
      <div className="caseBlanche">
        <div className="rond">
          <div className="test"></div>
        </div>
        <div className="load">
          <p>loading</p>
        </div>
      </div>
    </div>
  )

}

export default TokenLogin;