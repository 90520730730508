import { IPage } from "@src/types/FmsTypes";
import { httpRequest } from "@src/utils/HttpRequest";
import { IVehicleDetail, IVehicleFields, IVehicleSearch } from "./VehicleTypes";

class VehicleService {
  /**
   * 新增车辆
   * @param params 
   * @returns 
   */
  async insert(params: IVehicleFields) {
    const url = "/vehicle/insert";
    return await httpRequest.post<IVehicleDetail>(url, params);
  }

  /**
   * 更新车辆
   * @param params 
   * @returns 
   */
  async update(params: IVehicleFields) {
    const url = "/vehicle/update";
    return await httpRequest.post<boolean>(url, params);
  }

  /**
   * 分页查询车辆
   * @param params 
   * @returns 
   */
  async page(params: IVehicleSearch) {
    const url = "/shc2/vehicle/search_page";
    return await httpRequest.post<IPage<IVehicleDetail>>(url, params);
  }

  /**
   * 查询单条车辆
   * @param id
   * @returns 
   */
  async findOne(id: string) {
    const url = `/shc2/vehicleInfo/one`;
    return await httpRequest.post<IVehicleDetail>(url, { id });
  }
  /**
   * 导出
   * @returns 
   */
  async export() {
    const url = `/shc2/vehicle/export`;
    return await httpRequest.getFile(url)
  }
}

export const vehicleService = new VehicleService()