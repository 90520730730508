import { ITreeData, IYhFormRef } from "@src/components/YhFormBox/Types";
import { EditModeType, ISearchPage, ISearchPageField, OptionDsType } from "@src/types/FmsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@src/utils/constants";
import { convertRes2Blob } from "@src/utils/exportFile";
import { transPgToPagination } from "@src/utils/utils";
import { TablePaginationConfig } from "antd";
import { createModel } from "hox"
import { useRef, useState } from "react";
import { dictService } from "../dict/DictService";
import { vehicleService } from "./VehicleService";
import { IVehicleDetail, IVehicleFields } from "./VehicleTypes";

const useModel = () => {
  const initSearchParam: IVehicleFields = {};
  const initDataSource: IVehicleDetail[] = [];
  const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
  //列表数据源分页参数
  const formRef = useRef<IYhFormRef>();
  //查询条件
  const [searchParam, setSearchParam] = useState<IVehicleFields>(initSearchParam);
  //列表数据源
  const [dataSource, setDataSource] = useState<IVehicleDetail[]>(initDataSource);
  //表格选中行Key值
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IVehicleDetail>();
  //列表数据源分页参数

  const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

  /** Drawer */
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [resourceVisible, setResourceVisible] = useState(false);

  const [editModel, setEditModel] = useState<EditModeType>("add")
  const [editType, setEditType] = useState<EditModeType>("edit")

  const [resetState, setResetState] = useState(false)

  const [vehicleTypeDs, setVehicleTypeDs] = useState<OptionDsType[]>()
  const [orgDs, setOrgDs] = useState<ITreeData[]>()


  const getDs = async () => {
    try {
      let vehicleDs = await dictService.oneTreeDs({ code: "vehicle-type" })
      setVehicleTypeDs(vehicleDs)
      setOrgDs([])
    } catch (error) {

    }
  }

  const onSearch = () => {
    loadData({ pageNum: 1 }).then()
  }
  const onReset = () => {
    resetSearchParam()
    setResetState(!resetState)
  }

  const updatePgCfg = (updateParam: TablePaginationConfig) => {
    setPgCfg({ ...pgCfg, ...updateParam })
  }
  const updateSearchParam = (updateParam: IVehicleFields) => {
    setSearchParam({ ...searchParam, ...updateParam })
  }
  const resetSearchParam = () => {
    setSearchParam(initSearchParam);
  }

  const loadData = async (page?: ISearchPage) => {
    const searchParams: ISearchPageField = {
      ...searchParam,
      pg: {
        orders: [{
          rule: "ASC",
          column: "createTime"
        }],
        pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
        pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
      }
    }
    let { retData } = await vehicleService.page(searchParams);
    const { items, ...pg } = retData;
    setPgCfg(transPgToPagination(pg));
    setDataSource(items.map(item => { return { ...item, key: item.id as string } }));
  }

  /**
   * 导出车辆信息
   */
  const exportVehicle = async () => {
    const result = await vehicleService.export()
    convertRes2Blob(result)
  }
  return {
    /***field***/
    searchParam,
    dataSource,
    selectedRowKeys,
    selectedRowData,
    pgCfg,
    drawerVisible,
    resetState,
    resourceVisible,
    editModel,
    editType,
    vehicleTypeDs,
    orgDs,
    formRef,

    /***method***/
    setDataSource,
    setSelectedRowKeys,
    setSelectedRowData,
    resetSearchParam,
    updateSearchParam,
    loadData,
    updatePgCfg,
    setDrawerVisible,
    onSearch,
    onReset,
    setResourceVisible,
    setEditModel,
    setEditType,
    getDs,
    exportVehicle,
  }
}

export const useVehicleModle = createModel(useModel)