import React from 'react'
import {DataBox} from "./DataBox"
import {PageHeader} from './PageHeader'
export const Dynamic = () => {
  return (
    <>
      <PageHeader />
      <DataBox />
    </>
  )
}